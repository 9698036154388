.toggle-container {
  position: relative;

  .toggle {
    vertical-align: middle !important;
    appearance: none;
    height: 30px !important;
    width: 50px;
    border-radius: 50px;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: #A8A6A6;
    position: relative;

    &:before {
      content: " ";
      background: #F2F2F2;
      display: block;
      position: absolute;
      z-index: 2;
      width: 26px;
      height: 26px;
      left: 2px;
      top: 2px;
      border-radius: 50%;
      white-space: nowrap;
      transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s !important;
    }

    &:checked {
      background-color: #0061A0;

      &:before {
        content: " ";
        background: #F2F2F2;
        left: 22px;
      }
    }
  }
}
