@import 'scss/variables';
@import 'scss/mixins';

.section-title {
  h6 {
    @include font-size(0.9);
    text-transform: uppercase;
    font-weight: 600;

    i {
      margin-right: 0.3rem;
    }
  }
}
.form-group {
  &.has-search {
    margin-right: 1.2rem;
    margin-bottom: 0;
    max-width: 280px;
    width: 100%;
    @media screen and (max-width: 767px) {
      margin-bottom: 1.5rem;
    }
  }
}
.bd-highlight {
  & > label {
    white-space: nowrap;
    @include font-size(0.87);
  }
  & > {
    .dropdown {
      margin-left: 0.6rem;
      @media screen and (max-width: 767px) {
        display: inline-block;
        margin: 0.1rem;
      }
    }
  }
}
.clearFilter {
  > a {
    color: $midgrey;
    white-space: nowrap;
    @include font-size(0.87);
    i {
      margin-right: 0.2rem;
    }
    &:hover,
    &:focus {
      color: $primary-color;
    }
  }
}

.labelValue {
  p {
    margin-bottom: 0;
  }
  p + p {
    margin-top: 0.8rem;
  }
}
.tab-content {
  .chatMsg--block {
    overflow: unset;
  }
  .thumb-vertical {
    overflow-x: hidden;
  }
}

.chatMsg > div > div:nth-child(1) {
  // overflow-x: hidden!important;
}
/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 767px) {
  .form-group {
    .has-search {
      margin: 1rem;
    }
  }
}

.recurring-dropdown {
  max-height: inherit;
  overflow: visible;
}

.segmentBtn {
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  .btn {
    font-weight: 600;
    padding: 0.313rem 0;
    width: 100%;
    border-width: 1px;
  }
  .btn-outline-light {
    color: $midgrey;
  }
  .btn-primary {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
  }
}

.custom_dropdown {
  i {
    padding: 0 0 0 5px;
    color: $primary-color;
  }
  .btn {
    transition: none;
    border-radius: 2px;
  }
  .dropdown-toggle {
    padding: 0 0.62rem;
    background-color: transparent;
    color: $darkgrey;
    border: 2px solid transparent;
    border-bottom: 2px solid $primary-color;
    color: $primary-color;
    min-width: 60px;
    @include transition(all ease-in-out 350ms);
    i {
      font-size: 10px;
      @include transition(all ease-in-out 350ms);
    }
  }
  .btn-secondary:hover {
    border: 2px solid $midgrey;
    border-radius: 2px;
    color: $darkgrey;
    i {
      color: $darkgrey;
    }
  }
  .dropdown-menu {
    width: 100%;
    min-width: inherit;
    border-top: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    top: -8px !important;
    border-radius: 2px;
  }
  .btn-secondary:not(:disabled):not(.disabled):active {
    border: 2px solid $midgrey;
    border-radius: 2px;
    color: $darkgrey;
    background-color: transparent;
  }
  .btn-secondary.dropdown-toggle:focus,
  .btn-secondary:not(:disabled):not(.disabled):active:focus {
    border: 2px solid $midgrey;
    box-shadow: none;
  }
}

.custom_dropdown.show {
  .btn-secondary.dropdown-toggle {
    padding: 0 0.62rem;
    background-color: transparent;
    color: $darkgrey;
    border: 2px solid $midgrey;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-color: transparent;
  }
  i {
    color: $darkgrey;
  }
}
