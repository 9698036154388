.nav-tabs {
  border-bottom: 1px solid $sky;

  .nav-item {
    .nav-link {
      @include font-size(1.12);
      color: $lightgrey;
      padding: 1.5rem;
      border-color: transparent;
      border-width: 0 0 2px 0;
      border-style: solid;
      @include border-radius(0);
      background-color: transparent;

      &.active,
      &:hover {
        border-bottom-color: $darkgrey;
        color: $darkgrey;
      }
    }
  }
}
.tab-right-btn {
  margin-top: -70px;
  @media screen and (max-width: 767px) {
    margin-top: -100px;
  }
}
.navtabs-bgColor {
  background-color: $lightsky;
  .nav-item {
    .nav-link {
      background-color: transparent;
    }
  }
}
.navtabs-small {
  .nav-item {
    .nav-link {
      padding: 0.85rem 1.5rem;
    }
  }
}
