.table-responsive {
  overflow-x: visible;
  @media screen and (max-width: 1365px) {
    overflow-x: auto;
  }
}

.tableWrap {
  .table {
    margin-bottom: 1.5rem;
  }
}

.table {
  border-top: none;

  tr {
    border-bottom: 1px solid $sky;

    .check-container {
      top: 2px;
    }

    th {
      cursor: pointer;

      i {
        @include font-size(0.5);
        margin-left: 0.2rem;
        font-weight: 600;
      }
    }

    td,
    th {
      vertical-align: middle;
      border: none;
      padding: 0.9rem 0.5rem;
    }

    th {
      color: $midgrey;
      text-transform: uppercase;
      vertical-align: bottom;
      @include font-size(0.75);
      font-weight: 600;
      border-top: none;
      letter-spacing: 1px;
    }

    td {
      @include font-size(0.94);

      .btn-link.btn--onlyicon {
        padding-left: 0.3rem;
        padding-right: 0.3rem;
      }

      .text-dark {
        a {
          word-break: break-all;
        }
      }
    }

    .vertical-top {
      vertical-align: top;
    }

    .vertical-middle {
      vertical-align: middle;
    }
  }

  .cpa-name {
    letter-spacing: 1px;
    font-size: 10px;
  }

  tbody {
    tr {
      @include transition(all ease-in-out 350ms);

      &:hover {
        @include box-shadow($left: 0px, $top: 5px, $blur: 20px, $color: rgba(0, 0, 0, 0.1));
        cursor: pointer;
        border-color: transparent;
      }

      &.trInactive {
        td > span {
          color: $lightgrey !important;
        }

        &:hover {
          box-shadow: none;
          border-color: $sky;
        }
      }
    }

    .emptyTr {
      &:hover {
        box-shadow: none;
        cursor: default;
        border-color: $sky;
      }
    }
  }
}

.custom-page-link .icon-right-arrow,
.custom-page-link .material-icons {
  @include font-size(0.5);
  font-weight: 600;
}

.custom-page-link a {
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 600;
}

.table th .material-icons {
  font-size: 12px;
  vertical-align: middle;
}

.table thead th.sorting_asc {
  color: $darkgrey;
}

.table tbody i.icon-Tasks {
  color: $lightgrey;
}

.table.dataTables_paginate {
  float: right;
  margin: 0;
}

.tableFilter {
  .select-custom-class {
    width: 100%;
    max-width: 175px;
  }
}

.tdata {
  .fileTtype {
    width: auto;
    flex-basis: auto;
    height: auto;
  }
}

.tdata--detail {
  position: relative;
  flex-grow: 1;

  &.form-group {
    margin-bottom: 0;
  }

  .fileName {
    word-break: break-all;
  }
}

.tdata--icon {
  padding-right: 30px;

  .editTd {
    @include position(absolute, $top: 50%, $right: 3px);
    @include translate(0, -50%);
    color: $midgrey;
    font-weight: 600;
    opacity: 0;
    visibility: hidden;

    &:hover {
      color: $primary-color;
    }
  }

  &:hover {
    .editTd {
      visibility: visible;
      opacity: 1;
    }
  }
}

.tdata--edit {
  .form-control {
    max-width: 280px;
  }
}
// TD Button Hover Effect

.tdBtn {
  &__hover {
    opacity: 0;
    visibility: hidden;
    @include transition(all ease-in-out 350ms);
  }

  &:hover {
    .tdBtn__hover {
      opacity: 1;
      visibility: visible;
    }
  }
}
// Div Table Struture

.tRow {
  & > .row {
    @include transition(all ease-in-out 350ms);
    border-bottom: 1px solid $sky;
    margin-left: 0;
    margin-right: 0;
    align-items: center;

    .tdCol,
    .thCol {
      vertical-align: middle;
      border: none;
      padding-top: 0.9rem;
      padding-bottom: 0.9rem;
      @include transition(all ease-in-out 350ms);
      @media screen and (max-width: 1300px) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
      &.ie-px-1 {
        -webkit-transition: none;
        -moz-transition: none;
        -ms-transition: none;
        transition: none;
      }
    }

    .thCol {
      color: $midgrey;
      text-transform: uppercase;
      vertical-align: bottom;
      @include font-size(0.75);
      font-weight: 600;
      border-top: none;
      letter-spacing: 1px;

      &.sorting_asc {
        color: $darkgrey;
      }

      .posRel {
        position: relative;
        cursor: pointer;

        i {
          font-size: 8px;
          margin-left: 0.2rem;
          font-weight: 600;
          @include position(absolute, $top: 4px, $right: -10px);
        }
      }
    }

    .tdCol {
      font-size: 0.94rem;
      -ms-word-break: break-all;
      word-break: break-all;
      word-break: break-word;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;

      span {
        word-break: break-word;
      }

      &:not(.flex-grow-0) {
        span.rounded {
          position: relative;
          left: 5px;
        }
      }
    }

    &.trInactive {
      div > span {
        color: $lightgrey !important;
      }

      &:hover {
        box-shadow: none;
        border-color: $sky;
      }
    }
  }

  &--body {
    margin-bottom: 1.5rem;

    & > .row {
      &:hover {
        @include box-shadow($left: 0px, $top: 5px, $blur: 25px, $color: rgba(0, 0, 0, 0.1));
        cursor: pointer;
        border-color: transparent;

        .tdCol {
          border-color: transparent;
        }
      }

      &.emptyTr {
        .emptyBox {
          width: 100%;
        }

        &:hover {
          box-shadow: none;
          border-color: $sky;
        }
      }
    }
  }
}

#tags_list tbody {
  .tdBtn {
    display: flex;
    margin-left: 0;
    margin-right: 0;
    align-items: center;
  }
  tr {
    .tdCol {
      font-size: 0.94rem;
      -ms-word-break: break-all;
      word-break: break-all;
      word-break: break-word;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
      vertical-align: middle;
      border: none;
      padding-top: 0.9rem;
      padding-bottom: 0.9rem;
      -moz-transition: all ease-in-out 350ms;
      -o-transition: all ease-in-out 350ms;
      -webkit-transition: all ease-in-out 350ms;
      transition: all ease-in-out 350ms;
    }
  }
  tr {
    border-top: 0.5px solid $sky !important;
    border-bottom: 0.5px solid $sky !important;
    margin-top: -1px;
    background-color: white;
  }

  .dragable_row {
    td {
      color: $primary-color !important;
    }
    td > .dragbutton {
      opacity: 1 !important;
      visibility: visible !important;
    }
    td > .dragbutton > .icon-double-arrow:before {
      color: $primary-color !important;
      content: '\e235' !important;
      border: none;
    }
  }

  .dragbutton {
    -webkit-appearance: button;
  }
}
