.pagination {
  margin-bottom: 0;
}

.page-item {
  .page-link {
    border: 2px solid $sky;
    color: $midgrey;
    font-weight: 500;
    height: 36px;
    line-height: 2.13;
    padding: 0 0.78rem;
    @include border-radius(4px);
    margin: 0 5px;
    background-color: #fff;

    [class^='icon-'] {
      @include font-size(0.45);
      position: relative;
      top: -2px;
    }

    &:hover {
      color: $darkgrey;
      border-color: transparent;
      @include box-shadow($left: 0px, $top: 5px, $blur: 20px, $color: rgba(0, 0, 0, 0.1));
    }

    &:focus {
      border-color: $sky;
      background-color: #fff;
      box-shadow: none;
      color: $midgrey;
    }
  }

  &.active {
    .page-link {
      background: $primary-color;
      border-color: $primary-color;
      color: #fff;
      box-shadow: none;
    }
  }
}
@media screen and (max-width: 767px) {
  .Pagi-Responsive {
    overflow-x: auto;
  }
}
