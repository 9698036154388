@charset "utf-8";
/* CSS Document */


img, a img{
	
	border:0px none;	
	outline:none;
	
}

.invalidWrap{
	background-color: #213651;
}

/* === Preloader === */

#universal-preloader{
	
	position:fixed;
	top:0px;
	left:0px;
	width:100%;
	height:100%;
	opacity:0.96;
	background:#213651 /*url(../images/background.png) repeat*/;
	z-index:999999999;
	
}

#universal-preloader>.preloader{
	
	width:24px;
	height:24px;
	position:absolute;
	top:50%;
	left:50%;
	text-align:center;
	
}

.universal-preloader-preloader{
	
	position:absolute; 
	top:0px; 
	left:0px;	
	
}

/* === Dog Changer === */

#dog-changer{

	position:fixed;
	top:20px;
	left:-122px;
	background:#fefefe;
	border-radius:0px 5px 5px 0px;
	box-shadow:0px 0px 0px 5px rgba(0,0,0,0.2);

}

#dog-changer .toggle{

	height: 50px;
	position: absolute;
	right: -50px;
	top: -3px;
	width: 50px;

}

#dog-changer .toggle:hover{

	cursor:pointer;

}

#dog-changer ul{

	margin:0px;
	padding:20px;
	list-style: none;

}

#dog-changer ul li{

	width:80px;
	overflow:hidden;

}

#dog-changer ul li a{

	opacity:0.3;

}

/* === Main Section === */

.invalidWrap{
	overflow: hidden;
}

#wrapper{
	
	width:980px;
	margin:0px auto;
	position:relative;
	height:100%;
/*	background:url(../images/sky-shine.jpg) top left no-repeat*/;
	
}

div.top-left{

	/*position:absolute;
	right:0px;*/

}

h1.not-found-text{
	
	font-size:50px;
	color:#fff;
	font-family:inherit;
	letter-spacing:2px;
	margin-bottom:20px;
	
}

div.not-found-text{
	
	/*position:absolute;
	top:35px;
	right:0px;
	width:430px;*/
	
}

div.graphic{
	
	/*position:absolute;
	top:80px;
	left:0px;*/
	
}
div.graphic >img{
	max-width: 150px;
}

div.planet{

	/*position:absolute;*/
	/*bottom:-700px;*/
	margin:0px auto;
	z-index:0;
	position: relative;
	margin-top: 12rem;
}

div.planet>img{

	width:960px;

}

div.dog-wrapper{
	
	position:absolute;
	top:45px;
	left:440px;
	
}

div.dog{
	
	position:absolute;
	bottom:0px;
	left:0px;
	width:80px;
	height:80px;
	z-index:999;
	background:url(../images/dog1.png) 0px 0px no-repeat;
	
}

div.search{
	
	position:absolute;
	top:145px;
	right:0px;
	width:340px;
	background:#eeeeee;
	box-shadow:1px 1px 0 #ffffff inset, 5px 5px 0px #3680b1;
	color:#555555;
	font-size:14px;
	text-shadow:1px 1px 0 #ffffff;
	border-radius:30px;
	padding:10px;
	z-index:999;
	
}

div.search input[type=submit]:hover{
	
	background:#ffffff url(../images/search.png) center center no-repeat;	
	cursor:pointer;
	
}

div.search input[type=submit]{
	
	box-shadow:1px 1px 0 #ffffff inset, 5px 5px 0px #3680b1;
	background:#eeeeee url(../images/search.png) center center no-repeat;
	border-radius:50px;
	position:absolute;
	top:0px;
	right:380px;
	width:50px;
	height:40px;
	border:none;
	
	
}

div.search input[type=text]{
	
	padding:0px 10px;
	border:0px none;
	background:none;
	color:#999999;
	width:300px;
	height:20px;
	
}

div.top-menu{
	
	position:relative;
	top:200px;
	right:0px;
	color:#ffffff;
	z-index:998;
	
}

div.top-menu a{
	
	text-decoration:none;
	color:#eeeeee;
	margin:0px 5px;
	font-size:12px;
	
}

div.top-menu a:hover{
	
	color:#abd7fa;	
	
}

div.dog-bubble{
	
	font-size:14px;
	line-height:1.5;
	font-style:italic;
	height:179px;
	width:246px;
	background:url(../images/bubble.png) top center no-repeat;
	padding:20px 0px;
	position:absolute;
	bottom:0px;
	left:30px;
	z-index:999;
	opacity:0;
	color:#555555;
	font-size:14px;
	text-shadow:1px 1px 0 #ffffff;
	
}

div.dog-bubble>p{
	
	text-align:center;
	padding:0px 35px;	
	
}

div.bubble-options{

	opacity:0;
	visibility:hidden;
	display:none;
	
}

/* === Responsive === */

/* #Small laptop screens
================================================== */

    /* Note: Design for a width of 960px */

    @media only screen and (max-width: 960px) {

    	#wrapper{

    		width:600px;
    		background-image:none;

    	}

        div.planet{

			position:absolute;
			bottom:-360px;
			margin:0 auto 0 -280px;
			z-index:0;
			left:50%;
			
		}

		div.planet>img{

			width:560px;

		}

		div.dog-wrapper{
	
			position:absolute;
			top:45px;
			left:250px;
			
		}

		div.graphic{
	
			position:absolute;
			top:50px;
			left:40px;
			
		}

		div.top-left {

		    position: absolute;
		    right: 0px;
		    top: -40px;

		}

		div.graphic>img{

			width:60%;

		}

		div.graphic{

			left: 0px;
		    position: absolute;
		    top: 20px;

		}

		div.not-found-text{

			right:0px;
			top:22px;
			width:320px;

		}

		h1.not-found-text{

			font-size:40px;

		}

		div.search{

			right: 10px;
		    top: 105px;
		    width: 230px;

		}

		div.search input[type="text"]{

			width:200px;

		}

		div.search input[type="submit"]{

			right: 260px;

		}

		div.top-menu{

			right: 5px;
   			top: 160px;

		}

    }


/*  #Tablets and small screens
================================================== */

    /* Note: Design for a width of 768px */

    @media only screen and (max-width: 767px) {
        
    	#wrapper{

    		width:400px;
    		background-image:none;

    	}

    	div.graphic>img{

			width:40%;

		}

        div.planet{

			position:absolute;
			bottom:-240px;
			margin:0 auto 0 -180px;
			z-index:0;
			left:50%;
			
		}

		div.planet>img{

			width:360px;

		}

		div.dog-wrapper{
	
			position:absolute;
			top:35px;
			left:150px;
			
		}

		div.graphic{
	
			position:absolute;
			top:20px;
			left:0px;
			
		}

		div.top-left {

		    position: absolute;
		    right: 390px;
		    top: 170px;

		}

		div.search input[type="submit"] {

		    right: 163px;

		}

		div.search {

		    right: 10px;
		    top: 85px;
		    width: 130px;

		}

		h1.not-found-text {

		    font-size: 26px;

		}

		div.not-found-text {

		    right: -100px;
		    top: 33px;
		    width: 320px;

		}

		div.top-left {

		    position: absolute;
		    right: 0;
		    top: -38px;

		}

		div.top-menu {

		    font-size: 9px;
		    right: 5px;
		    top: 137px;

		}

		div.top-menu a{

			font-size: 9px;

		}

    }


/* #Mobile phones
================================================== */

    /* Note: Design for a width of 480px */

    @media only screen and (max-width: 479px){
        
    	#wrapper{

    		width:320px;
    		background-image:none;

    	}

    	div.graphic>img{

			width:25%;

		}

        div.planet{

			position:absolute;
			bottom:-130px;
			margin:0 auto 0 -100px;
			z-index:0;
			left:50%;
			
		}

		div.planet>img{

			width:200px;

		}

		div.dog-wrapper {

		    left: 70px;
		    position: absolute;
		    top: 35px;

		}

		div.search input[type="text"] {

		    height: 10px;
		    padding: 0 10px;

		}

		div.search input[type="submit"] {

		    height: 30px;
		    width: 40px;

		}

		div.search input[type="submit"] {

		    right: 170px;

		}

		div.search {

		    width: 140px;

		}

		div.top-menu {

		    font-size: 7px;
		    top: 123px;

		}

		div.top-menu a{

			font-size: 7px;

		}

    }
.not-found-text p a {
    color: #00afcc;
    text-decoration: none;
}
.not-found-text p {
    color: #fff;
    line-height: 25px;
}
h1.not-found-text img {
    margin-right: 12px;
    margin-top: 0;
    width: 43px;
}