@import 'variables';
@import 'mixins';
@import 'checkbox';
@import 'button';
@import 'filter';
@import 'input';
@import 'dropdown';

// Ck Editor
.cke_reset {
  &.cke {
    border: 2px solid $sky;
  }

  .cke_top {
    background-color: transparent;
    border-bottom-color: $sky;
  }

  .cke_bottom {
    background-color: transparent;
    border-top-color: $sky;
  }

  a {
    &.cke_button {
      border-color: transparent;
      cursor: pointer;

      &:hover {
        background-color: darken($sky, 1%);
        border-color: transparent;
      }
    }

    &.cke_path_item {
      cursor: pointer;

      &:hover {
        background-color: darken($sky, 1%);
      }
    }
  }
}
