.tox.tox-tinymce--disabled {
  border: none !important;
  border-radius: 0px !important;
  font-family: inherit !important;

}

.mce-content-readonly {
  p {
    margin: 0px;
  }
}

body .tox-tinymce--disabled, body .tox.tox-tinymce--disabled .tox-edit-area__iframe {
  background-color: transparent !important;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
  padding: 0px !important;
  border-top: none !important;
}