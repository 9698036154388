.carousel {
  background-color: rgba(223, 227, 232, 0.4);
  height: calc(100vh - 65px);

  .carousel-item {
    height: calc(100vh - 65px);
  }

  .active {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 5%;
    background-color: rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  img {
    max-width: 100vw;
    max-height: 100vh;
  }
}
