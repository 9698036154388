.messageRow {
  display: flex;
  justify-content: space-between;
  padding: 0.9rem 0.5rem;
  border-bottom: 1px solid $sky;
  &:last-child {
    border-bottom: none;
  }

  .avatar {
    margin-right: 1.25rem;
    flex-basis: 40px;
    &--w40 {
      max-width: 40px;
    }
  }

  .messageDetails {
    flex: 1 1 auto;
    display: flex;
    cursor: pointer;
    flex-wrap: wrap;
    .messageBlock {
      flex-grow: 1;
      padding-right: 1rem;
    }
    .messageAction {
      .messageDate {
        margin-left: 1rem;
        margin-right: 0.8rem;
      }
    }
  }
  .messageHeading {
    @include font-size(0.94);
    font-weight: 600;
    margin-bottom: 0;
    .userType {
      font-weight: 400;
      vertical-align: middle;
      &:before {
        content: '•';
        padding: 0 0.5rem;
      }
    }
  }
  .messageSent {
    @include font-size(0.94);
    margin-bottom: 0.3rem;
  }
  .messageDesc {
    margin-bottom: 0;
    white-space: normal;
    word-break: break-word;
  }
  .messageText {
    font-weight: 400;
  }
}
.messageExpand {
  display: flex;
  width: 100%;
  margin-top: 0.5rem;
  cursor: default;
  .message__wrap {
    padding: 1rem;
    background-color: $lightsky;
  }
  .messageDesc {
    margin-top: 0;

    a {
      padding: 0;
    }

    p {
      margin-bottom: 1rem;
      max-width: 100%;
      display: inline-block;
    }

    img {
      cursor: pointer;
    }

    span {
      display: initial;
    }
  }
  .docsInfo {
    margin-top: 0.5rem;
  }
  .dragWrap {
    max-width: 900px;
    width: 100%;
    label {
      text-transform: uppercase;
      font-weight: 600;
      @include font-size(0.75);
    }
    .file-drop-upload {
      padding: 0;
    }
  }
}
.docsInfo {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 1rem 0 0;
  .messageHeading {
    flex: 0 0 100%;
    align-items: center;
    margin-bottom: 0.2rem;
    i {
      color: $midgrey;
      margin-right: 0.3rem;
      vertical-align: middle;
      @include font-size(0.75);
    }
  }
  &--fill {
    background-color: rgba($sky, 0.52);
    @include border-radius(0.25rem);
    padding: 0.5rem;
  }
}
.messageReply {
  border-top: 1px solid $sky;
  padding-top: 2rem;
  margin-top: 1.5rem;
  .dragWrap {
    width: 100%;
    max-width: 840px;
  }
}
.messageReply--fullwidth {
  margin-left: -2.3rem;
  margin-right: -2.3rem;
  padding-left: 2.3rem;
  padding-right: 2.3rem;
}
.replyInput {
  .customTags {
    background-color: $lightsky;
    z-index: 1;
  }
  .customTags__input {
    border-bottom-width: 1px;
    padding: 0.6rem 1.9rem 0.6rem 0.6rem;
    min-height: 48px;
    .msgTo {
      padding-top: 1px;
    }
  }
  .expand {
    @include position(absolute, $top: 13px, $right: 0.6rem);
    z-index: 9;
    padding: 0;
    .expMsg {
      display: inline-block;
    }
    .colpsMsg {
      display: none;
    }
    &:focus {
      background-color: transparent;
    }
  }
  .customEditor {
    margin-top: -48px;
    padding-top: 47px;
    .DraftEditor {
      background-color: $lightsky;
      border-top: none;
      height: 230px;
    }
  }
  &:focus-within {
    .customTags__input,
    .DraftEditor {
      border-color: $midgrey;
    }
    .DraftEditor-root {
      border-bottom-color: $midgrey;
    }
  }
  .form-error {
    .DraftEditor {
      background-color: rgba($red, 0.1);
      border-color: $red;
      .DraftEditor-root {
        border-bottom-color: $red;
      }
    }
  }
}

.fullWidthMsg {
  .section-title,
  .messageList {
    display: none;
  }
  .messageReply {
    border-top: none;
    padding-top: 0 !important;
    .DraftEditor {
      min-height: 500px;
    }
    .expand {
      .expMsg {
        display: none;
      }
      .colpsMsg {
        display: inline-block;
      }
    }
  }
}

.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    display: inline;
  }

  p {
    display: contents;
  }
}

.noAnchor {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-size: 0.95rem;
  }
}
