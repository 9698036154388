/*checkbox css*/
.check-container,
.radio-container {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: $fontglobal;

  label {
    padding-left: 1rem;
    margin-bottom: 0;
    position: relative;
    cursor: pointer;
    text-transform: none;
    @include font-size(0.8);
    color: $darkgrey;
    min-height: 15px;
  }

  input {
    @include position(absolute, $top: 0, $left: 0);
    opacity: 0;
    cursor: pointer;
    z-index: 1;
    margin: 0;

    &.disabled,
    &:disabled {
      cursor: not-allowed;
    }
  }

  .checkmark {
    @include position(absolute, $top: 1px, $left: 0);
    width: 15px;
    height: 15px;
    border: 2px solid $sky;

    &:after {
      content: '';
      visibility: hidden;
      opacity: 0;
    }
  }

  input.disabled ~ .checkmark,
  input:checked:disabled ~ .checkmark,
  input:disabled ~ .checkmark {
    background-color: $lightsky;
    border-color: $sky;
    color: $lightgrey;
    cursor: not-allowed;
  }
  input.disabled,
  input:disabled ~ span.pl-2 {
    cursor: not-allowed;
  }
}

.check-container {
  .checkmark {
    @include border-radius(3px);

    &:after {
      @include position(absolute, $top: 0px, $left: 3px);
      width: 5px;
      height: 8px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      @include rotate(45);
    }
  }

  input:checked ~ .checkmark {
    background-color: $primary-color;
    border-color: $primary-color;
    @include box-shadow($top: 0, $left: 1px, $blur: 1px, $color: rgba(97, 121, 133, 0.05));
    color: $darkgrey;

    &:after {
      opacity: 1;
      visibility: visible;
    }
  }

  input:checked:disabled ~ .checkmark {
    border-color: $midgrey;
    color: $lightgrey;
    cursor: not-allowed;

    &:after {
      border-color: $midgrey;
    }
  }
}

.radio-container {
  .checkmark {
    @include border-radius(50%);

    &:after {
      @include position(absolute, $top: 2px, $left: 2px);
      width: 7px;
      height: 7px;
      background-color: $primary-color;
      @include border-radius(50%);
    }
  }

  input:checked ~ .checkmark {
    border-color: $primary-color;
    @include box-shadow($top: 0, $left: 1px, $blur: 1px, $color: rgba(97, 121, 133, 0.05));
    color: $darkgrey;

    &:after {
      opacity: 1;
      visibility: visible;
    }
  }

  input.disabled ~ .checkmark,
  input:checked:disabled ~ .checkmark,
  input:disabled ~ .checkmark {
    &:after {
      background-color: $lightgrey;
    }
  }
}

.check-container input:indeterminate ~ .checkmark {
  background-color: $primary-color;
  border-color: $primary-color;
  @include box-shadow($top: 0, $left: 1px, $blur: 1px, $color: rgba(97, 121, 133, 0.05));
  color: $darkgrey;

  &:after {
    border-width: 0 0 2px 0;
    top: 0;
    left: 2px;
    width: 7px;
    height: 7px;
    @include rotate(0);
    opacity: 1;
    visibility: visible;
  }
}

.check-container input[data-indeterminate="true"] ~ .checkmark {
  background-color: $primary-color;
  border-color: $primary-color;
  @include box-shadow($top: 0, $left: 1px, $blur: 1px, $color: rgba(97, 121, 133, 0.05));
  color: $darkgrey;

  &:after {
    border-width: 0 0 2px 0;
    top: 0;
    left: 2px;
    width: 7px;
    height: 7px;
    @include rotate(0);
    opacity: 1;
    visibility: visible;
  }
}
