.modal-backdrop,
.modal {
  background-color: rgba(245, 245, 245, 0.6);
  opacity: 1;
}
.modal + .modal-backdrop {
  opacity: 0;
}
.modal-dialog {
  box-shadow: 0 2px 15px 30px rgba(0, 0, 0, 0.01);
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.modal-open {
  .modal {
    @media (max-width: 767px) {
      overflow: auto;
    }
  }
}

.customModal {
  width: 100%;
  max-width: 1050px;
  &.fullModal {
    background-color: #f2f4f6;
    margin: 0;
    padding: 3rem 5rem;
    max-width: 100%;
  }

  @media (max-width: 1024px) {
    max-width: 800px !important;
  }
  @media (max-width: 992px) {
    max-width: 600px !important;
  }
  @media (max-width: 768px) {
    .docWrap {
      height: auto;
    }
  }
  @media (max-width: 767px) {
    max-width: 450px !important;
  }
  @media (max-width: 595px) {
    max-width: 100% !important;
    padding: 20px;
    margin: 1rem 0 0;
  }

  &--w990 {
    max-width: 990px;
  }
  &--w1150 {
    max-width: 1150px;
  }
  &--w600 {
    max-width: 600px;
  }
  &--w700 {
    max-width: 700px;
  }
  &--w500 {
    max-width: 500px;
  }
  &--w350 {
    max-width: 350px;
  }

  .modal-content {
    @include border-radius(0);
    border: none;
    box-shadow: 0 2px 15px 30px rgba(0, 0, 0, 0.005);

    .ModalHeader {
      border-bottom: 1px solid $sky;
      padding: 0.9rem 1.6rem;

      .infoHeader {
        @include font-size(1.12);

        i {
          color: $midgrey;
          margin-right: 0.7rem;
          &.icon-back-arrow {
            cursor: pointer;
          }
        }
      }
      &--links {
        margin-left: auto;
      }

      .close {
        width: 30px;
        height: 30px;
        line-height: 2.76;
        background-color: $darkgrey;
        color: #fff;
        @include border-radius(50%);
        @include font-size(0.7);
        @include position(absolute, $top: 0, $left: 100%);
        margin: 0 0 0 1.4rem;
        opacity: 1;
        padding: 0;
        text-shadow: none;
        z-index: 1;

        &:hover {
          background-color: #000;
        }
        &:focus {
          outline: none;
        }
        @media (max-width: 595px) {
          top: -10px;
          right: -10px;
          left: auto;
        }
      }
    }

    .modal-body {
      padding: 0;
      max-height: 96vh;
      overflow: auto;

      .modalContent--inner {
        padding: 2.5rem 5rem;

        @media (max-width: 595px) {
          padding: 1rem;
        }

        .modalContent--Header {
          margin-bottom: 2.5rem;
        }

        .chatMsg--block {
          padding: 1.8rem 5rem;
          margin: 0 -5rem;
        }

        .bottomTabs {
          .nav-tabs {
            margin: 0 -5rem;
            padding: 0 5rem;
            border-bottom: 1px solid $sky;
          }
        }
      }
    }
  }

  &--nopadding {
    .modal-content {
      .ModalHeader {
        padding: 0;
        border: none;
      }

      .modalContent .modalContent--inner {
        padding: 2.5rem;

        @media (max-width: 595px) {
          padding: 1rem;
        }
      }

      .modalContent {
        &--header {
          border-bottom: 1px solid $sky;
          padding-bottom: 0.9rem;
          margin-bottom: 1.5rem;

          &__heading {
            @include font-size(1);
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &--alert {
    .modal-content {
      .modalContent {
        &--header {
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 0.5rem;
          &__heading {
            @include font-size(1.62);
          }
        }
      }
    }
  }

  .modalFooter {
    padding: 0 2.5rem 2.5rem;
    border: none;

    @media (max-width: 767px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    &--inner {
      width: 100%;
      display: flex;
      padding-top: 1rem;
      border-top: 1px solid $sky;

      &.termsFooter {
        @media (max-width: 767px) {
          flex-wrap: wrap;
          .check-container {
            margin-bottom: 0.8rem;
          }
        }
      }
    }
  }
  &.modalMxHeight {
    .modal-content {
      .modal-body {
        max-height: none;
      }
    }
  }
}

.browserpopup {
  max-width: 850px;
  .modal-content {
    padding: 50px;
  }
}

.modal {
  &.fade {
    .customModal--center {
      position: absolute;
      left: 50%;
      top: 50%;
      @include translate(-50%, -60%);
      margin: 0;
      -webkit-font-smoothing: subpixel-antialiased;
    }
  }
  &.show {
    .customModal--center {
      position: absolute;
      left: 50%;
      top: 50%;
      @include translate(-50%, -50%);
    }
  }
}

.msgModalIn.modal {
  overflow: hidden !important;
  .customModal .modal-content .modal-body {
    overflow-y: auto;
  }
}

.Modal-Esign {
  .modal-content {
    height: calc(100vh - 6rem);
  }
  .modalContent,
  .modalContent--inner {
    height: 100%;
  }
}

/* LEDGER SETUP MODAL */
.LedgerSetup__Modal {
  width: 800px;
  .modal-content {
    .ModalHeader {
      .close {
        margin: 0;
        top: -13px;
        right: -12px;
        left: unset;
        z-index: 1;
      }
    }
  }

  .LedgerSetup__ModalBody {
    height: 200px;
    .container {
      padding: 16px;
    }

    .SelectContainer {
      width: 250px;
    }

    .LedgerSetup__ErrorMessage {
      padding-left: 16px;
    }
  }
}

/* INVOICE MODAL */
.InvoiceModal {
  .modal-content {
    .ModalHeader {
      .InvoiceSummary__Link {
        display: flex;
        align-items: center;

        .icon-left-arrow {
          font-size: 12px;
          margin-right: 4px;
          color: #919eab;
          cursor: pointer;
        }

        h6 {
          margin: 0;
          span {
            cursor: pointer;
            color: #919eab;
          }
        }
      }
    }

    div.InvoiceModal__ModalBody {
      max-height: 70vh;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 10px 20px;

      .InvoiceModal__Loading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60vh;

        p {
          color: #919eab;
          font-weight: bold;
          font-size: 24px;
        }
      }
    }
  }
}
/* NEW MESSAGE MODAL */
.NewMessageModal__Loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;

  p {
    color: #919eab;
    font-weight: bold;
    font-size: 24px;
  }
}

// MODAL CLOSE ICON
.customModal {
  .modal-content {
    .ModalHeader {
      .close {
        margin: 0;
        top: -13px;
        right: -12px;
        left: unset;
        cursor: pointer;
      }
    }
  }
}

// TERMINATE RECURRING PROCESS (WARNING MODAL)
.WarningModal {
  .modal-content {
    .modalContent--header {
      display: flex;
      align-items: center;

      .MuiSvgIcon-root {
        margin-right: 8px;
      }
    }
  }
}

// MULTI TAGS MONTH YEAR MODAL
.MultiTagsYearMonthModal__ModalBody,
.ReplaceTagsModal__Body,
.EditRelationshipModal__Body,
.InviteContactModal__Body {
  overflow: visible !important;
}

// VOID INVOICE MODAL
.VoidInvoiceModal, .InvoiceDeleteAttachmentModal {
  .modal-content {
    .modal-body {
      overflow: hidden !important;
    }
  }
}
