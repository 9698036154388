.right-panel-top {
  border-bottom: solid 1px #e8ebf0;
  margin-bottom: 10px;
}

.visibility {
  visibility: hidden;
  width: 1px;
  height: 1px;
  position: absolute;
}

.task-action-icons {
  margin-top: 10px;
}
