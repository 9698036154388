@font-face {
  font-family: 'always';
  src: url('fonts/always-webfont.woff2') format('woff2'), url('fonts/always-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LiscioFonts';
  src: url('fonts/icomoon.eot?w54fg9');
  src: url('fonts/icomoon.eot?w54fg9#iefix') format('embedded-opentype'), url('fonts/icomoon.ttf?w54fg9') format('truetype'),
    url('fonts/icomoon.woff?w54fg9') format('woff'), url('fonts/icomoon.svg?w54fg9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'dotsfont';
  src: url('fonts/dotsfont-webfont.woff2') format('woff2'), url('fonts/dotsfont-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'LiscioFonts' !important;
  speak: none;
  font-style: normal;
  font-size: inherit;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga=1';
  -moz-font-feature-settings: 'liga';
  -ms-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga';
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?cq370o');
  src: url('fonts/icomoon.eot?cq370o#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?cq370o') format('truetype'),
    url('fonts/icomoon.woff?cq370o') format('woff'),
    url('fonts/icomoon.svg?cq370o#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?j5juge');
  src: url('fonts/icomoon.eot?j5juge#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?j5juge') format('truetype'),
    url('fonts/icomoon.woff?j5juge') format('woff'),
    url('fonts/icomoon.svg?j5juge#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-font_download:before {
  content: "\e167";
}

.icon-format_align_center:before {
  content: "\e234";
}

.icon-format_align_justify:before {
  content: "\e235";
}

.icon-format_align_left:before {
  content: "\e236";
}

.icon-format_align_right:before {
  content: "\e237";
}

.icon-format_bold:before {
  content: "\e238";
}

.icon-format_clear:before {
  content: "\e239";
}

.icon-format_color_fill:before {
  content: "\e23a";
}

.icon-format_color_text:before {
  content: "\e23c";
}

.icon-format_indent_decrease:before {
  content: "\e23d";
}

.icon-format_indent_increase:before {
  content: "\e23e";
}

.icon-format_italic:before {
  content: "\e23f";
}

.icon-format_line_spacing:before {
  content: "\e240";
}

.icon-format_list_bulleted:before {
  content: "\e241";
}

.icon-format_list_numbered:before {
  content: "\e242";
}

.icon-format_quote:before {
  content: "\e244";
}

.icon-format_size:before {
  content: "\e245";
}

.icon-format_strikethrough:before {
  content: "\e246";
}

.icon-format_textdirection_l_to_r:before {
  content: "\e247";
}

.icon-format_textdirection_r_to_l:before {
  content: "\e248";
}

.icon-format_underlined:before {
  content: "\e249";
}

.icon-flash_off:before {
  content: "\e3e6";
}

.icon-flash_on:before {
  content: "\e3e7";
}

.icon-download:before {
  content: "\e865";
}

.icon-upload2:before {
  content: "\e866";
}

.icon-chevron-up3:before {
  content: "\e873";
}

.icon-chevron-down3:before {
  content: "\e874";
}

.icon-chevron-left2:before {
  content: "\e875";
}

.icon-chevron-right3:before {
  content: "\e876";
}

.icon-help:before {
  content: "\e900";
}

.icon-tickmark_trans_o:before {
  content: "\e901";
}

.icon-tickmark_o:before {
  content: "\e902";
}

.icon-file:before {
  content: "\e903";
}

.icon-Accounts:before {
  content: "\e904";
}

.icon-more:before {
  content: "\e905";
}

.icon-filetype-pdf:before {
  content: "\e906";
}

.icon-Contacts:before {
  content: "\e907";
}

.icon-close-eye:before {
  content: "\e908";
}

.icon-home:before {
  content: "\e909";
}

.icon-Tasks:before {
  content: "\e90a";
}

.icon-undo:before {
  content: "\e90b";
}

.icon-redo:before {
  content: "\e90c";
}

.icon-redo2:before {
  content: "\e90d";
}

.icon-chevron-left:before {
  content: "\e90e";
}

.icon-chevron-right:before {
  content: "\e90f";
}

.icon-chevron-right2:before {
  content: "\e910";
}

.icon-chevron-up:before {
  content: "\e911";
}

.icon-chevron-up2:before {
  content: "\e912";
}

.icon-chevron-down:before {
  content: "\e913";
}

.icon-chevron-down2:before {
  content: "\e914";
}

.icon-change-account:before {
  content: "\e915";
}

.icon-right-arrow2:before {
  content: "\e916";
}

.icon-left-arrow2:before {
  content: "\e917";
}

.icon-up-arrow2:before {
  content: "\e918";
}

.icon-down-arrow2:before {
  content: "\e919";
}

.icon-clone:before {
  content: "\e91a";
}

.icon-note-thin:before {
  content: "\e91b";
}

.icon-contact-thin:before {
  content: "\e91c";
}

.icon-edit:before {
  content: "\e91d";
}

.icon-close2:before {
  content: "\e91e";
}

.icon-account2:before {
  content: "\e91f";
}

.icon-print:before {
  content: "\e920";
}

.icon-delete-small:before {
  content: "\e921";
}

.icon-tag:before {
  content: "\e922";
}

.icon-archive:before {
  content: "\e923";
}

.icon-expand:before {
  content: "\e924";
}

.icon-collapse:before {
  content: "\e925";
}

.icon-close:before {
  content: "\e926";
}

.icon-new-message:before {
  content: "\e927";
}

.icon-upload-file:before {
  content: "\e928";
}

.icon-rightarrow2:before {
  content: "\e929";
}

.icon-leftarrow2:before {
  content: "\e92a";
}

.icon-uparrow2:before {
  content: "\e92b";
}

.icon-downarrow2:before {
  content: "\e92c";
}

.icon-download2:before {
  content: "\e92d";
}

.icon-plus:before {
  content: "\e92e";
}

.icon-minus:before {
  content: "\e92f";
}

.icon-checkbox-default:before {
  content: "\e930";
}

.icon-checkbox-checked:before {
  content: "\e931";
}

.icon-Burger:before {
  content: "\e932";
}

.icon-tickmark:before {
  content: "\e933";
}

.icon-up-arrow:before {
  content: "\e934";
}

.icon-down-arrow:before {
  content: "\e935";
}

.icon-right-arrow:before {
  content: "\e936";
}

.icon-left-arrow:before {
  content: "\e937";
}

.icon-double-arrow:before {
  content: "\e938";
}

.icon-uparrow:before {
  content: "\e939";
}

.icon-downarrow:before {
  content: "\e93a";
}

.icon-rightarrow:before {
  content: "\e93b";
}

.icon-leftarrow:before {
  content: "\e93c";
}

.icon-zoom2:before {
  content: "\e93d";
}

.icon-zoom:before {
  content: "\e93e";
}

.icon-upload:before {
  content: "\e93f";
}

.icon-open-eye:before {
  content: "\e940";
}

.icon-notes:before {
  content: "\e941";
}

.icon-message2:before {
  content: "\e942";
}

.icon-history:before {
  content: "\e943";
}

.icon-create-new:before {
  content: "\e944";
}

.icon-cancel:before {
  content: "\e945";
}

.icon-callout-small:before {
  content: "\e946";
}

.icon-calendar:before {
  content: "\e947";
}

.icon-bell:before {
  content: "\e948";
}

.icon-attachment:before {
  content: "\e949";
}

.icon-message:before {
  content: "\e94a";
}

.icon-menu:before {
  content: "\e94b";
}

.icon-notification-message:before {
  content: "\e94c";
}

.icon-trash1:before {
  content: "\e94d";
}

.icon-upload3:before {
  content: "\e94e";
}

.icon-upload4:before {
  content: "\e94f";
}

.icon-download3:before {
  content: "\e950";
}

.icon-download4:before {
  content: "\e951";
}

.icon-square-chevron-left:before {
  content: "\e952";
}

.icon-square-chevron-right:before {
  content: "\e953";
}

.icon-square-chevron-up:before {
  content: "\e954";
}

.icon-square-chevron-down:before {
  content: "\e955";
}

.icon-square-arrow-left:before {
  content: "\e956";
}

.icon-square-arrow-right:before {
  content: "\e957";
}

.icon-square-arrow-up:before {
  content: "\e958";
}

.icon-square-arrow-down:before {
  content: "\e959";
}

.icon-square-plus:before {
  content: "\e95a";
}

.icon-square-minus:before {
  content: "\e95b";
}

.icon-square-cancel:before {
  content: "\e95c";
}

.icon-square-checkmark:before {
  content: "\e95d";
}

.icon-circle-plus:before {
  content: "\e95e";
}

.icon-circle-minus:before {
  content: "\e95f";
}

.icon-circle-cancel:before {
  content: "\e960";
}

.icon-circle-checkmark:before {
  content: "\e961";
}

.icon-magnifier:before {
  content: "\e962";
}

.icon-delete:before {
  content: "\e963";
}

.icon-label:before {
  content: "\e964";
}

.icon-undo2:before {
  content: "\e965";
}

.icon-chevron-left3:before {
  content: "\e966";
}

.icon-Filter:before {
  content: "\e967";
}

.icon-user-Icon:before {
  content: "\e968";
}

.icon-image-preview:before {
  content: "\e969";
}

.icon-reset-password:before {
  content: "\e96a";
}

.icon-reinvite:before {
  content: "\e96b";
}

.icon-rotate-to-right-button:before {
  content: "\e96c";
}

.icon-rotate-to-left-button:before {
  content: "\e96d";
}

.icon-crop-rotate:before {
  content: "\e96e";
}

.icon-crop11:before {
  content: "\e96f";
}

.icon-admin-new:before {
  content: "\e970";
}

.icon-logout:before {
  content: "\e971";
}

.icon-Admin:before {
  content: "\e972";
}

.icon-excel:before {
  content: "\e973";
}

.icon-powerpoint-file:before {
  content: "\e974";
}

.icon-powerpoint:before {
  content: "\e975";
}

.icon-txt:before {
  content: "\e976";
}

.icon-word:before {
  content: "\e977";
}

.icon-zip:before {
  content: "\e978";
}

.icon-filter-new:before {
  content: "\e979";
}

.icon-expired:before {
  content: "\e97a";
}

.icon-Star:before {
  content: "\e97b";
}

.icon-Camera:before {
  content: "\e97c";
}

.icon-Add-Image:before {
  content: "\e97d";
}

.icon-Invite:before {
  content: "\e97e";
}

.icon-Multi-invite:before {
  content: "\e97f";
}

.icon-signature:before {
  content: "\e980";
}

.icon-back-arrow:before {
  content: "\e981";
}

.icon-taskUnread:before {
  content: "\e982";
}

.icon-bulk_task:before {
  content: "\e983";
}

.icon-pen:before {
  content: "\e984";
}

.icon-no-wifi:before {
  content: "\e985";
}

.icon-Selected:before {
  content: "\e986";
}

.icon-Unselected:before {
  content: "\e987";
}

.icon-login:before {
  content: "\e988";
}

.icon-switch-user:before {
  content: "\e989";
}

.icon-Workspace:before {
  content: "\e98a";
}

.icon-Face-id:before {
  content: "\e98b";
}

.icon-fingerprint:before {
  content: "\e98c";
}

.icon-File:before {
  content: "\e98d";
}

.icon-save:before {
  content: "\e98e";
}

.icon-paper-clicp:before {
  content: "\e98f";
}

.icon-alert:before {
  content: "\e990";
}

.icon-Recurring:before {
  content: "\e991";
}

.icon-unarchive:before {
  content: "\e992";
}

.icon-archive-line:before {
  content: "\e993";
}

.icon-uniE994:before {
  content: "\e994";
}

.icon-uniE995:before {
  content: "\e995";
}

.icon-uniE996:before {
  content: "\e996";
}

.icon-uniE997:before {
  content: "\e997";
}

.icon-Audit-report:before {
  content: "\e998";
}

.icon-Lock:before {
  content: "\e999";
}

.icon-Share:before {
  content: "\e99a";
}

.icon-message1:before {
  content: "\e99b";
}

.icon-File-Upload:before {
  content: "\e99c";
}

.icon-download31:before {
  content: "\e99d";
}

.icon-cancel-agreement:before {
  content: "\e99e";
}

.icon-eDoc:before {
  content: "\e99f";
}

.icon-File_01:before {
  content: "\e9a0";
}

.icon-Add-user:before {
  content: "\e9a1";
}

.icon-view-details:before {
  content: "\e9a2";
}

.icon-insert_link:before {
  content: "\e9a3";
}

.icon-eDoc_New:before {
  content: "\e9a4";
}

.icon-Pinned:before {
  content: "\e9a5";
}

.icon-Chrome:before {
  content: "\e9a6";
}

.icon-uniE9A7:before {
  content: "\e9a7";
}

.icon-uniE9A8:before {
  content: "\e9a8";
}

.icon-Clock:before {
  content: "\e9a9";
}

.icon-question:before {
  content: "\e9aa";
}

.icon-Sign-out:before {
  content: "\e9ab";
}

.icon-iphone:before {
  content: "\e9ac";
}

.icon-Windows:before {
  content: "\e9ad";
}

.icon-Apple:before {
  content: "\e9ae";
}

.icon-firefox:before {
  content: "\e9af";
}

.icon-internet-explorer:before {
  content: "\e9b0";
}

.icon-safari:before {
  content: "\e9b1";
}

.icon-linux:before {
  content: "\e9b2";
}

.icon-computerscreen:before {
  content: "\e9b3";
}

.icon-CSTeam:before {
  content: "\e9b4";
}

.icon-sort:before {
  content: "\e9b5";
}

.icon-move:before {
  content: "\e9b6";
}

.icon-Storage:before {
  content: "\e9b7";
}

.icon-Files:before {
  content: "\e9b8";
}

.icon-warning:before {
  content: "\e9b9";
}

.icon-notification-bell:before {
  content: "\e9ba";
}

.icon-switch-account:before {
  content: "\e9bb";
}

.icon-alphabet_sorting:before {
  content: "\e9bc";
}

.icon-google-drive:before {
  content: "\e9bd";
}

.icon-List_view:before {
  content: "\e9be";
}

.icon-onedrive:before {
  content: "\e9bf";
}

.icon-syncing:before {
  content: "\e9c0";
}

.icon-Create-karbanhq:before {
  content: "\e9c1";
}

.icon-Create-liscio:before {
  content: "\e9c2";
}

.icon-unlink:before {
  content: "\e9c3";
}

.icon-round-more:before {
  content: "\e9c4";
}

.icon-custom-view:before {
  content: "\e9c5";
}

.icon-Email:before {
  content: "\e9c6";
}

.icon-uploaded-vault:before {
  content: "\e9c8";
}

.icon-billing:before {
  content: "\e9c9";
}

.icon-sms:before {
  content: "\e9ca";
}

.icon-Requests:before {
  content: "\e9c7";
}