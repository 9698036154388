$fontglobal: 'Source Sans Pro', sans-serif;
$primary-color: #00aecc;
$secondary-color: #434566;
$darkgrey: #212b36;
$midgrey: #919eab;
$lightgrey: #c4cdd5;
$sky: #dfe3e8;
$lightsky: #f9fafb;
$error: #de3618;
$pink: #eb2aac;
$red: #de3618;
$green: #20d195;
$yellow: #edc200;
$blue: #4c8af0;
