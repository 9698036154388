.has-search {
  .form-control-feedback {
    position: absolute;
    display: block;
    width: 2.25rem;
    height: 2.25rem;
    line-height: 2.25rem;
    text-align: center;
    pointer-events: none;
    color: $midgrey;
  }

  .form-control {
    padding-left: 2.375rem;
    min-height: 36px;
  }
}

.filter-wrapper {
  margin-bottom: 1rem;

  .dropdown-toggle:after {
    content: '';
    display: none;
  }

  .btn-outline-light {
    color: $midgrey;
    border-color: $sky;
    padding: 0.712rem 1.5rem;
    @media screen and (max-width: 767px) {
      padding: 0.5rem;
    }

    &:hover {
      border-color: transparent;
      color: $midgrey;
    }

    &.active,
    &:active,
    &:focus {
      color: #fff;
      background-color: $primary-color;
      border-color: $primary-color;
    }

    &.disabled,
    &:disabled {
      color: $lightgrey;
      background-color: $lightsky;
      border-color: $sky;
    }
  }

  .btn-sm {
    padding: 0.375rem 0.9rem;
  }

  .btn-outline-light:not(:disabled):not(.disabled).active,
  .btn-outline-light:not(:disabled):not(.disabled):active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #fff;
    background-color: $primary-color;
    border-color: $primary-color;
    @include box-shadow(
      $top: 0,
      $left: 5px,
      $blur: 20px,
      $color: rgba(0, 0, 0, 0.1)
    );
  }

  .filterPart {
    @include calc(width, '100% - 101px');
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    .has-search {
      margin-top: 0.35rem;

      & + .filterGroup {
        @include calc(width, '100% - 301px');
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
    &--archive {
      @include calc(width, '100% - 270px');
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      .form-group.has-search {
        width: 260px;
      }
      .has-search + .filterGroup {
        @include calc(width, '100% - 275px');
      }
    }
    &--restricted {
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      .form-group.has-search {
        width: 140px;
      }
      .has-search + .filterGroup {
        @include calc(width, '100% - 160px');
      }
    }
  }

  .filterGroup {
    width: 100%;
    margin-top: 0.35rem;

    .btn {
      @include font-size(0.87);
      font-weight: 600;
      text-transform: uppercase;
    }

    .select-filter-list {
      padding-left: 62px;

      & > .badge-filter {
        margin-bottom: 5px;

        .icon-close2 {
          font-size: 0.5rem;
        }
      }
    }
  }
}

.selected-filters-bar {
  display: flex;
  flex-direction: row;
  margin-top: -0.5em;
}

.filter-clear-button {
  color: #fff;
  background-color: rgb(0, 174, 204);
  border: 2px solid rgb(0, 174, 204);
  margin-right: -5px;
  margin-top: 3px;
  font-size: 0.68rem;
  white-space: nowrap;
  *:hover {
    color: rgb(0, 175, 204);
  }
}

.icon-small {
  font-size: 0.75rem !important;
}

.icon-medium {
  font-size: 0.9rem !important;
}

.filter-left-block {
  width: 24%;
  min-width: 150px;
  max-width: 350px;
}

.clearable-search {
  display: flex;
  max-width: 160px;
  .icon-small {
    margin-left: -2em;
    background-color: transparent;
  }
}

.date-range-clear {
  position: relative;
  float: right;
  margin-left: -35px;
  margin-top: -7px;
  padding: 0 0.5em;
  font-size: 10px;
  font-weight: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 2.5em;
  align-items: end;
}

.clear-dates {
  font-size: 10px;
}
