.accordion {
  margin-top: 60px;
  margin-bottom: 60px;
  cursor: pointer;
  .card {
    border: none;
  }
  .card-header {
    &.collapsed {
      border-bottom: solid 1px #dee2e6;
    }
    &:after,
    &.collapsed:after {
      line-height: 36px;
    }
    background: none;
    border: none;
    padding-left: 0;
    padding-right: 0;
    border-top: solid 1px #dee2e6;
    line-height: 28px;
    border-bottom: none;
  }
  .card-title {
    margin-bottom: 0.05rem;
    display: inline-block;
    margin-top: 0.25rem;
  }
  .card-body {
    border-bottom: solid 1px #dee2e6;
    padding: 1.25rem 0;
  }
  .card-header {
    &.collapsed:after {
      content: '\f067';
    }
    &:after {
      font-family: 'FontAwesome';
      content: '\f068';
      float: right;
    }
  }
}
