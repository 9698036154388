.formbtn {
  display: flex;
  margin-top: 1.6rem;

  &--border {
    padding-top: 1rem;
    border-top: 1px solid $sky;
  }
}

.btn-div {
  &:before {
    content: '|';
    font-size: 1.3rem;
    margin: 0 0.5rem;
    color: $sky;
  }
}

.btnOnlyLink {
  color: $midgrey;

  &:hover {
    color: $primary-color;
  }
}

.btn {
  padding: 0.625rem 1.5rem;
  line-height: 1.465;
  white-space: nowrap;
  @include font-size(0.94);
  @include border-radius(2px);
  border-width: 2px;
  border-style: solid;
  @include transition(all ease-in-out 350ms);

  &.dropdown-toggle {
    &:after {
      display: none;
    }
  }
  &.btn-sm {
    padding: 0.313rem 0.9rem;
  }
  i {
    padding-right: 10px;
    font-style: normal;
    transition: none;
  }

  &--onlyicon {
    padding: 0.594rem 0.86rem;
    i {
      padding-right: 0;
      color: $midgrey;
      vertical-align: middle;
    }
    &.btn-sm {
      padding: 0.282rem 0.54rem;
    }
    &.linkPrimary {
      i {
        padding-right: 0;
        color: $primary-color;
        vertical-align: middle;
      }
      &:hover {
        i {
          color: $midgrey;
        }
      }
    }
  }

  &:hover {
    text-decoration: none;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &.disabled,
  &:disabled {
    pointer-events: auto;
  }
}

.btn--leftspace {
  .btn + .btn {
    margin-left: 0.75rem;
  }
}

.btn--rightspace {
  .btn + .btn {
    margin-right: 0.75rem;
  }
}

.btn.disabled,
.btn:disabled,
input[type='button'].disabled,
input[type='button']:disabled,
input[type='submit'].disabled,
input[type='submit']:disabled,
button.disabled,
button:disabled {
  cursor: not-allowed;
}

.btn.disabled,
.btn:disabled {
  background-color: $sky;
  color: $midgrey;
  border-color: $sky;
}

.btn-primary {
  color: #fff;
  background-color: $primary-color;
  box-shadow: none;
  border-color: $primary-color;

  &:hover,
  &:focus {
    color: #fff;
    background-color: $primary-color;
    border-color: $primary-color;
    @include box-shadow($left: 0px, $top: 5px, $blur: 20px, $color: rgba(0, 0, 0, 0.1));
  }
  &:active {
    color: #fff;
    background-color: darken($primary-color, 16%);
    border-color: darken($primary-color, 16%);
    @include box-shadow($left: 0, $top: 5px, $blur: 20px, $color: rgba(0, 0, 0, 0.1));
  }

  &:not(:disabled):not(.disabled) {
    &.active,
    &:active {
      color: #fff;
      background-color: darken($primary-color, 16%);
      border-color: darken($primary-color, 16%);
      @include box-shadow($left: 0, $top: 5px, $blur: 20px, $color: rgba(0, 0, 0, 0.1));

      &:hover,
      &:focus {
        @include box-shadow($left: 0, $top: 5px, $blur: 20px, $color: rgba(0, 0, 0, 0.1));
      }
    }
  }

  &.disabled,
  &:disabled {
    background-color: $sky;
    color: $midgrey;
    border-color: $sky;
    cursor: not-allowed;

    &:hover {
      box-shadow: none;
    }
  }
}

.btn-outline-light {
  background: #ffffff;
  border-color: $sky;
  color: $darkgrey;

  &:hover,
  &:focus {
    border-color: #fff;
    background: #fff;
    @include box-shadow($left: 0, $top: 5px, $blur: 25px, $color: rgba(0, 0, 0, 0.1));
    color: $darkgrey;
  }

  &:active {
    border-color: $primary-color;
    background-color: #fff;
    @include box-shadow($left: 0, $top: 5px, $blur: 25px, $color: rgba(0, 0, 0, 0.1));
    color: $darkgrey;
  }

  &:not(:disabled):not(.disabled) {
    &.active,
    &:active {
      border-color: $primary-color;
      background-color: #fff;
      color: $darkgrey;
      @include box-shadow($left: 0, $top: 5px, $blur: 25px, $color: rgba(0, 0, 0, 0.1));

      &:focus {
        @include box-shadow($left: 0, $top: 5px, $blur: 25px, $color: rgba(0, 0, 0, 0.1));
        color: $darkgrey;
      }
    }
  }

  &.disabled,
  &:disabled {
    background-color: #fff;
    color: $midgrey;
    border-color: $sky;
    cursor: not-allowed;
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }
  }
}

.btn-outline-none {
  background: transparent;
  border-color: transparent;
  color: $darkgrey;

  &:hover,
  &:focus {
    border-color: transparent;
    background: rgba(255, 255, 255, 0.1);
    @include box-shadow($left: 0, $top: 5px, $blur: 25px, $color: rgba(0, 0, 0, 0.1));
    color: $darkgrey;
  }

  &:active {
    border-color: $sky;
    background: rgba(255, 255, 255, 0.1);
    @include box-shadow($left: 0, $top: 5px, $blur: 25px, $color: rgba(0, 0, 0, 0.1));
    color: $darkgrey;
  }

  &:not(:disabled):not(.disabled) {
    &.active,
    &:active {
      color: $darkgrey;
      border-color: $sky;
      background: rgba(255, 255, 255, 0.1);
      @include box-shadow($left: 0, $top: 5px, $blur: 25px, $color: rgba(0, 0, 0, 0.1));

      &:focus {
        @include box-shadow($left: 0, $top: 5px, $blur: 25px, $color: rgba(0, 0, 0, 0.1));
        background: rgba(255, 255, 255, 0.1);
        border-color: $sky;
        color: $darkgrey;
      }
    }
  }

  &.disabled,
  &:disabled {
    background-color: #fff;
    color: $midgrey;
    border-color: $sky;
    cursor: not-allowed;
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }
  }
}

.btn-link {
  background: transparent;
  border-color: transparent;
  color: $darkgrey;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  &:active,
  &:hover {
    border-color: transparent;
    background: transparent;
    box-shadow: none;
    color: $primary-color;

    i {
      color: $primary-color;
    }
  }

  &:active,
  &:focus {
    border-color: transparent;
    background: transparent;
    box-shadow: none;
    color: $primary-color;
    outline: none;
    text-decoration: none;

    i {
      color: $primary-color;
    }
  }

  &:not(:disabled):not(.disabled) {
    background: transparent;
    border-color: transparent;

    &.active,
    &:active {
      border-color: transparent;
      background: transparent;
      box-shadow: none;
      color: $primary-color;
      outline: none;
      text-decoration: none;

      &:focus {
        box-shadow: none;
        border-color: transparent;
        outline: none;
      }
    }
  }

  &.disabled,
  &:disabled {
    background-color: transparent;
    color: $sky;
    border-color: transparent;
    cursor: not-allowed;
    pointer-events: auto;
    i {
      color: $sky;
    }
    &:hover {
      box-shadow: none;
      color: $sky;
      i {
        color: $sky;
      }
    }
  }
}

.btn-group-transparent {
  border: 2px solid $sky;
  @include border-radius(0.2rem);

  .btn + .btn {
    margin-left: 0;
  }

  .btn-sm {
    padding: 0.313rem 0.7rem;
  }

  .btn-transparent {
    background-color: transparent;
    border-color: transparent;
    color: $midgrey;
    z-index: 0;
    border-width: 0 0 0 1px;
    border-style: none none none solid;
    border-color: transparent transparent transparent $sky;

    &:first-child {
      border-left: none;
    }

    &.btn-secondary:not(:disabled):not(.disabled):active:focus,
    &.focus,
    &:focus,
    &:hover {
      background-color: $lightsky;
      color: $primary-color;
      box-shadow: none;
      border-left-color: $sky;

      i {
        color: $primary-color;
      }
    }
  }
}

.mailFilter {
  .btn-group-transparent {
    border-color: $sky;
    @include border-radius(0);

    .btn-transparent {
      color: $darkgrey;
      z-index: 0;

      &.active,
      &:focus,
      &:hover,
      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):active:focus {
        background-color: $sky;
        color: $darkgrey;
        box-shadow: none;
        border-color: $sky;
      }
    }
  }

  &--primary {
    .btn-group-transparent {
      border: none;
      @include border-radius(0);

      .btn-transparent {
        color: #fff;
        border-top: 2px solid $primary-color;
        border-bottom: 2px solid $primary-color;
        border-left: 2px solid $primary-color;
        background-color: $primary-color;

        &:first-child {
          border-left: 2px solid $primary-color;
        }

        &:last-child {
          border-right: 2px solid $primary-color;

          &.active,
          &:focus,
          &:hover {
            border-right-color: darken($primary-color, 16%);
          }
        }

        &.active,
        &:focus,
        &:hover,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):active:focus {
          background-color: darken($primary-color, 16%);
          border-top-color: darken($primary-color, 16%);
          border-bottom-color: darken($primary-color, 16%);
          border-left-color: darken($primary-color, 16%);
          border-right-color: darken($primary-color, 16%);
          color: #fff;
          box-shadow: none;
        }
      }
    }
  }
}

.cntBtn {
  display: flex;
  align-items: center;
  border: 1px solid $primary-color;
  @include transition(all ease-in-out 350ms);
  span {
    flex: 0 0 40px;
    max-width: 40px;
    text-align: center;
  }
  img {
    width: 20px;
  }
  .csBtn {
    flex-grow: 1;
    font-weight: 500;
    padding: 10px 24px;
    background-color: $primary-color;
    color: #fff;
    border: none;
  }
  &:hover,
  &:focus {
    color: #fff;
    border-color: $primary-color;
    @include box-shadow($left: 0px, $top: 5px, $blur: 20px, $color: rgba(0, 0, 0, 0.1));
  }
  &:disabled {
    box-shadow: none;
    border: 1px solid #dfe3e8;
    .csBtn {
      color: #c4cdd5;
      background-color: #f9fafb;
    }
  }
}

.cntBtnGgle {
  display: flex;
  align-items: center;
  border: 1px solid $primary-color;
  @include transition(all ease-in-out 350ms);
  span {
    flex: 0 0 40px;
    max-width: 40px;
    text-align: center;
  }
  img {
    width: 20px;
  }
  .csBtnGgle {
    flex-grow: 1;
    font-weight: 500;
    padding: 10px 24px;
    background-color: #4285f4;
    color: #fff;
    border: none;
  }
  &:hover,
  &:focus {
    color: #fff;
    border-color: #4285f4;
    background-color: #4285f4;
    outline: none;
  }
  &:disabled {
    border: 1px solid #dfe3e8;
    .csBtnGgle {
      color: #c4cdd5;
      background-color: #f9fafb;
    }
  }
}

.filter-btn {
  color: #919eab;
  font-weight: 600;
  font-size: 14px;
}

/* ACCOUNT BUTTON GROUP */
.AccountButtonGroup {
  margin-bottom: 40px;
}
