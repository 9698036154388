// TO-DO: create TinymceEditior.css file and host it in aws server
.tox-tinymce {
  border: 2px solid #dfe3e8 !important;
  border-radius: 0px !important;
  font-family: inherit !important;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
  padding: 0px !important;
}
