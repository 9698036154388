@import 'scss/variables.scss';
@import 'scss/mixins.scss';

// .center-wrapper.chat-align {
//   position: relative;
//   height: calc(100% - 76px);
//   padding: 1.5rem 2.3rem 2.5rem;
// }

.chat-Message {
  background: $lightsky;
  border: 2px solid $darkgrey;
  border-bottom: none;
  position: fixed;
  right: 2.5%;
  bottom: 0;
  width: 35%;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  z-index: 1;
  .Chat-Txt,
  .Chat-show {
    flex: 1;
  }
  .Chat-Txt {
    flex-basis: 200px;
    padding-left: 0.5rem;
    padding-top: 6px;
  }
  .Chat-show {
    text-align: right;
    padding-right: 0.9rem;
    a {
      font-size: 0.94rem;
      color: #000;
    }
    .cross-btn {
      padding-left: 0.9rem;
      color: #000;
      font-size: 0.94rem;
      padding: 0rem;
    }
    .icon-close2 {
      padding-left: 0.9rem;
      vertical-align: middle;
      font-size: 0.7rem;
      color: #000;
    }
  }
  .chat-Img {
    width: auto;
    height: 20px;
  }
  h2 {
    font-size: 1rem;
    display: inline-block;
    padding-left: 1rem;
  }
  .icon-message:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #00aecc;
  }
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 767px) {
  .chat-Message {
    width: 92%;
    .chat-Img {
      width: auto;
      height: 16px;
    }
  }
  .chat-Message h2 {
    font-size: 0.9rem;
    display: inline-block;
    padding-left: 0.2rem;
  }
  .chat-Message .Chat-show .cross-btn {
    padding-left: 0.3rem;
    font-size: 0.8rem;
  }
  .Chat-show {
    a {
      padding: 0;
    }
  }
}
