.address-autocomplete {
  position: relative;
  .autocomplete-dropdown-container {
    position: absolute;
    background-color: white;
    z-index: 1;
    border: 2px solid #7B8897;
    width: 100%;
    padding-top: 2px;
    margin-top: -2px;
    border-top: transparent;
  }
  .suggestion-item {
    padding: .375rem .75rem;
  }
}