@import 'scss/variables.scss';
@import 'scss/mixins.scss';

.Edit-btn-right {
  text-align: right;
}

.Edit-btn-right button {
  font-size: 0.94rem;
  color: $midgrey;
}

.media {
  .btn-Radius {
    border-radius: 4px;
    padding: 0.45rem 0.9rem;
    line-height: 1;
    border-width: 1px;
    color: $lightgrey;
    letter-spacing: 1px;
    @include font-size(0.62);
    &:not(:disabled):not(.disabled):active:focus,
    &:focus {
      color: darken($primary-color, 5%);
    }
  }

  .media-left {
    cursor: default;
  }

  .user-Media-P {
    padding: 1rem;

    .media-heading {
      @include font-size(2);
    }
  }
}

.btn--leftspace-edit {
  margin-left: 1rem;
}

.dragWrap {
  .Drag-Img {
    width: 250px;
    height: 250px;
  }
}

.modalContent--innerP {
  padding: 1.5rem;
}

.file-W-auto {
  max-width: 350px;
  margin: 0 auto 1rem;
  height: 350px;
  position: relative;

  .Choose-text-Hid h5 {
    background-color: $primary-color;
  }
}

.imageUploadWrap .DragDrop {
  height: 350px;
}

.btn-Upload-Image {
  margin-top: 1rem;

  .Choose-text-Hid {
    vertical-align: top;
    display: inline-block;
    position: relative;
    width: 100%;
  }

  .Choose-text-Hid .browse-Up-btn {
    width: 100%;
    @include position(absolute, $top: 0, $left: 0);
    height: 47px;
    z-index: 2;
    text-align: center;
    line-height: 47px;
    cursor: pointer;
    margin-bottom: 0;
    font-weight: normal;
  }

  .Input-Chosse-btn {
    opacity: 0;
    position: relative;
    z-index: 1;
  }

  h5.browse-Up-btn {
    background: transparent;
    height: auto;
    line-height: normal;
    margin: 0;
    top: 15%;
  }
}

.cropWrap {
  width: 100%;
  max-width: 350px;
  margin: 0 auto 1rem;
  position: relative;

  .cropWrap--image {
    margin-bottom: 0;
  }

  &--image {
    width: 100%;
    height: 350px;
    margin: 0 auto 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: lighten($sky, 7%);
    position: relative;
  }
}

.removeImage {
  @include position(absolute, $top: 20px, $right: 20px);
  color: #fff;

  &:hover {
    color: $primary-color;
  }
}

.modalContent--footer {
  display: flex;
  width: 100%;
  margin-top: 0;
  border-top: none;
  padding-top: 0;
}

.btn-Upload-Image {
  margin: 1rem auto;
  width: 100%;
  max-width: 350px;
}

.Img-Browse-btn {
  margin: 0;
  width: auto;

  .Choose-text-Hid {
    width: auto;

    .Input-Chosse-btn {
      position: absolute;
      width: 0;
      overflow: hidden;
    }

    .browse-Up-btn {
      margin-bottom: 0;
      height: auto;
      position: static;
      cursor: pointer;
      color: $midgrey;
      font-weight: 600;
      @include font-size(0.87);

      &:hover {
        color: $primary-color;
      }
    }
  }
}
/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (max-width: 767px) {
  .media .Img-P {
    width: 80px;
    height: 80px;
  }

  .center-wrapper {
    .media-heading {
      font-size: 1rem;
    }
  }

  .media .btn-Radius {
    padding: 0.2rem 0.3rem;
  }
}
