@import 'scss/variables';

.page-title {
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 0;
  letter-spacing: 1px;
}

.select-wrapper {
  min-width: 220px;
  font-size: 0.9rem;
  margin-right: 12px;
}

.smart-vault-account-name {
  border: 2px solid $sky;
  border-radius: 4px;
  min-height: 38px;
  padding: 6px 10px;
  color: hsl(0, 0%, 50%);

  &:hover {
    cursor: not-allowed;
  }
}

.input-label {
  width: 135px
}
