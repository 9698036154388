.badge {
  font-size: 0.62rem;
  border-width: 1px;
  border-style: solid;
  padding: 0.2rem 0.4rem;
  @include border-radius(0.12rem);
  font-weight: 400;
  &-primary {
    border: solid 1px $primary-color;
    color: $primary-color;
    background-color: transparent;
  }
}
.badge-get-signature {
  border: solid 1px #62deb4;
  color: #62deb4;
}
.badge-onboard {
  border: solid 1px #ff7094;
  color: #ff7094;
}
.badge-primary {
  border: solid 1px #ff7094;
  color: #ff7094;
}
.badge-request-info {
  border: solid 1px #5352ee;
  color: #5352ee;
}
.badge-manage-to-go,
.badge-pink {
  border: solid 1px #d170ff;
  color: #d170ff;
}
.badge-to-do {
  border: solid 1px #d9c06d;
  color: #d9c06d;
}
.badge-comment {
  background: transparent;
  border: transparent;
  font-size: 10px;
  letter-spacing: 1px;
  color: $midgrey;
  font-weight: 400;
  text-transform: uppercase;
}
.badge-light {
  background: $sky;
  color: $midgrey;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  padding: 0.45em 0.4em;
  letter-spacing: 1px;
  margin-right: 5px;
  vertical-align: middle;
}
.file-type {
  background-color: $midgrey;
  border-radius: 2px;
  color: $sky;
  padding: 5px 2px;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0;
  vertical-align: middle;
}
.badge-filter {
  display: inline-block;
  border: solid 1px $sky;
  border-radius: 2px;
  padding: 1px 5px;
  font-size: 12px;
  text-transform: none;
  line-height: 16px;
  letter-spacing: 1px;
  color: $midgrey;
  margin-right: 5px;
  a {
    font-size: 10px;
    color: $midgrey;
    margin-left: 5px;
    i {
      &:focus {
        outline: none;
      }
    }
  }
}

.green-badge {
  font-size: 0.85rem;
  display: inline-block;
  padding: 1px 8px;
  border-radius: 12px;
  text-align: center;
  color: #18AE27;
  background: #E6FCE8;
  font-weight: 600;
}
