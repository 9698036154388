@import 'scss/variables.scss';
@import 'scss/mixins.scss';

@font-face {
  font-family: 'liscio';
}

#upload_doc, .upload_doc {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.NewNoteEmail .form-inline-label{
  display:grid;
}
.NewNoteEmail .form-inline-label .select-custom-class{
  max-width:unset;
}
.NewNoteEmail .CustomFieldsDatePicker{
  border-bottom: 1px solid hsl(0, 0%, 80%);
  border-radius: 2px;
}
.NewNoteEmail .chip{
  border-bottom: 1px solid hsl(0, 0%, 80%);
    border-radius: 2px;
    padding-bottom: 2px;
}
.NewNoteEmail .Notes--uname{
  background-color: #F9FAFB;
    border-radius: 42px;
    padding: 0px 10px 1px 3px;
}
.NewNoteEmail .dragBrowse {
  background-color:#F9FAFB;
}