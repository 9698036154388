/*!
 * Box UI Element
 *
 * Copyright 2019 Box, Inc. All rights reserved.
 *
 * This product includes software developed by Box, Inc. ("Box")
 * (http://www.box.com)
 *
 * ALL BOX SOFTWARE IS PROVIDED "AS IS" AND ANY EXPRESS OR IMPLIED
 * WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
 * MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE ARE DISCLAIMED.
 * IN NO EVENT SHALL BOX BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
 * SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT
 * LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE,
 * DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY
 * THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT
 * (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE
 * OF THIS SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
 *
 * See the Box license for the specific language governing permissions
 * and limitations under the license.
 */

#box-picker {
  .be-logo {
    padding-left: 20px
  }

  .be-logo .be-logo-custom {
    max-width: 80px;
    max-height: 32px
  }

  .be-is-small .be-logo .be-logo-custom {
    max-width: 75px
  }

  .be-logo .be-logo-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75px;
    height: 32px;
    background-color: #e8e8e8;
    border: 1px dashed
  }

  .be-is-small .be-logo .be-logo-placeholder {
    width: 60px
  }

  .be-logo .be-logo-placeholder span {
    font-size: 10px;
    text-transform: uppercase
  }

  .be-logo svg {
    display: block
  }

  .be .be-header {
    display: flex;
    flex: 0 0 70px;
    align-items: center;
    padding: 0 25px 0 0;
    background: #fcfcfc;
    border-bottom: 1px solid #e8e8e8
  }

  .be-is-small .be .be-header {
    padding-right: 20px
  }

  .be .be-header .be-search {
    flex: 1;
    padding-left: 20px
  }

  .be .be-header input[type=search] {
    width: 100%;
    font: inherit;
    -webkit-appearance: none
  }

  .crawler {
    width: 10px;
    margin: 0 auto;
    font-size: 0;
    white-space: nowrap
  }

  .crawler div {
    display: inline-block;
    width: 2px;
    height: 10px;
    background-color: #0061d5;
    border-radius: 4px;
    animation: crawler .66s ease-in-out infinite
  }

  .crawler.is-small {
    transform: scale(.7)
  }

  .crawler.is-medium {
    transform: scale(1.5)
  }

  .crawler.is-large {
    transform: scale(2)
  }

  .is-loading .crawler div {
    transform: translateZ(0);
    will-change: transform, opacity
  }

  .crawler div:nth-child(2) {
    margin-left: 2px;
    animation-delay: .1s
  }

  .crawler div:last-child {
    left: 8px;
    margin-left: 2px;
    animation-delay: .2s
  }

  @keyframes crawler {
    0%, 80%, to {
      transform: scaleY(1.2);
      opacity: .5
    }
    40% {
      transform: scaleY(1.6);
      opacity: 1
    }
  }

  .loading-indicator-wrapper {
    position: relative
  }

  .loading-indicator-wrapper .loading-indicator-veil {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex
  }

  .loading-indicator-wrapper .loading-indicator-veil.is-with-center-crawler {
    align-items: center
  }

  .loading-indicator-wrapper .loading-indicator-veil.blur-content {
    background-color: hsla(0, 0%, 98.4%, .75)
  }

  .loading-indicator-wrapper .loading-indicator-veil.hide-content {
    background-color: #fff
  }

  .radar {
    position: relative
  }

  .radar .radar-dot:before {
    top: -4px;
    left: -4px;
    z-index: 193;
    width: 8px;
    height: 8px;
    border: 2px solid #fff;
    border-radius: 50%
  }

  .radar .radar-dot:after, .radar .radar-dot:before {
    position: absolute;
    background-color: #0061d5;
    content: " "
  }

  .radar .radar-dot:after {
    z-index: 192;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, .3);
    animation-name: ripple;
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: infinite
  }

  .radar .radar-circle:before {
    border: 1px solid #0061d5;
    border-radius: 50%
  }

  .radar .radar-circle:after, .radar .radar-circle:before {
    position: absolute;
    top: -20px;
    left: -20px;
    z-index: 192;
    width: 40px;
    height: 40px;
    content: " "
  }

  .radar .radar-circle:after {
    background-color: #0061d5;
    border-radius: 50%;
    opacity: .3
  }

  @keyframes ripple {
    0% {
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      opacity: .75
    }
    to {
      top: -20px;
      left: -20px;
      width: 40px;
      height: 40px;
      opacity: 0
    }
  }

  .radar-animation-target-attached-bottom.radar-animation-target-attached-center > .radar, .radar-animation-target-attached-bottom.radar-animation-target-attached-left > .radar, .radar-animation-target-attached-bottom.radar-animation-target-attached-right > .radar {
    margin-top: 1px
  }

  .radar-animation-target-attached-middle.radar-animation-target-attached-right > .radar {
    margin-left: 1px
  }

  .radar-animation-target-attached-middle.radar-animation-target-attached-center > .radar {
    margin-top: 1px
  }

  .radar-animation-target-attached-middle.radar-animation-target-attached-left > .radar {
    margin-right: 1px
  }

  .radar-animation-target-attached-top.radar-animation-target-attached-center > .radar, .radar-animation-target-attached-top.radar-animation-target-attached-left > .radar, .radar-animation-target-attached-top.radar-animation-target-attached-right > .radar {
    margin-bottom: 1px
  }

  .radar-animation-element {
    z-index: 190
  }

  .be-breadcrumb {
    display: flex;
    align-items: center;
    min-width: 20px
  }

  .be-breadcrumb:only-child {
    min-width: 64px
  }

  .be .be-breadcrumb .btn-plain {
    color: #6f6f6f
  }

  .be .be-breadcrumb .btn-plain:last-child {
    color: #222
  }

  .be-breadcrumb-seperator {
    flex-shrink: 0;
    margin: 1px 8px 4px 5px;
    border-style: none !important;
    transform: none !important;
    color: rgb(153, 153, 153);
    border-width: 2px;
    height: 7px;
    width: 7px;
  }

  .bdl-Breadcrumb-title {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
  }

  .dropdown-menu-element-attached-center .aria-menu, .dropdown-menu-element-attached-left .aria-menu, .dropdown-menu-element-attached-right .aria-menu {
    animation: fade-in .15s cubic-bezier(0, 0, .6, 1)
  }

  .dropdown-menu-element {
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-weight: 400;
    font-size: 14px;
    color: #222;
    line-height: 20px;
    letter-spacing: .3px;
    z-index: 170;
    margin-top: 5px
  }

  .dropdown-menu-element, .dropdown-menu-element *, .dropdown-menu-element :after, .dropdown-menu-element :before {
    box-sizing: border-box !important
  }

  @media (max-width: 767px) {
    .bdl-DropdownMenu--responsive.dropdown-menu-enabled {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin-top: 0;
      margin-bottom: 64px;
      transform: none !important
    }

    .bdl-DropdownMenu--responsive .aria-menu:not(.submenu) {
      height: 100%;
      margin-top: 64px;
      overflow-x: auto;
      background-color: #fbfbfb;
      border: none;
      border-radius: 0;
      box-shadow: none
    }

    .bdl-DropdownMenu--responsive .aria-menu:not(.submenu) .bdl-MenuHeader {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      display: flex;
      height: 64px;
      padding: 12px;
      background-color: #fff;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, .05)
    }

    .bdl-DropdownMenu--responsive .aria-menu:not(.submenu) .submenu:not(.is-hidden) {
      top: 100%;
      right: 0;
      bottom: auto;
      left: auto
    }

    .bdl-DropdownMenu--responsive .aria-menu:not(.submenu) .submenu:not(.is-hidden).is-right-bottom-aligned {
      top: auto;
      bottom: 100%
    }
  }

  .aria-menu {
    padding: 12px;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .1);
    margin: 0;
    white-space: nowrap;
    list-style: none;
    background: #fff
  }

  .aria-menu:not(.should-outline-focus):focus {
    outline: none
  }

  .aria-menu [role=separator] {
    height: 0;
    margin: 8px 4px;
    border: 0;
    border-top: 1px solid #e8e8e8
  }

  .aria-menu .lnk, .aria-menu .lnk:focus, .aria-menu .lnk:hover, .aria-menu a, .aria-menu a:focus, .aria-menu a:hover {
    text-decoration: none
  }

  .aria-menu .bdl-MenuHeader {
    display: none
  }

  .menu-item {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 30px;
    color: #222;
    padding: 8px 48px 8px 8px;
    border-radius: 8px;
    cursor: pointer
  }

  .menu-item.is-active {
    background-color: rgba(34, 34, 34, .05)
  }

  .menu-item.menu-section-header {
    color: #000;
    font-weight: 700;
    font-size: 10px
  }

  .menu-item[aria-disabled] {
    cursor: default;
    opacity: .4
  }

  .menu-item.is-select-item {
    position: relative;
    padding-left: 38px
  }

  .menu-item.is-select-item.is-selected:before {
    position: absolute;
    top: 10px;
    left: 14px;
    width: 6px;
    height: 12px;
    margin: auto;
    border-color: #222;
    border-style: none solid solid none;
    border-width: 2px;
    transform: rotate(45deg);
    content: ""
  }

  .menu-item:not([aria-disabled]):focus, .menu-item:not([aria-disabled]):hover {
    color: #222;
    background-color: #f4f4f4
  }

  .menu-item svg:not(.menu-item-arrow) {
    margin-right: 16px
  }

  .be .btn-plain.be-breadcrumbs-drop-down {
    color: #6f6f6f;
    letter-spacing: 1.5px
  }

  .be .btn-plain.be-breadcrumbs-drop-down.be-breadcrumbs-condensed {
    letter-spacing: inherit
  }

  .be-breadcrumbs {
    display: flex;
    align-items: center;
    min-width: 0;
    padding-right: 20px
  }

  .be-breadcrumb-more {
    display: flex;
    align-items: center;
    min-width: 35px
  }

  .be-inline-breadcrumbs {
    display: flex;
    min-width: 0;
    color: #6f6f6f;
    font-size: 11px
  }

  .be-inline-breadcrumbs .be-breadcrumbs {
    padding-right: 0
  }

  .be-inline-breadcrumbs .be-breadcrumb:first-child {
    min-width: auto
  }

  .be .be-inline-breadcrumbs .be-breadcrumb .btn-plain {
    color: inherit;
    font-size: 11px
  }

  .be-inline-breadcrumbs .be-breadcrumb-more {
    min-width: 16px
  }

  .bdl-Tooltip, .tooltip {
    position: relative;
    max-width: 200px;
    padding: 8px 10px;
    color: #fff;
    font-size: 13px;
    line-height: 1.4;
    word-wrap: break-word;
    background-color: #4e4e4e;
    border-color: #4e4e4e;
    border-radius: 4px
  }

  .bdl-Tooltip:before, .tooltip:before {
    position: absolute;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    content: ""
  }

  .bdl-Tooltip.is-error:after, .is-error.tooltip:after {
    position: absolute;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    content: ""
  }

  .bdl-Tooltip.is-error, .is-error.tooltip {
    color: #222;
    background-color: #fdebee;
    border: 1px solid #f69bab
  }

  .bdl-Tooltip.is-error .tooltip-close-button .bdl-Tooltip-iconClose path, .is-error.tooltip .tooltip-close-button .bdl-Tooltip-iconClose path {
    fill: #222
  }

  .bdl-Tooltip.is-callout, .is-callout.tooltip {
    color: #fafbfd;
    background-color: #003c84;
    border-color: #003c84
  }

  .bdl-Tooltip.is-callout a, .is-callout.tooltip a {
    color: #fafbfd
  }

  .bdl-Tooltip.is-callout .tooltip-close-button .bdl-Tooltip-iconClose path, .is-callout.tooltip .tooltip-close-button .bdl-Tooltip-iconClose path {
    fill: #fafbfd
  }

  .bdl-Tooltip.is-metadata-tooltip, .is-metadata-tooltip.tooltip {
    margin-left: -8px
  }

  .bdl-Tooltip.with-close-button, .with-close-button.tooltip {
    padding-right: 28px
  }

  .bdl-Tooltip .tooltip-close-button, .tooltip .tooltip-close-button {
    position: absolute;
    top: 9px;
    right: 3px;
    width: 22px;
    cursor: pointer
  }

  .bdl-Tooltip .tooltip-close-button .bdl-Tooltip-iconClose, .tooltip .tooltip-close-button .bdl-Tooltip-iconClose {
    height: 16px
  }

  .bdl-Tooltip .tooltip-close-button .bdl-Tooltip-iconClose path, .tooltip .tooltip-close-button .bdl-Tooltip-iconClose path {
    fill: #fff
  }

  .tooltip-target-attached-top.tooltip-target-attached-center > .bdl-Tooltip, .tooltip-target-attached-top.tooltip-target-attached-center > .tooltip {
    margin-bottom: 10px
  }

  .tooltip-target-attached-top.tooltip-target-attached-center > .bdl-Tooltip:before, .tooltip-target-attached-top.tooltip-target-attached-center > .tooltip:before {
    top: 100%;
    left: 50%;
    margin-left: -6px;
    border-top-color: inherit
  }

  .tooltip-target-attached-top.tooltip-target-attached-center > .bdl-Tooltip.is-error:after, .tooltip-target-attached-top.tooltip-target-attached-center > .is-error.tooltip:after {
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-top-color: #fdebee
  }

  .tooltip-target-attached-top.tooltip-target-attached-left > .bdl-Tooltip, .tooltip-target-attached-top.tooltip-target-attached-left > .tooltip {
    margin-bottom: 10px
  }

  .tooltip-target-attached-top.tooltip-target-attached-left > .bdl-Tooltip:before, .tooltip-target-attached-top.tooltip-target-attached-left > .tooltip:before {
    top: 100%;
    left: 10px;
    border-top-color: inherit
  }

  .tooltip-target-attached-top.tooltip-target-attached-left > .bdl-Tooltip.is-error:after, .tooltip-target-attached-top.tooltip-target-attached-left > .is-error.tooltip:after {
    top: 100%;
    left: 11px;
    border-top-color: #fdebee
  }

  .tooltip-target-attached-top.tooltip-target-attached-right > .bdl-Tooltip, .tooltip-target-attached-top.tooltip-target-attached-right > .tooltip {
    margin-bottom: 10px
  }

  .tooltip-target-attached-top.tooltip-target-attached-right > .bdl-Tooltip:before, .tooltip-target-attached-top.tooltip-target-attached-right > .tooltip:before {
    top: 100%;
    right: 10px;
    border-top-color: inherit
  }

  .tooltip-target-attached-top.tooltip-target-attached-right > .bdl-Tooltip.is-error:after, .tooltip-target-attached-top.tooltip-target-attached-right > .is-error.tooltip:after {
    top: 100%;
    right: 11px;
    border-top-color: #fdebee
  }

  .tooltip-target-attached-middle.tooltip-target-attached-right > .bdl-Tooltip, .tooltip-target-attached-middle.tooltip-target-attached-right > .tooltip {
    margin-left: 10px
  }

  .tooltip-target-attached-middle.tooltip-target-attached-right > .bdl-Tooltip:before, .tooltip-target-attached-middle.tooltip-target-attached-right > .tooltip:before {
    top: 50%;
    right: 100%;
    margin-top: -6px;
    border-right-color: inherit
  }

  .tooltip-target-attached-middle.tooltip-target-attached-right > .bdl-Tooltip.is-error:after, .tooltip-target-attached-middle.tooltip-target-attached-right > .is-error.tooltip:after {
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-right-color: #fdebee
  }

  .tooltip-target-attached-middle.tooltip-target-attached-left > .bdl-Tooltip, .tooltip-target-attached-middle.tooltip-target-attached-left > .tooltip {
    margin-right: 10px
  }

  .tooltip-target-attached-middle.tooltip-target-attached-left > .bdl-Tooltip:before, .tooltip-target-attached-middle.tooltip-target-attached-left > .tooltip:before {
    top: 50%;
    left: 100%;
    margin-top: -6px;
    border-left-color: inherit
  }

  .tooltip-target-attached-middle.tooltip-target-attached-left > .bdl-Tooltip.is-error:after, .tooltip-target-attached-middle.tooltip-target-attached-left > .is-error.tooltip:after {
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-left-color: #fdebee
  }

  .bcp-footer .bcp-footer-actions .btn {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border: 1px solid #bcbcbc;

    &:hover {
      background-color: #f7f7f7;
      z-index: auto;
    }
  }

  .bcp-footer .bcp-footer-actions .btn-primary {
    margin-right: 0 !important;
    background-color: #0061d5;
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    border: none;

    &:hover {
      background-color: #0061d5 !important;
    }

    &:active {
      background-color: #004eac !important;
    }
  }

  .tooltip-target-attached-bottom.tooltip-target-attached-center > .bdl-Tooltip, .tooltip-target-attached-bottom.tooltip-target-attached-center > .tooltip {
    margin-top: 10px
  }

  .tooltip-target-attached-bottom.tooltip-target-attached-center > .bdl-Tooltip:before, .tooltip-target-attached-bottom.tooltip-target-attached-center > .tooltip:before {
    bottom: 100%;
    left: 50%;
    margin-left: -6px;
    border-bottom-color: inherit
  }

  .tooltip-target-attached-bottom.tooltip-target-attached-center > .bdl-Tooltip.is-error:after, .tooltip-target-attached-bottom.tooltip-target-attached-center > .is-error.tooltip:after {
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-bottom-color: #fdebee
  }

  .tooltip-target-attached-bottom.tooltip-target-attached-left > .bdl-Tooltip, .tooltip-target-attached-bottom.tooltip-target-attached-left > .tooltip {
    margin-top: 10px
  }

  .tooltip-target-attached-bottom.tooltip-target-attached-left > .bdl-Tooltip:before, .tooltip-target-attached-bottom.tooltip-target-attached-left > .tooltip:before {
    bottom: 100%;
    left: 10px;
    border-bottom-color: inherit
  }

  .tooltip-target-attached-bottom.tooltip-target-attached-left > .bdl-Tooltip.is-error:after, .tooltip-target-attached-bottom.tooltip-target-attached-left > .is-error.tooltip:after {
    bottom: 100%;
    left: 11px;
    border-bottom-color: #fdebee
  }

  .tooltip-target-attached-bottom.tooltip-target-attached-right > .bdl-Tooltip, .tooltip-target-attached-bottom.tooltip-target-attached-right > .tooltip {
    margin-top: 10px
  }

  .tooltip-target-attached-bottom.tooltip-target-attached-right > .bdl-Tooltip:before, .tooltip-target-attached-bottom.tooltip-target-attached-right > .tooltip:before {
    right: 10px;
    bottom: 100%;
    border-bottom-color: inherit
  }

  .tooltip-target-attached-bottom.tooltip-target-attached-right > .bdl-Tooltip.is-error:after, .tooltip-target-attached-bottom.tooltip-target-attached-right > .is-error.tooltip:after {
    right: 11px;
    bottom: 100%;
    border-bottom-color: #fdebee
  }

  .tooltip-element {
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-weight: 400;
    font-size: 13px;
    color: #222;
    line-height: 20px;
    letter-spacing: .3px;
    z-index: 190
  }

  .tooltip-element, .tooltip-element *, .tooltip-element :after, .tooltip-element :before {
    box-sizing: border-box !important
  }

  .btn.be-btn-sort {
    padding: 7px 6px
  }

  .bce:not(.be-is-small) .btn.be-btn-sort {
    display: none
  }

  .btn.be-btn-sort svg {
    display: block
  }

  .btn.be-btn-add {
    margin-left: 7px;
    padding: 5px 6px
  }

  .btn.be-btn-add svg {
    display: block
  }

  .bdl-GridViewSlider {
    display: flex;
    margin-right: 5px
  }

  .bdl-GridViewSlider-range {
    margin-right: 7px;
    margin-left: 7px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
  }

  .bdl-GridViewSlider-range::-webkit-slider-runnable-track {
    height: 2px;
    background: #e8e8e8;
    border: none;
    border-radius: 2px
  }

  .bdl-GridViewSlider-range::-webkit-slider-thumb {
    height: 10px;
    background: #4e4e4e;
    border: none;
    border-radius: 50%;
    width: 10px;
    margin-top: -4px;
    -webkit-appearance: none;
    appearance: none
  }

  .bdl-GridViewSlider-range::-moz-range-track {
    height: 2px;
    background: #e8e8e8;
    border: none;
    border-radius: 2px
  }

  .bdl-GridViewSlider-range::-moz-range-thumb {
    width: 10px;
    height: 10px;
    background: #4e4e4e;
    border: none;
    border-radius: 50%
  }

  .bdl-GridViewSlider-range::-ms-track {
    height: 2px;
    background: #e8e8e8;
    border: none;
    border-radius: 2px;
    color: transparent
  }

  .bdl-GridViewSlider-range::-ms-fill-lower, .bdl-GridViewSlider-range::-ms-fill-upper {
    background: #e8e8e8;
    border-radius: 10px
  }

  .bdl-GridViewSlider-range::-ms-thumb {
    width: 10px;
    height: 10px;
    background: #4e4e4e;
    border: none;
    border-radius: 50%
  }

  .bdl-GridViewSlider-range:focus::-ms-fill-lower {
    background: #909090
  }

  .bdl-GridViewSlider-range:focus::-ms-fill-upper {
    background: #d3d3d3
  }

  .bdl-GridViewSlider-button {
    display: flex;
    align-items: center;
    justify-content: center
  }

  .btn.bdl-ViewModeChangeButton {
    padding: 5px 6px
  }

  .btn.bdl-ViewModeChangeButton svg {
    display: block
  }

  .be-sub-header-right {
    display: flex;
    align-items: center
  }

  .be-sub-header-right .bdl-ViewModeChangeButton, .be-sub-header-right .be-btn-sort {
    margin-left: 7px
  }

  .be-sub-header {
    display: flex;
    flex: 0 0 50px;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 25px;
    border-bottom: 1px solid #e8e8e8;
    box-shadow: 0 4px 6px -2px rgba(51, 51, 51, .04)
  }

  .be-is-small .be-sub-header {
    padding: 0 15px 0 18px
  }

  .bce:not(.be-is-small) .be-sub-header {
    box-shadow: none
  }

  .bce.be-is-small .be-sub-header {
    border-bottom: 0
  }

  .badgeable-container .badges .bottom-left-badge, .badgeable-container .badges .bottom-right-badge, .badgeable-container .badges .top-left-badge, .badgeable-container .badges .top-right-badge {
    position: absolute;
    pointer-events: all
  }

  .badgeable-container {
    position: relative;
    display: inline-block;
    line-height: 1
  }

  .badgeable-container .badges {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none
  }

  .badgeable-container .badges .top-left-badge {
    top: -6px;
    left: 8px;
    transform: translateX(-100%)
  }

  .badgeable-container .badges .top-right-badge {
    top: -6px;
    left: calc(100% - 8px)
  }

  .badgeable-container .badges .bottom-left-badge {
    bottom: -6px;
    left: 8px;
    transform: translateX(-100%)
  }

  .badgeable-container .badges .bottom-right-badge {
    bottom: -6px;
    left: calc(100% - 8px)
  }

  .bcu-item-label {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
  }

  .bcu-item-icon-name {
    display: flex;
    width: 100%;
    height: 50px;
    cursor: default
  }

  .bcu-item-icon {
    flex: 0 0 50px;
    align-items: center
  }

  .bcu-item-icon, .bcu-item-name {
    display: flex;
    justify-content: center
  }

  .bcu-item-name {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    line-height: 15px;
    text-align: left
  }

  .bcu-icon-badge .badges .bottom-right-badge {
    bottom: -4px;
    left: calc(100% - 16px)
  }

  .bcu-progress-container {
    z-index: 201;
    width: 100%;
    height: 2px;
    margin-right: 40px;
    background: #e8e8e8;
    transition: opacity .4s
  }

  .be-is-small .bcu-progress-container {
    margin-right: 24px
  }

  .bcu-progress-container .bcu-progress {
    top: 0;
    left: 0;
    max-width: 100%;
    height: 2px;
    background: #0061d5;
    box-shadow: 0 1px 5px 0 #e4f4ff;
    transition: width .1s
  }

  .bcu-item-progress {
    display: flex;
    align-items: center
  }

  .bcu-progress-label {
    min-width: 35px
  }

  .bcu-item-action {
    width: 24px;
    height: 24px
  }

  .bcu-item-action .crawler {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%
  }

  .bcu-item-action button {
    display: flex
  }

  .bcu-item-action button svg {
    display: block
  }

  .bcu-item-action button .be-icon-in-progress {
    width: 24px;
    height: 24px
  }

  .bcu-item-action button .be-icon-in-progress svg {
    display: none
  }

  .bcu-item-action button:hover .be-icon-in-progress svg {
    display: block
  }

  .bcu-item-action button:hover .be-icon-in-progress .crawler {
    display: none
  }

  .ReactVirtualized__Table__headerRow {
    font-weight: 700;
    text-transform: uppercase
  }

  .ReactVirtualized__Table__headerRow, .ReactVirtualized__Table__row {
    display: flex;
    flex-direction: row;
    align-items: center
  }

  .ReactVirtualized__Table__headerTruncatedText {
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden
  }

  .ReactVirtualized__Table__headerColumn, .ReactVirtualized__Table__rowColumn {
    margin-right: 10px;
    min-width: 0
  }

  .ReactVirtualized__Table__rowColumn {
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .ReactVirtualized__Table__headerColumn:first-of-type, .ReactVirtualized__Table__rowColumn:first-of-type {
    margin-left: 10px
  }

  .ReactVirtualized__Table__sortableHeaderColumn {
    cursor: pointer
  }

  .ReactVirtualized__Table__sortableHeaderIconContainer {
    display: flex;
    align-items: center
  }

  .ReactVirtualized__Table__sortableHeaderIcon {
    flex: 0 0 24px;
    height: 1em;
    width: 1em;
    fill: currentColor
  }

  .bcu-item-row {
    border-bottom: 1px solid rgba(190, 212, 222, .4);
    outline: none
  }

  .bcu-item-list-action-column:last-child {
    margin-right: 18px
  }

  .bcu-drag-drop-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    opacity: .2
  }

  .bcu-upload-state {
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    text-align: center
  }

  .bcu-upload-state svg {
    margin-bottom: 10px
  }

  .bcu-upload-state.bcu-is-droppable .bcu-drag-drop-overlay {
    display: block;
    background: #0061d5
  }

  .bcu-upload-state.bcu-is-not-droppable .bcu-drag-drop-overlay {
    display: block;
    background: #c82341
  }

  .bcu-upload-state.bcu-has-items {
    display: none
  }

  .bcu-upload-state.bcu-has-items.bcu-is-droppable, .bcu-upload-state.bcu-has-items.bcu-is-not-droppable {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    background-color: hsla(0, 0%, 100%, .8)
  }

  .bcu-upload-state.bcu-has-items.bcu-is-droppable .bcu-drag-drop-overlay, .bcu-upload-state.bcu-has-items.bcu-is-not-droppable .bcu-drag-drop-overlay {
    display: none
  }

  .bcu-upload-state-message + .be-input-btn {
    margin-top: 10px
  }

  .bcu-upload-state-message + .be-input-link {
    margin-top: 5px
  }

  .bcu-upload-input-container .be-input-link {
    display: inline-block
  }

  .bcu-droppable-content {
    position: relative;
    display: flex;
    flex: 1 0 200px;
    flex-direction: column
  }

  .bcu-droppable-content .bcu-item-list {
    position: relative;
    flex: 1
  }

  .bcu-droppable-content .bcu-item-list .ReactVirtualized__Table__Grid {
    outline: none
  }

  .bcu-uploads-manager-action {
    margin: 5px -20px 5px 5px
  }

  .bcu-uploads-manager-action {
    display: flex
  }

  .bcu-overall-progress-bar {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding-right: 65px;
    padding-left: 30px;
    color: #fff;
    line-height: 60px;
    background: #0061d5;
    cursor: pointer
  }

  .bcu-overall-progress-bar:hover {
    background: #0063d9
  }

  .bcu-overall-progress-bar .bcu-progress {
    background: #fff
  }

  .bcu-overall-progress-bar .bcu-progress-container {
    background: transparent
  }

  .bcu-overall-progress-bar .bcu-upload-status {
    flex: none;
    margin-right: 20px;
    font-size: 16px
  }

  .be.bcu-uploads-manager-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-height: 0;
    transition: max-height .5s
  }

  .be.bcu-uploads-manager-container .bcu-progress-container {
    margin-right: 0
  }

  .be.bcu-uploads-manager-container .bcu-item-progress .bcu-progress-container {
    display: none
  }

  .be.bcu-uploads-manager-container .bcu-item-progress .bcu-progress-label {
    margin-left: auto
  }

  .be.bcu-uploads-manager-container.bcu-is-visible {
    max-height: 60px
  }

  .be.bcu-uploads-manager-container .bcu-uploads-manager-item-list {
    display: none;
    height: 375px
  }

  .be.bcu-uploads-manager-container .bcu-uploads-manager-item-list .buik-btn-plain:focus {
    border: 1px solid #bcbcbc
  }

  .be.bcu-uploads-manager-container .bcu-uploads-manager-toggle {
    position: absolute;
    top: 0;
    right: 26px;
    bottom: 0;
    width: 10px;
    height: 10px;
    margin: auto 0;
    vertical-align: middle;
    background: 0 0;
    border: 0
  }

  .be.bcu-uploads-manager-container .bcu-uploads-manager-toggle:after, .be.bcu-uploads-manager-container .bcu-uploads-manager-toggle:before {
    position: absolute;
    width: 6px;
    height: 6px;
    border: 0 solid #fff;
    content: ""
  }

  .be.bcu-uploads-manager-container .bcu-uploads-manager-toggle:before {
    bottom: 0;
    left: 0;
    border-bottom-width: 2px;
    border-left-width: 2px
  }

  .be.bcu-uploads-manager-container .bcu-uploads-manager-toggle:after {
    top: 0;
    right: 0;
    border-top-width: 2px;
    border-right-width: 2px
  }

  .be.bcu-uploads-manager-container.bcu-is-expanded {
    max-height: 435px
  }

  .be.bcu-uploads-manager-container.bcu-is-expanded .bcu-uploads-manager-item-list {
    display: block
  }

  .be.bcu-uploads-manager-container.bcu-is-expanded .bcu-uploads-manager-toggle:before {
    bottom: 7px;
    left: 7px
  }

  .be.bcu-uploads-manager-container.bcu-is-expanded .bcu-uploads-manager-toggle:after {
    top: 7px;
    right: 7px
  }

  .be.bcu-uploads-manager-container .bcu-overall-progress-bar:hover .bcu-uploads-manager-toggle:before {
    bottom: -3px;
    left: -3px
  }

  .be.bcu-uploads-manager-container .bcu-overall-progress-bar:hover .bcu-uploads-manager-toggle:after {
    top: -3px;
    right: -3px
  }

  .be.bcu-uploads-manager-container.bcu-is-expanded .bcu-overall-progress-bar:hover .bcu-uploads-manager-toggle:before {
    bottom: 6px;
    left: 6px
  }

  .be.bcu-uploads-manager-container.bcu-is-expanded .bcu-overall-progress-bar:hover .bcu-uploads-manager-toggle:after {
    top: 6px;
    right: 6px
  }

  .be.bcu-uploads-manager-container .bcu-item-row {
    padding-right: 5px
  }

  .be.bcu-uploads-manager-container .bcu-item-icon-name {
    padding-left: 5px
  }

  .be.bcu-uploads-manager-container .bcu-progress {
    box-shadow: none
  }

  .be.bcu-uploads-manager-container .bcu-item-list {
    position: relative
  }

  .bcu-footer {
    display: flex;
    flex: 0 0 70px;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background-color: #fcfcfc;
    border-top: 1px solid #e8e8e8
  }

  .bcu-footer .btn {
    padding: 0 16px;
    font-size: 14px;
    line-height: 38px
  }

  .bcu-footer .bcu-footer-message {
    padding: 0 10px;
    text-align: center
  }

  .bcu-footer .bcu-footer-right .btn {
    margin-left: 8px
  }

  .btn-plain {
    background-color: transparent;
    border: none;
  }

  @font-face {
    font-weight: 400;
    font-family: Lato;
    font-style: normal;
    src: local("Lato Regular"), local("Lato-Regular"), url(https://cdn01.boxcdn.net/fonts/1.0.17/lato/Lato-Regular.woff2) format("woff2"), url(https://cdn01.boxcdn.net/fonts/1.0.17/lato/Lato-Regular.woff) format("woff")
  }

  @font-face {
    font-weight: 700;
    font-family: Lato;
    font-style: normal;
    src: local("Lato Bold"), local("Lato-Bold"), url(https://cdn01.boxcdn.net/fonts/1.0.17/lato/Lato-Bold.woff2) format("woff2"), url(https://cdn01.boxcdn.net/fonts/1.0.17/lato/Lato-Bold.woff) format("woff")
  }

  button svg {
    pointer-events: none
  }

  .inline-alert {
    display: none;
    margin: 10px 0;
    padding: 14px 10px;
    color: #222;
    border-radius: 6px
  }

  .inline-alert.inline-alert-visible {
    display: block
  }

  .inline-alert.inline-alert-warning {
    background-color: #fef7e8;
    border: 1px solid #fad98d
  }

  .inline-alert.inline-alert-error {
    background-color: #fdebee;
    border: 1px solid #f69bab
  }

  .inline-alert.inline-alert-success {
    background-color: #e9f8f2;
    border: 1px solid #26c281
  }

  .inline-alert.inline-alert-info {
    background-color: #e9f2fe;
    border: 1px solid #91c2fd
  }

  .inline-alert.inline-alert-generic {
    background-color: #e8e8e8;
    border: 1px solid #909090
  }

  .accessibility-hidden {
    position: absolute;
    top: auto;
    left: -9999px
  }

  .overlay-wrapper {
    position: relative;
    display: inherit
  }

  .overlay-wrapper .overlay {
    position: absolute;
    z-index: 90;
    transform: rotateY(-10deg) rotateX(-10deg);
    transform-origin: left top;
    transform-style: preserve-3d;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 75ms, opacity 75ms, transform .125s;
    pointer-events: none
  }

  .overlay-wrapper.is-visible .overlay {
    transform: rotateY(0deg) rotateX(0deg);
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
    pointer-events: all
  }

  .overlay {
    margin: 0;
    padding: 8px 0;
    background: #fff;
    border: 1px solid #bcbcbc;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .1)
  }

  .overlay hr {
    height: 0;
    margin: 8px 0;
    border: 0;
    border-top: 1px solid #bcbcbc
  }

  .bdl-Pill-warning, .pill-warning {
    color: #f5b31b;
    background-color: #fef7e8;
    border: 1px solid #fad98d
  }

  .bdl-Pill-warning a, .pill-warning a {
    color: #f5b31b
  }

  .bdl-Pill--error, .pill-error {
    color: #ed3757;
    background-color: #fdebee;
    border: 1px solid #f69bab
  }

  .bdl-Pill--error a, .pill-error a {
    color: #ed3757
  }

  .bdl-Pill.bdl-PillCloud-button, .bdl-Pill.pill-cloud-button, .bdl-PillCloud-button.pill, .pill-cloud-button.pill {
    display: inline-block;
    margin: 3px;
    color: #0061d5;
    background-color: #fff;
    border: 1px solid #0061d5
  }

  .bdl-Pill.bdl-PillCloud-button.is-selected, .bdl-Pill.is-selected.pill-cloud-button, .bdl-PillCloud-button.is-selected.pill, .is-selected.pill-cloud-button.pill {
    box-shadow: none
  }

  .bdl-Pill.bdl-PillCloud-button:not(.bdl-is-disabled):focus, .bdl-Pill.bdl-PillCloud-button:not(.is-disabled):focus, .bdl-Pill.pill-cloud-button:not(.bdl-is-disabled):focus, .bdl-Pill.pill-cloud-button:not(.is-disabled):focus, .bdl-PillCloud-button.pill:not(.bdl-is-disabled):focus, .bdl-PillCloud-button.pill:not(.is-disabled):focus, .pill-cloud-button.pill:not(.bdl-is-disabled):focus, .pill-cloud-button.pill:not(.is-disabled):focus {
    border-color: #004eac;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1)
  }

  .bdl-PillCloud, .pill-cloud-container {
    padding: 8px;
    overflow-y: scroll;
    border: 1px solid #e8e8e8
  }

  .bdl-PillSelector-input--hidden, .pill-selector-hidden-input {
    position: absolute;
    visibility: hidden
  }

  .bdl-PillSelectorDropdown, .pill-selector-wrapper {
    position: relative;
    margin: 0 0 20px
  }

  .bdl-PillSelectorDropdown .bdl-PillSelector, .bdl-PillSelectorDropdown .pill-selector-input-wrapper, .pill-selector-wrapper .bdl-PillSelector, .pill-selector-wrapper .pill-selector-input-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    align-items: flex-start;
    width: 262px;
    margin-top: 5px;
    padding: 5px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 6px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .08);
    cursor: text;
    transition: border-color .15s linear, box-shadow .15s linear;
    -webkit-font-smoothing: antialiased
  }

  .bdl-PillSelectorDropdown .bdl-PillSelector:hover, .bdl-PillSelectorDropdown .pill-selector-input-wrapper:hover, .pill-selector-wrapper .bdl-PillSelector:hover, .pill-selector-wrapper .pill-selector-input-wrapper:hover {
    border: 1px solid #004aa2
  }

  .bdl-PillSelectorDropdown .bdl-is-disabled.pill-selector-input-wrapper, .bdl-PillSelectorDropdown .bdl-PillSelector.bdl-is-disabled, .bdl-PillSelectorDropdown .bdl-PillSelector.is-disabled, .bdl-PillSelectorDropdown .is-disabled.pill-selector-input-wrapper, .pill-selector-wrapper .bdl-is-disabled.pill-selector-input-wrapper, .pill-selector-wrapper .bdl-PillSelector.bdl-is-disabled, .pill-selector-wrapper .bdl-PillSelector.is-disabled, .pill-selector-wrapper .is-disabled.pill-selector-input-wrapper {
    color: #bcbcbc;
    background-color: #fbfbfb;
    box-shadow: none
  }

  .bdl-PillSelectorDropdown .bdl-is-disabled.pill-selector-input-wrapper:hover, .bdl-PillSelectorDropdown .bdl-PillSelector.bdl-is-disabled:hover, .bdl-PillSelectorDropdown .bdl-PillSelector.is-disabled:hover, .bdl-PillSelectorDropdown .is-disabled.pill-selector-input-wrapper:hover, .pill-selector-wrapper .bdl-is-disabled.pill-selector-input-wrapper:hover, .pill-selector-wrapper .bdl-PillSelector.bdl-is-disabled:hover, .pill-selector-wrapper .bdl-PillSelector.is-disabled:hover, .pill-selector-wrapper .is-disabled.pill-selector-input-wrapper:hover {
    border-color: #bcbcbc
  }

  .bdl-PillSelectorDropdown .bdl-PillSelector.is-focused, .bdl-PillSelectorDropdown .is-focused.pill-selector-input-wrapper, .pill-selector-wrapper .bdl-PillSelector.is-focused, .pill-selector-wrapper .is-focused.pill-selector-input-wrapper {
    border-color: #0061d5;
    outline: 0;
    box-shadow: none
  }

  .bdl-PillSelectorDropdown .bdl-PillSelector.is-focused .bdl-PillSelector-input--nextLine, .bdl-PillSelectorDropdown .is-focused.pill-selector-input-wrapper .bdl-PillSelector-input--nextLine, .pill-selector-wrapper .bdl-PillSelector.is-focused .bdl-PillSelector-input--nextLine, .pill-selector-wrapper .is-focused.pill-selector-input-wrapper .bdl-PillSelector-input--nextLine {
    flex: 1 1 100%
  }

  .bdl-PillSelectorDropdown .bdl-PillSelector.show-error, .bdl-PillSelectorDropdown .show-error.pill-selector-input-wrapper, .pill-selector-wrapper .bdl-PillSelector.show-error, .pill-selector-wrapper .show-error.pill-selector-input-wrapper {
    border-color: #ed3757
  }

  .bdl-PillSelectorDropdown .bdl-PillSelector--suggestionsEnabled.pill-selector-input-wrapper, .bdl-PillSelectorDropdown .bdl-PillSelector.bdl-PillSelector--suggestionsEnabled, .bdl-PillSelectorDropdown .bdl-PillSelector.pill-selector-suggestions-enabled, .bdl-PillSelectorDropdown .pill-selector-suggestions-enabled.pill-selector-input-wrapper, .pill-selector-wrapper .bdl-PillSelector--suggestionsEnabled.pill-selector-input-wrapper, .pill-selector-wrapper .bdl-PillSelector.bdl-PillSelector--suggestionsEnabled, .pill-selector-wrapper .bdl-PillSelector.pill-selector-suggestions-enabled, .pill-selector-wrapper .pill-selector-suggestions-enabled.pill-selector-input-wrapper {
    min-height: 80px
  }

  .bdl-PillSelectorDropdown .bdl-PillSelector:not(.show-error) .icon-alert, .bdl-PillSelectorDropdown .pill-selector-input-wrapper:not(.show-error) .icon-alert, .pill-selector-wrapper .bdl-PillSelector:not(.show-error) .icon-alert, .pill-selector-wrapper .pill-selector-input-wrapper:not(.show-error) .icon-alert {
    display: none
  }

  .bdl-PillSelectorDropdown .bdl-PillSelector .icon-alert, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .icon-alert, .pill-selector-wrapper .bdl-PillSelector .icon-alert, .pill-selector-wrapper .pill-selector-input-wrapper .icon-alert {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 16px;
    height: 16px
  }

  .bdl-PillSelectorDropdown .bdl-PillSelector .icon-alert path, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .icon-alert path, .pill-selector-wrapper .bdl-PillSelector .icon-alert path, .pill-selector-wrapper .pill-selector-input-wrapper .icon-alert path {
    fill: #ed3757
  }

  .bdl-PillSelectorDropdown .bdl-PillSelector .bdl-Tooltip, .bdl-PillSelectorDropdown .bdl-PillSelector .tooltip, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .bdl-Tooltip, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .tooltip, .pill-selector-wrapper .bdl-PillSelector .bdl-Tooltip, .pill-selector-wrapper .bdl-PillSelector .tooltip, .pill-selector-wrapper .pill-selector-input-wrapper .bdl-Tooltip, .pill-selector-wrapper .pill-selector-input-wrapper .tooltip {
    top: calc(100% - 5px) !important;
    right: 10px !important;
    margin-top: 0 !important
  }

  .bdl-PillSelectorDropdown .bdl-PillSelector .bdl-PillsList, .bdl-PillSelectorDropdown .bdl-PillSelector .pills-list, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .bdl-PillsList, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .pills-list, .pill-selector-wrapper .bdl-PillSelector .bdl-PillsList, .pill-selector-wrapper .bdl-PillSelector .pills-list, .pill-selector-wrapper .pill-selector-input-wrapper .bdl-PillsList, .pill-selector-wrapper .pill-selector-input-wrapper .pills-list {
    margin: 0
  }

  .bdl-PillSelectorDropdown .bdl-PillSelector .bdl-PillsList li, .bdl-PillSelectorDropdown .bdl-PillSelector .pills-list li, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .bdl-PillsList li, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .pills-list li, .pill-selector-wrapper .bdl-PillSelector .bdl-PillsList li, .pill-selector-wrapper .bdl-PillSelector .pills-list li, .pill-selector-wrapper .pill-selector-input-wrapper .bdl-PillsList li, .pill-selector-wrapper .pill-selector-input-wrapper .pills-list li {
    float: left
  }

  .bdl-PillSelectorDropdown .bdl-PillSelector .bdl-PillSelector-input, .bdl-PillSelectorDropdown .bdl-PillSelector .pill-selector-input, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .bdl-PillSelector-input, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .pill-selector-input, .pill-selector-wrapper .bdl-PillSelector .bdl-PillSelector-input, .pill-selector-wrapper .bdl-PillSelector .pill-selector-input, .pill-selector-wrapper .pill-selector-input-wrapper .bdl-PillSelector-input, .pill-selector-wrapper .pill-selector-input-wrapper .pill-selector-input {
    flex: 1;
    box-sizing: initial !important;
    min-width: 0;
    height: 18px;
    margin: 1px;
    padding: 3px;
    overflow: hidden;
    color: #4f4f4f;
    line-height: 18px;
    white-space: nowrap;
    background-color: initial;
    border: 0;
    box-shadow: none;
    resize: none
  }

  .bdl-PillSelectorDropdown .bdl-PillSelector .bdl-PillSelector-input:focus, .bdl-PillSelectorDropdown .bdl-PillSelector .bdl-PillSelector-input:hover, .bdl-PillSelectorDropdown .bdl-PillSelector .pill-selector-input:focus, .bdl-PillSelectorDropdown .bdl-PillSelector .pill-selector-input:hover, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .bdl-PillSelector-input:focus, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .bdl-PillSelector-input:hover, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .pill-selector-input:focus, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .pill-selector-input:hover, .pill-selector-wrapper .bdl-PillSelector .bdl-PillSelector-input:focus, .pill-selector-wrapper .bdl-PillSelector .bdl-PillSelector-input:hover, .pill-selector-wrapper .bdl-PillSelector .pill-selector-input:focus, .pill-selector-wrapper .bdl-PillSelector .pill-selector-input:hover, .pill-selector-wrapper .pill-selector-input-wrapper .bdl-PillSelector-input:focus, .pill-selector-wrapper .pill-selector-input-wrapper .bdl-PillSelector-input:hover, .pill-selector-wrapper .pill-selector-input-wrapper .pill-selector-input:focus, .pill-selector-wrapper .pill-selector-input-wrapper .pill-selector-input:hover {
    outline: none
  }

  .bdl-PillSelectorDropdown .bdl-PillSelector .bdl-RoundPill ~ .bdl-PillSelector-input, .bdl-PillSelectorDropdown .bdl-PillSelector .bdl-RoundPill ~ .pill-selector-input, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .bdl-RoundPill ~ .bdl-PillSelector-input, .bdl-PillSelectorDropdown .pill-selector-input-wrapper .bdl-RoundPill ~ .pill-selector-input, .pill-selector-wrapper .bdl-PillSelector .bdl-RoundPill ~ .bdl-PillSelector-input, .pill-selector-wrapper .bdl-PillSelector .bdl-RoundPill ~ .pill-selector-input, .pill-selector-wrapper .pill-selector-input-wrapper .bdl-RoundPill ~ .bdl-PillSelector-input, .pill-selector-wrapper .pill-selector-input-wrapper .bdl-RoundPill ~ .pill-selector-input {
    height: 28px;
    line-height: 28px
  }

  .bdl-PillSelectorDropdown .SelectorDropdown-overlay > ul.overlay, .pill-selector-wrapper .SelectorDropdown-overlay > ul.overlay {
    position: relative
  }

  .notifications-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 200;
    height: 0
  }

  .notification {
    display: flex;
    align-items: center;
    max-width: 572px;
    min-height: 48px;
    margin: 10px auto;
    padding: 10px 10px 10px 20px;
    overflow: hidden;
    color: #222;
    font-weight: 700;
    background-color: #e8e8e8;
    border: 2px solid #222;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .15);
    opacity: 1;
    transition: opacity .1s ease-out
  }

  .notification.info {
    background-color: #d4f3e6;
    border-color: #26c281
  }

  .notification.warn {
    background-color: #fdf0d1;
    border-color: #f5b31b
  }

  .notification.error {
    background-color: #fbd7dd;
    border-color: #ed3757
  }

  .notification.is-hidden {
    box-shadow: none;
    opacity: 0
  }

  .notification > span {
    margin-right: auto;
    padding-right: 10px;
    font-size: 15px
  }

  .notification > svg {
    flex: none;
    margin-right: 15px
  }

  .notification.wrap > span {
    overflow: hidden;
    word-wrap: break-word;
    overflow-wrap: break-word
  }

  .notification.ellipsis > span {
    overflow: hidden;
    text-overflow: ellipsis
  }

  .notification a, .notification button {
    flex: none;
    color: #222;
    font-weight: 700
  }

  .notification a.close-btn, .notification button.close-btn {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    outline: none;
    cursor: pointer
  }

  .notification a.close-btn path, .notification button.close-btn path {
    fill: #222
  }

  .be {
    font-size: 13px;
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #222;
    line-height: 20px;
    letter-spacing: .3px;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background: #fff;
    border: 0;
    outline: none
  }

  .be, .be * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    box-sizing: border-box !important
  }

  .be * {
    font-family: inherit
  }

  .be :after, .be :before {
    box-sizing: inherit;
    color: inherit;
    font-family: inherit;
    text-rendering: inherit
  }

  .be .lnk, .be a {
    color: #0061d5;
    text-decoration: none
  }

  .be .lnk {
    display: inline;
    margin: 0;
    padding: 0;
    background: transparent none;
    border: none;
    cursor: pointer;
    filter: none
  }

  .be .lnk, .be .lnk:active, .be .lnk:hover {
    height: auto;
    line-height: inherit;
    box-shadow: none
  }

  .be .lnk:active, .be .lnk:hover {
    background: transparent
  }

  .be .lnk:active {
    color: #0061d5
  }

  .be .lnk.bdl-is-disabled:focus, .be .lnk.is-disabled:focus, .be .lnk:active, .be .lnk:focus, .be a.bdl-is-disabled:focus, .be a.is-disabled:focus, .be a:focus {
    text-decoration: underline
  }

  .be .lnk.bdl-is-disabled, .be .lnk.bdl-is-disabled:active, .be .lnk.bdl-is-disabled:hover, .be .lnk.is-disabled, .be .lnk.is-disabled:active, .be .lnk.is-disabled:hover, .be a.bdl-is-disabled, .be a.bdl-is-disabled:active, .be a.bdl-is-disabled:hover, .be a.is-disabled, .be a.is-disabled:active, .be a.is-disabled:hover {
    color: #909090;
    text-decoration: none;
    cursor: default
  }

  .be .lnk-plain {
    color: inherit
  }

  .be .lnk-plain:active, .be .lnk-plain:hover {
    text-decoration: none
  }

  .be .lnk-plain.bdl-is-disabled, .be .lnk-plain.bdl-is-disabled:active, .be .lnk-plain.bdl-is-disabled:hover, .be .lnk-plain.is-disabled, .be .lnk-plain.is-disabled:active, .be .lnk-plain.is-disabled:hover {
    color: #909090
  }

  .be a svg {
    pointer-events: none
  }

  .be .link {
    overflow: hidden;
    color: #6f6f6f;
    line-height: 1.5em;
    white-space: nowrap;
    text-decoration: none;
    text-overflow: ellipsis;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
    transition: color .05s;
    font-smoothing: antialiased
  }

  .be .link:hover {
    color: #0061d5
  }

  .be .link-group-title {
    color: #a7a7a7;
    font-size: 10px;
    line-height: 28px;
    letter-spacing: .1em;
    text-transform: uppercase
  }

  .be div[contentEditable=true], .be input[type=date], .be input[type=email], .be input[type=number], .be input[type=password], .be input[type=search], .be input[type=tel], .be input[type=text], .be input[type=url], .be textarea {
    width: 262px;
    padding: 7px;
    color: #222;
    border: 1px solid #d3d3d3;
    border-radius: 6px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, .1);
    transition: border-color .15s linear, box-shadow .1s linear;
    -webkit-font-smoothing: antialiased
  }

  .be div[contentEditable=true]:hover, .be input[type=date]:hover, .be input[type=email]:hover, .be input[type=number]:hover, .be input[type=password]:hover, .be input[type=search]:hover, .be input[type=tel]:hover, .be input[type=text]:hover, .be input[type=url]:hover, .be textarea:hover {
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, .1)
  }

  .be div[contentEditable=true]:focus, .be input[type=date]:focus, .be input[type=email]:focus, .be input[type=number]:focus, .be input[type=password]:focus, .be input[type=search]:focus, .be input[type=tel]:focus, .be input[type=text]:focus, .be input[type=url]:focus, .be textarea:focus {
    border: 1px solid #0061d5;
    outline: 0
  }

  .be input:-ms-input-placeholder, .be input::-moz-placeholder, .be input::-webkit-input-placeholder {
    color: #d3d3d3
  }

  .be div[contentEditable=true].is-invalid, .be input[type=date].is-invalid, .be input[type=email].is-invalid, .be input[type=number].is-invalid, .be input[type=password].is-invalid, .be input[type=search].is-invalid, .be input[type=tel].is-invalid, .be input[type=text].is-invalid, .be input[type=url].is-invalid, .be textarea.is-invalid {
    border: 1px solid #ed3757
  }

  .be div[contentEditable=true].is-invalid:focus, .be input[type=date].is-invalid:focus, .be input[type=email].is-invalid:focus, .be input[type=number].is-invalid:focus, .be input[type=password].is-invalid:focus, .be input[type=search].is-invalid:focus, .be input[type=tel].is-invalid:focus, .be input[type=text].is-invalid:focus, .be input[type=url].is-invalid:focus, .be textarea.is-invalid:focus {
    border: 1px solid #f44
  }

  .be div[contentEditable=true]:disabled, .be input[type=date]:disabled, .be input[type=email]:disabled, .be input[type=number]:disabled, .be input[type=password]:disabled, .be input[type=search]:disabled, .be input[type=tel]:disabled, .be input[type=text]:disabled, .be input[type=url]:disabled, .be textarea:disabled {
    color: #bcbcbc;
    background-color: #fbfbfb;
    border-color: #e8e8e8;
    box-shadow: none
  }

  .be div[contentEditable=true]:disabled:hover, .be input[type=date]:disabled:hover, .be input[type=email]:disabled:hover, .be input[type=number]:disabled:hover, .be input[type=password]:disabled:hover, .be input[type=search]:disabled:hover, .be input[type=tel]:disabled:hover, .be input[type=text]:disabled:hover, .be input[type=url]:disabled:hover, .be textarea:disabled:hover {
    border-color: #e8e8e8
  }

  .be input[type=search] {
    -webkit-appearance: textfield
  }

  .be input[type=search]::-webkit-search-cancel-button, .be input[type=search]::-webkit-search-results-button {
    display: none
  }

  .be input::-ms-clear {
    display: none
  }

  .be textarea {
    vertical-align: top
  }

  .be .input-x-small, .be .input-x-small[type] {
    width: 100px
  }

  .be .input-small, .be .input-small[type] {
    width: 150px
  }

  .be .input-medium, .be .input-medium[type] {
    width: 300px
  }

  .be .input-large, .be .input-large[type] {
    width: 375px
  }

  .be .input-x-large, .be .input-x-large[type] {
    width: 475px
  }

  .be .input-full, .be .input-full[type] {
    width: 100%
  }

  .be .label-sep:after {
    content: ":"
  }

  .be .select-container .bdl-SelectButton, .be .select-container .select-button, .be .select-container select {
    position: relative;
    z-index: 1;
    display: inline-block;
    width: 100%;
    height: 34px;
    padding: 5px 0 5px 10px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
  }

  .be .select-container select {
    padding-right: 25px;
    color: #222;
    background: none;
    border: none
  }

  .be .select-container .bdl-SelectButton:disabled, .be .select-container .select-button:disabled, .be .select-container select:disabled {
    cursor: default
  }

  .be .select-container {
    position: relative;
    display: inline-block
  }

  .be .select-container .bdl-SelectButton, .be .select-container .select-button, .be .select-container .select-container-inner {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
    vertical-align: top
  }

  .be .select-container .select-overlay {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0
  }

  .be .select-container .bdl-SelectButton, .be .select-container .select-button, .be .select-container .select-overlay {
    background-color: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 6px;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .05);
    transition: border-color .15s linear, box-shadow .1s linear
  }

  .be .select-container .bdl-SelectButton:before, .be .select-container .select-button:before, .be .select-container .select-overlay:before {
    position: absolute;
    top: 15px;
    right: 11px;
    width: 0;
    height: 0;
    border-top: 3px solid #4e4e4e;
    border-right: 3px solid transparent;
    border-left: 3px solid transparent;
    transition: transform .15s;
    content: ""
  }

  .be .select-container .bdl-SelectButton[aria-expanded=true]:before, .be .select-container .select-button[aria-expanded=true]:before {
    transform: rotate(180deg)
  }

  .be .select-container .bdl-SelectButton, .be .select-container .select-button {
    padding-right: 25px;
    color: #222
  }

  .be .select-container .bdl-SelectButton:focus, .be .select-container .select-button:focus, .be .select-container select:focus {
    outline: none
  }

  .be .select-container .bdl-SelectButton:focus, .be .select-container .select-button:focus, .be .select-container select:focus ~ .select-overlay {
    border: 1px solid #0061d5
  }

  .be .select-container .bdl-SelectButton:hover, .be .select-container .select-button:hover, .be .select-container select:hover ~ .select-overlay {
    border: 1px solid #4c4c4c;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .1)
  }

  .be .select-container .bdl-SelectButton:disabled, .be .select-container .select-button:disabled, .be .select-container select:disabled {
    color: #bcbcbc
  }

  .be .select-container .bdl-SelectButton:disabled, .be .select-container .select-button:disabled, .be .select-container select:disabled ~ .select-overlay {
    background-color: #fbfbfb;
    border-color: #e8e8e8;
    box-shadow: none
  }

  .be .select-container .bdl-SelectButton:disabled:hover, .be .select-container .select-button:disabled:hover, .be .select-container select:disabled:hover ~ .select-overlay {
    box-shadow: none
  }

  .be .select-container .bdl-SelectButton:disabled:before, .be .select-container .select-button:disabled:before, .be .select-container select:disabled ~ .select-overlay:before {
    border-top: 3px solid #d5d5d5
  }

  .be .select-container.is-invalid .bdl-SelectButton:focus ~ .select-overlay, .be .select-container.is-invalid .select-button:focus ~ .select-overlay, .be .select-container.is-invalid select + .select-overlay, .be .select-container.is-invalid select:focus ~ .select-overlay, .be .select-container.is-invalid select:hover + .select-overlay {
    border: 1px solid #ed3757
  }

  .be .select-container .bdl-SelectButton:active, .be .select-container .select-button:active {
    background-color: #e8e8e8
  }

  .be .select-container-x-small {
    width: 50px
  }

  .be .select-container-small {
    width: 100px
  }

  .be .select-container-medium {
    width: 200px
  }

  .be .select-container-large {
    width: 262px
  }

  .be .select-container-x-large {
    width: 345px
  }

  .be .select-container.huge {
    width: 500px
  }

  .be select::-ms-expand {
    display: none
  }

  .be div[contentEditable=true], .be input[type=date], .be input[type=email], .be input[type=number], .be input[type=password], .be input[type=search], .be input[type=tel], .be input[type=text], .be input[type=url], .be textarea {
    border-color: #909090
  }

  .be ::-webkit-input-placeholder {
    color: #6f6f6f;
    font-weight: 400;
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    opacity: 1
  }

  .be ::-moz-placeholder {
    color: #6f6f6f;
    font-weight: 400;
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    opacity: 1
  }

  .be :-ms-input-placeholder {
    color: #6f6f6f;
    font-weight: 400;
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    opacity: 1
  }

  .be ::-webkit-file-upload-button {
    cursor: pointer
  }

  .be ul {
    margin: 0;
    padding: 0
  }

  .be ul li {
    list-style: none
  }

  .be .bdl-SelectButton, .be .btn-plain, .be .select-button, .be input, .be select, .be textarea {
    font: inherit
  }

  .ReactModal__Body--open .be {
    position: relative
  }

  .be-modal {
    font-size: 13px;
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #222;
    line-height: 20px;
    letter-spacing: .3px
  }

  .be-modal, .be-modal * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    box-sizing: border-box !important
  }

  .be-modal * {
    font-family: inherit
  }

  .be-modal :after, .be-modal :before {
    box-sizing: inherit;
    color: inherit;
    font-family: inherit;
    text-rendering: inherit
  }

  .be-modal div[contentEditable=true], .be-modal input[type=date], .be-modal input[type=email], .be-modal input[type=number], .be-modal input[type=password], .be-modal input[type=search], .be-modal input[type=tel], .be-modal input[type=text], .be-modal input[type=url], .be-modal textarea {
    border-color: #909090
  }

  .be-modal ::-webkit-input-placeholder {
    color: #6f6f6f;
    font-weight: 400;
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    opacity: 1
  }

  .be-modal ::-moz-placeholder {
    color: #6f6f6f;
    font-weight: 400;
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    opacity: 1
  }

  .be-modal :-ms-input-placeholder {
    color: #6f6f6f;
    font-weight: 400;
    font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
    opacity: 1
  }

  .be-modal ::-webkit-file-upload-button {
    cursor: pointer
  }

  .be-modal ul {
    margin: 0;
    padding: 0
  }

  .be-modal ul li {
    list-style: none
  }

  .be-modal .bdl-SelectButton, .be-modal .btn-plain, .be-modal .select-button, .be-modal input, .be-modal select, .be-modal textarea {
    font: inherit
  }

  .be-app-element {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%
  }

  .be-input-link {
    display: block;
    color: #0061d5;
    cursor: pointer
  }

  .be-input-link:active, .be-input-link:focus, .be-input-link:hover {
    text-decoration: underline
  }

  .be-input-link input {
    display: none
  }

  .be-input-btn {
    display: block;
    cursor: pointer
  }

  .be-hidden, .be-input-btn input {
    display: none
  }

  .menu-toggle {
    display: flex;
    align-items: center
  }

  .menu-toggle > .toggle-arrow {
    transition-timing-function: cubic-bezier(0, 0, .6, 1);
    transition-duration: .15s;
    transition-property: transform;
    flex: none;
    margin-left: 5px
  }

  .dropdown-menu-enabled .menu-toggle > .toggle-arrow, .is-toggled .menu-toggle > .toggle-arrow {
    transform: rotate(180deg)
  }

  .lnk .menu-toggle > .toggle-arrow {
    margin-top: 2px
  }

  .lnk .menu-toggle > .toggle-arrow path {
    fill: #0061d5
  }


  .lnk.bdl-is-disabled .menu-toggle .fill-color, .lnk.is-disabled .menu-toggle .fill-color {
    fill: #909090
  }

  .btn.bdl-CloseButton {
    width: 32px;
    height: 32px;
    padding: 0;
    background-color: #e8e8e8;
    border: none;
    border-radius: 6px
  }

  .bdl-MenuHeader {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 100%
  }

  .bdl-MenuHeader-content {
    display: flex;
    flex: 1;
    padding-right: 5px
  }

  .bdl-MenuHeader-title-container {
    display: flex;
    flex-direction: column;
    min-width: 0
  }

  .bdl-MenuHeader-title {
    display: -webkit-box;
    overflow: hidden;
    line-height: 1.5;
    white-space: normal;
    word-break: normal;
    overflow-wrap: anywhere;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin: 0;
    color: #222;
    font-weight: 700;
    font-size: 16px
  }

  @media (-ms-high-contrast: active),(-ms-high-contrast: none) {
    .bdl-MenuHeader-title {
      white-space: nowrap;
      text-overflow: ellipsis
    }
  }

  .bdl-MenuHeader-subtitle {
    margin: 0;
    color: #6f6f6f;
    font-size: 12px
  }

  .submenu-target {
    position: relative
  }

  .submenu-target .submenu {
    position: absolute;
    top: -12px;
    right: auto;
    left: calc(100% - -12px);
    z-index: 171
  }

  .submenu-target .submenu.is-left-aligned {
    right: 100%;
    left: auto
  }

  .submenu-target .submenu.is-hidden {
    right: auto;
    left: -9999px
  }

  .submenu-target .submenu.is-bottom-aligned {
    top: auto;
    bottom: -12px
  }

  .submenu-target .menu-item-arrow {
    position: absolute;
    top: 12px;
    right: 8px
  }

  .bdl-Pagination-dropdownMenu {
    max-height: 300px;
    margin-bottom: 5px;
    overflow: auto
  }

  .bdl-Pagination {
    display: flex
  }

  .bdl-Pagination .bdl-Pagination-count {
    display: flex;
    align-items: center
  }

  .bdl-Pagination .bdl-Pagination-toggle {
    margin-right: 5px;
    margin-left: 5px
  }

  .be-empty {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center
  }

  .be-progress-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 190;
    width: 100%;
    transition: opacity .4s
  }

  .be-progress-container .be-progress {
    max-width: 100%;
    height: 2px;
    background: #0061d5;
    box-shadow: 0 1px 5px 0 #e4f4ff;
    transition: width .4s
  }

  .be-item-label {
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
    outline: none
  }

  .be-date-capitalize {
    text-transform: capitalize
  }

  .bdl-GridView .bdl-ItemSubDetails-modifiedBy, .bdl-GridView .bdl-ItemSubDetails-size {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
  }

  .bce-item-row .bdl-ItemSubDetails-size:before, .bcp-item-row .bdl-ItemSubDetails-size:before {
    padding-right: 3px;
    padding-left: 3px;
    font-size: 8px;
    vertical-align: middle;
    content: "â€¢"
  }

  .be-item-name .be-item-details {
    color: #6f6f6f;
    font-size: 11px
  }

  .be-item-name {
    overflow: hidden;
    line-height: 15px;
    white-space: normal;
    text-align: left;
    text-overflow: ellipsis
  }

  .be-item-icon {
    display: flex;
    flex: 0 0 50px;
    align-items: center;
    justify-content: center
  }

  .be-is-small .be-item-icon {
    flex: 0 0 30px
  }

  .be select.be-share-access-select {
    padding: 5px 25px 5px 10px;
    color: inherit;
    background: none;
    background-image: linear-gradient(45deg, transparent 50%, #6f6f6f 0), linear-gradient(135deg, #6f6f6f 50%, transparent 0);
    background-repeat: no-repeat;
    background-position: right 14px center, right 10px center;
    background-size: 4px 4px, 4px 4px;
    border: 1px solid #bdc3c7;
    border-radius: 2px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
  }

  .be select.be-share-access-select:hover {
    border-color: #0061d5
  }

  .be select.be-share-access-select:active, .be select.be-share-access-select:focus {
    color: #0061d5;
    background-image: linear-gradient(45deg, transparent 50%, #0061d5 0), linear-gradient(135deg, #0061d5 50%, transparent 0);
    border-color: #0061d5;
    outline: none
  }

  .be select.be-share-access-select:disabled {
    color: #e8e8e8;
    background-image: linear-gradient(45deg, transparent 50%, #e8e8e8 0), linear-gradient(135deg, #e8e8e8 50%, transparent 0);
    border-color: #e8e8e8
  }

  .be select.be-share-access-select:-moz-focusring, .be select.be-share-access-select::-moz-focus-inner {
    color: transparent !important;
    text-shadow: 0 0 0 #222 !important
  }

  .be select.be-share-access-select::-ms-expand {
    display: none !important
  }

  .checkbox-label {
    position: relative;
    display: inline-flex;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
  }

  .checkbox-label > input[type=checkbox] {
    flex: none;
    width: 14px;
    height: 14px;
    margin: 3px 0 0;
    opacity: 0
  }

  .checkbox-label > input[type=checkbox] + span:before {
    position: absolute;
    top: 3px;
    left: 0;
    width: 14px;
    height: 14px;
    background: #fff;
    border: 1px solid #909090;
    border-radius: 2px;
    content: "";
    pointer-events: none
  }

  .checkbox-label > input[type=checkbox] + span:after {
    transition-timing-function: cubic-bezier(0, 0, .6, 1);
    transition-duration: .15s;
    transition-property: transform;
    position: absolute;
    top: 6px;
    left: 5px;
    width: 4px;
    height: 7px;
    border-right: 2px solid #0061d5;
    border-bottom: 2px solid #0061d5;
    transform: rotate(45deg) scale(0) translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    content: "";
    pointer-events: none
  }

  .checkbox-label > input[type=checkbox]:checked + span:after {
    transform: rotate(45deg) scale(1) translateZ(0)
  }

  .checkbox-label > input[type=checkbox]:focus + span:before {
    border: 2px solid #0061d5
  }

  .checkbox-label > input[type=checkbox]:disabled + span:before {
    background-color: #fbfbfb;
    border: 1px solid #d3d3d3
  }

  .checkbox-label > input[type=checkbox]:disabled + span:after {
    border-color: #bcbcbc
  }

  .bdl-Checkbox-labelTooltipWrapper {
    margin-left: 10px
  }

  .checkbox-tooltip-wrapper {
    display: inline-flex;
    vertical-align: text-bottom
  }

  .checkbox-tooltip-wrapper > .info-tooltip {
    position: relative;
    display: inline-block;
    flex: none;
    width: 16px;
    height: 16px;
    margin-left: 5px
  }

  .checkbox-tooltip-wrapper > .info-tooltip > svg path {
    fill: #0061d5
  }

  .checkbox-container {
    margin: 0 0 20px
  }

  .checkbox-container.bdl-is-disabled .checkbox-label, .checkbox-container.is-disabled .checkbox-label {
    color: #6f6f6f
  }

  .checkbox-container > .bdl-Label, .checkbox-container > .label {
    margin: 8px 0
  }

  .checkbox-description, .checkbox-subsection {
    margin-top: 2px;
    margin-left: 24px
  }

  .checkbox-description {
    color: #6f6f6f
  }

  .radio-label {
    position: relative;
    display: inline-flex;
    align-items: center;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
  }

  .radio-label > input[type=radio] {
    flex: none;
    width: 14px;
    height: 14px;
    margin: 0;
    opacity: 0
  }

  .radio-label > input[type=radio] + span:before {
    position: absolute;
    top: 3px;
    left: 0;
    width: 14px;
    height: 14px;
    background-color: #fff;
    border: 7px solid #fff;
    border-radius: 9001px;
    box-shadow: 0 0 0 1px #909090, 0 -1px 1px rgba(0, 0, 0, .4);
    transition: border-width .1s;
    content: ""
  }

  .radio-label > input[type=radio]:checked + span:before {
    background-color: #0061d5;
    border: 3px solid #fff
  }

  .radio-label > input[type=radio]:focus + span:before {
    box-shadow: 0 0 0 2px #0061d5
  }

  .radio-label > input[type=radio]:disabled + span:before {
    background-color: #bcbcbc;
    border-color: #fbfbfb;
    box-shadow: 0 0 0 1px #d3d3d3
  }

  .radio-label > input[type=radio] + span + span {
    margin-left: 10px
  }

  .radio-label > input[type=radio]:disabled + span + span {
    color: #bcbcbc
  }

  .radio-container {
    margin: 0 0 20px
  }

  .radio-description {
    margin-top: 2px;
    margin-left: 24px;
    color: #6f6f6f
  }

  .bcp-item-grid {
    flex: 1
  }

  .bcp-item-grid .ReactVirtualized__Table__Grid {
    outline: none
  }

  .bcp-item-row {
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid transparent;
    border-left: 2px solid transparent;
    outline: none
  }

  .bcp-item-row.bcp-item-row-selected {
    background-color: #e4f4ff;
    border-top-color: #bed4de
  }

  .bcp-item-row.bcp-item-row-selected .be-item-label, .be .bcp-item-row.bcp-item-row-selected .btn-plain {
    color: #1a5976;
    outline: none
  }

  .bcp-item-row:first-child {
    border-top-color: transparent !important
  }

  .bcp-item-row:last-child {
    border-bottom: 1px solid #e8e8e8
  }

  .bcp-item-row:last-child.bcp-item-row-selected {
    border-bottom-color: #bed4de
  }

  .bcp-item-row.bcp-item-row-unselectable .ReactVirtualized__Table__rowColumn {
    opacity: .3
  }

  .bcp-item-row select.bcp-shared-access-select {
    background-image: none;
    border-color: transparent
  }

  .bcp-item-row select.bcp-shared-access-select:active, .bcp-item-row select.bcp-shared-access-select:focus {
    color: #0061d5;
    background-color: #fff;
    background-image: linear-gradient(45deg, transparent 50%, #0061d5 0), linear-gradient(135deg, #0061d5 50%, transparent 0);
    border-color: #0061d5;
    outline: none
  }

  .bcp-item-row .checkbox-container, .bcp-item-row .radio-container {
    margin: 0 0 0 1px
  }

  .bcp-item-row .checkbox-container input, .bcp-item-row .checkbox-container span, .bcp-item-row .radio-container input, .bcp-item-row .radio-container span {
    cursor: pointer
  }

  .bcp-item-row:active, .bcp-item-row:focus, .bcp-item-row:hover {
    background-color: rgba(228, 244, 255, .4);
    border-left-color: #1a5976
  }

  .bcp-item-row:active select.bcp-shared-access-select, .bcp-item-row:focus select.bcp-shared-access-select, .bcp-item-row:hover select.bcp-shared-access-select {
    background-color: #fff;
    background-image: linear-gradient(45deg, transparent 50%, #6f6f6f 0), linear-gradient(135deg, #6f6f6f 50%, transparent 0);
    border-color: #d3d3d3
  }

  .bcp-item-row:active .be-item-label, .bcp-item-row:focus .be-item-label, .bcp-item-row:hover .be-item-label, .be .bcp-item-row:active .btn-plain, .be .bcp-item-row:focus .btn-plain, .be .bcp-item-row:hover .btn-plain {
    color: #1a5976
  }

  .bcp-item-row.bcp-item-row-selected + .bcp-item-row {
    border-top-color: #bed4de
  }

  .bcp-content {
    position: relative;
    display: flex;
    flex: 1 0 160px;
    flex-direction: column
  }

  .bcp-share-access-loading {
    display: flex;
    align-items: center;
    height: 30px
  }

  .bcp-footer {
    display: flex;
    flex: 0 1 70px;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background-color: #fcfcfc;
    border-top: 1px solid #e8e8e8
  }

  .bcp-footer .bcp-footer-left {
    flex: 0 1 100%;
    min-width: 0
  }

  .bcp-footer .bcp-selected {
    max-width: 100%;
    margin: 0
  }

  .bcp-footer .bcp-selected .btn-content {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    line-height: 13px;
    white-space: nowrap;
    text-overflow: ellipsis
  }

  .bcp-footer .bcp-selected-max {
    display: inline-block;
    margin-left: 5px;
    color: #c82341;
    font-size: 11px;
    animation: be_pulse .5s linear 2
  }

  .bcp-footer .bcp-footer-actions .btn {
    width: 40px;
    height: 32px;
    padding: 0
  }

  .bcp-footer .bcp-footer-actions .btn:first-child {
    border-right: 0
  }

  .bcp-footer .bcp-footer-right {
    display: flex;
    flex: 1 0 auto
  }

  .bcp-footer .bdl-ButtonGroup, .bcp-footer .btn-group {
    margin-right: 5px;
    margin-left: 5px
  }

  .be-is-small .bcp-footer .bdl-Pagination-count {
    display: none
  }

  @keyframes be_pulse {
    0%, to {
      transform: scale(1)
    }
    50% {
      transform: scale(1.2)
    }
  }

  .be-modal .be-modal-btns {
    display: flex;
    justify-content: center;
    padding: 15px 0 0
  }
}