@import 'scss/mixins.scss';
@import 'scss/variables.scss';

.contactCardClass {
  border-radius: 4px;
  // overflow: hidden;
  padding: 0 !important;
  opacity: 1 !important;
  width: 275px;
  background-color: #fff !important;
  @include box-shadow($left: 0px, $top: 0px, $blur: 20px, $color: rgba(0, 0, 0, 0.13));
  pointer-events: auto !important;
  transform: none;

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }

  span {
    color: $darkgrey !important;
  }

  .contact-status {
    color: #fff !important;
  }

  label {
    margin-bottom: 0.3rem;
  }
  a,
  .starRating,
  .icon-Star {
    transition: none;
  }
  .contactcard-team-member {
    color: #fff !important;
  }
  .contact-name-section {
    z-index: 10000;
  }
  &:after {
    display: none;
  }
}
.expndAction {
  @include position(absolute, $top: 10px, $right: 10px);
  a {
    color: $midgrey;
    font-weight: 600;
    &:hover {
      color: $primary-color;
    }
  }
}
.cardHead {
  .cardAction {
    border-left: 1px solid $sky;
    border-bottom: 1px solid $sky;
    a {
      color: $midgrey;
      font-weight: 600;
      &:hover {
        color: $primary-color;
      }
    }
    &:first-child {
      border-left: none;
    }
  }
}
.contactCardThreadDetail {
  margin-left: -10px !important;
}
