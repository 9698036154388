@import 'scss/variables.scss';
@import 'scss/mixins.scss';

#page-wrapper,
#root,
.crPage,
.page-wrapper,
body,
.AppContainer, 
.Main, 
.Main__Content,
html {
  height: 100%;
}
#root {
  position: relative;
  display: flex;
}
.login-bg #root{
  display: block;
}

.crPage--inner {
  display: flex;
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  overflow: auto;
  background-color: $lightsky;
  @media screen and (max-width: 767px) {
    align-items: self-start;
  }

  &Form {
    width: 415px;
    &.w-550 {
      width: 550px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      &.w-550 {
        width: 100%;
      }
    }

    &--sec {
      width: 100%;
      background-color: #fff;
      padding: 3rem;
      margin-top: 1.5rem;
      @include box-shadow($left: 0, $top: 0, $blur: 30px, $color: rgba(0, 0, 0, 0.08));

      .homeLogo {
        margin-top: 3rem;
        margin-bottom: 1rem;
        max-width: 200px;
      }

      h1 {
        @include font-size(1.6);
        margin: 4rem 0 1.3rem;
        text-align: center;
      }
      .alert {
        margin-top: -0.5rem;
      }
      .error_label {
        @include font-size(0.8);
      }

      .txtInfo {
        color: $lightgrey;
        @include font-size(0.9);
        vertical-align: middle;
      }

      .cpaImg {
        width: 40px;
        height: 40px;
        background-color: $lightgrey;
        position: relative;
        img {
          max-width: 100%;
          padding: 5px;
          @include position(absolute, $top: 50%, $left: 50%);
          @include translate(-50%, -50%);
        }
      }

      .activeUser {
        &:focus {
          background-color: transparent !important;
        }
      }

      .tptBtn {
        padding: 0 0.2rem;
        color: $lightgrey;
        @include font-size(0.9);
        letter-spacing: 0;
        border: none;
        background-color: transparent;
        b {
          color: $midgrey;
        }

        &:hover {
          color: $primary-color;
          background-color: transparent;
          b {
            color: $primary-color;
          }
        }
      }

      .crPage--bott {
        padding-top: 0.5rem;

        .check-container {
          label {
            color: $darkgrey;
            @include font-size(0.95);
            text-transform: none;
            font-weight: 400;
            input ~ .checkmark {
              top: 2px;
            }
          }
        }
      }
    }

    &--footer {
      text-align: center;
      padding: 2rem 0 1.5rem;

      .crPaeg--links {
        a {
          &:after {
            content: '|';
            padding: 0 0.4rem;
          }

          &:last-child {
            &:after {
              display: none;
            }
          }
        }
      }

      a {
        color: $lightgrey;
        @include font-size(0.8);

        &:hover {
          color: $primary-color;
          &:after {
            color: $lightgrey;
          }
        }
      }

      p {
        color: $lightgrey;
        @include font-size(0.8);
        margin-bottom: 0;
        margin-top: 0.8;
      }
    }
  }
}

#page-wrapper {
  width: 100%;
}

.login-error-danger {
  background-color: #de361833;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
