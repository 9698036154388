.noCaret:after {
  display: none !important;
}

.dropdown {
  a[aria-expanded='false']::before,
  a[aria-expanded='true']::before {
    display: none;
  }

  & > .iconCircle {
    border: 1px solid $midgrey;
    color: $midgrey;
    padding: 0;
    @include border-radius(50%);

    i {
      padding-right: 0;
    }

    &--w40 {
      //line-height: 2.53;
      //vertical-align: middle;
    }

    &:not(:disabled):not(.disabled) {
      border: 1px solid $midgrey;
      color: $midgrey;

      &:hover {
        border-color: $primary-color;
        color: $primary-color;
      }
    }

    &:hover {
      border-color: $primary-color;
      color: $primary-color;
    }
  }

  &--small {
    .dropdown-menu {
      width: 170px;

      .dropdown-item {
        @include font-size(0.87);
        padding: 0.4rem 0.62rem;
      }
    }
  }
}

.dropdown-menu {
  border: 2px solid $midgrey;
  background-color: #fff;
  padding: 0;
  width: 250px;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 0.4rem;

  .input-group {
    padding-left: 25px;

    span.input-group-addon {
      position: absolute;
      left: 10px;
      top: 8px;
      @include font-size(0.87);

      i:before {
        color: $midgrey;
      }
    }
  }

  .dropdown-item {
    color: $midgrey;
    text-decoration: none;
    @include font-size(0.94);
    padding: 0.5rem 0.62rem;
    white-space: normal;
    word-break: break-word;
    float: left;
    width: 100%;

    label {
      @include font-size(0.94);
      color: $midgrey;
      @include transition(all ease-in-out 350ms);
      font-weight: 400;
      &.check-container {
        width: 100%;
        input[type='checkbox'] {
          width: 100%;
          height: 100%;
        }
      }
      .checkmark {
        top: 2px;
      }
      &.label-large {
        @include font-size(0.87);
        text-transform: none;
      }
    }

    &.active,
    &:active,
    &:hover,
    &:focus {
      background: transparent;
      color: $darkgrey;

      label {
        color: $darkgrey;
      }
    }
  }
  &.recurring-dropdown {
    width: 290px;
    margin-top: 15px;
    border: none;
    max-height: 420px;
    overflow: visible;
    @include box-shadow(
      $left: 0px,
      $top: 0px,
      $blur: 20px,
      $color: rgba(0, 0, 0, 0.13)
    );
    & > .dropdown-item {
      position: relative;
      &:before {
        width: 0;
        height: 0;
        content: '';
        opacity: 1;
        visibility: visible;
        border-bottom: 10px solid #fff;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        @include position(absolute, $top: -10px, $left: 50px);
      }
    }
    .monthClass {
      min-width: 70px;
      .dropdown-toggle {
        width: 100%;
      }
    }
  }
}

.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: $primary-color;
  box-shadow: none;
  border-color: $primary-color;

  &:focus {
    box-shadow: none;
  }
}

.select-filter-list {
  margin-top: 5px;
  padding-top: 5px;
}

.dropdown-toggle:after {
  float: right;
  margin-top: 9px;
}

.dropupWrap {
  margin-left: 0.75rem;

  .btn-link {
    &:after,
    &:before {
      display: none;
    }
  }

  .dropupWrap--caret {
    width: 0;
    height: 0;
    content: '';
    opacity: 0;
    visibility: hidden;
    border-bottom: 10px solid #fff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    z-index: 1001;
    @include position(absolute, $top: 50px, $left: 0);
  }

  .dropdown-menu {
    width: 200px;
    padding: 0.6rem 0.2rem;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.16);
    border: none;
  }

  &--small {
    .dropdown-menu {
      width: 200px;

      .dropdown-item {
        padding: 0.5rem 0.94rem;
        @include font-size(0.87);
      }
    }
  }

  &--center {
    .dropupWrap--caret {
      left: 50%;
      @include translate(-50%, 0);
    }

    .dropdown-menu {
      -webkit-transform: translate(-50%, 0) !important;
      -moz-transform: translate(-50%, 0) !important;
      transform: translate(-50%, 0) !important;
      top: 52px !important;
      left: 50% !important;
    }
  }

  &--right {
    .dropupWrap--caret {
      right: 10px;
      left: auto;
      @include translate(0, 0);
    }

    .dropdown-menu {
      -webkit-transform: translate(0, 0) !important;
      -moz-transform: translate(0, 0) !important;
      transform: translate(0, 0) !important;
      top: 52px !important;
      left: auto !important;
      right: 0;
    }
  }

  &--left {
    .dropupWrap--caret {
      left: 10px;
      @include translate(0, 0);
    }

    .dropdown-menu {
      -webkit-transform: translate(0, 0) !important;
      -moz-transform: translate(0, 0) !important;
      transform: translate(0, 0) !important;
      top: 52px !important;
      left: 0 !important;
    }
  }

  > .btn-sm.btn-link {
    .dropupWrap--caret {
      top: 50px !important;
    }
  }

  > .btn-sm.btn-link + .dropdown-menu {
    top: 52px !important;
  }

  > .btn-sm.btn--onlyicon {
    .dropupWrap--caret {
      top: 38px !important;
    }
  }

  > .btn-sm.btn--onlyicon + .dropdown-menu {
    top: 40px !important;
  }

  &.show {
    .dropupWrap--caret {
      opacity: 1;
      visibility: visible;
    }
  }
}
// Drophover

.drophover {
  position: relative;

  .drophover-menu {
    @include box-shadow(
      $left: 0px,
      $top: 0px,
      $blur: 20px,
      $color: rgba(0, 0, 0, 0.13)
    );
    padding: 0.55rem 0.4rem;
    @include border-radius(0.4rem);
    background-color: #fff;
    width: 220px;
    @include position(absolute, $top: 100%, $left: 0);
    opacity: 0;
    visibility: hidden;
    @include transition(all ease-in-out 350ms);
    z-index: 99;
    &--custom {
      display: flex;
      width: auto;
      .tag {
        word-break: normal !important;
        white-space: nowrap;
      }
    }
  }

  &--rightTop {
    .drophover-menu {
      left: 40%;
      bottom: 40%;
      top: auto;
    }
  }

  &--leftTop {
    .drophover-menu {
      right: 40%;
      bottom: 40%;
      top: auto;
      left: auto;
    }
  }

  &:hover {
    .drophover-menu {
      visibility: visible;
      opacity: 1;
    }
  }
}

.react-phone-number-input {
  .react-phone-number-input__row {
    display: flex;
  }
  img,
  a img {
    border: 0px none;
    outline: none;
    width: 20px;
    height: 20px;
    margin: 0 4px 0 0;
  }
  i {
    padding: 0;
  }
  .Select-arrow:before {
    content: '\E938';
    font-family: 'LiscioFonts' !important;
    color: #919eab;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 8px;
  }
  .react-phone-number-input__country {
    border: none;
  }
  .btn-secondary {
    display: flex;
    background: none;
    border: 2px solid #dfe3e8;
    padding: 0.288rem 0.5rem;
  }
  .btn-secondary.dropdown-toggle:focus,
  .btn-secondary:not(:disabled):not(.disabled):active:focus {
    border: 2px solid $midgrey;
    box-shadow: none;
    background-color: transparent;
  }
  .btn-secondary:not(:disabled):not(.disabled):active {
    border: 2px solid $midgrey;
    border-radius: 2px;
    color: $darkgrey;
    background-color: transparent;
  }
  .Select-arrow {
    border-color: #fff transparent transparent;
    border-style: solid;
    border-width: 0;
  }
  .show {
    .btn-secondary.dropdown-toggle {
      background-color: transparent;
      border: 2px solid $midgrey;
    }
  }
}

.mt-icon {
  color: #919eab;
  position: relative;
  &:hover {
    color: #919eab;
  }
}

.unread-dot {
  height: 0.5em;
  width: 0.5em;
  border-radius: 100%;
  background: #06aecc;
  position: absolute;
  right: 5px;
  bottom: 20px;
}

.new-message-dot {
  height: 0.5em;
  width: 0.5em;
  border-radius: 100%;
  background: #06aecc;
  position: absolute;
  bottom: 17px;
}

.email-tool-bar {
  min-width: 17vw;
  display: flex;
  justify-content: space-around;
}
