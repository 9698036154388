/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.form-control {
  padding: 0.313rem 0.5rem;
  @include font-size(0.94);
  color: $darkgrey;
  height: auto;
  border: 2px solid $sky;
  border-radius: 0.25rem;
  background-color: transparent;
  border-radius: 2px;
  &:focus {
    border-color: $midgrey;
    box-shadow: none;
  }

  &:disabled {
    border-color: $sky;
    background-color: $lightsky;
    color: $lightgrey;
    cursor: not-allowed;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .form-control {
    line-height: normal;
  }
}
.inviteSelect {
  height: 36px;
}

.InputGroupFocus {
  .input-group {
    border: 2px solid $sky;
    max-height: 36px;
    max-width: 280px;

    .form-control {
      border: 0;
      background: transparent;
      padding-right: 0;
      padding-left: 0.5rem;
    }

    .input-group-prepend + .form-control {
      padding-left: 0;
      padding-right: 0.5rem;
    }

    .input-group-text {
      border: 0;
      color: $midgrey;
      padding: 0.375rem 0.5rem;
      @include font-size(0.95);
      background: transparent;
    }
  }

  .input-group:focus-within {
    border-color: $midgrey;
  }
}
.dropdown-item {
  .InputGroupFocus {
    .input-group {
      width: 165px;
    }
  }
}
.pr-7 {
  padding-right: 150px;
}
.top-0 {
  top: 0;
}
.right-0 {
  right: 0;
}
.notify--fill {
  background-color: #f9fafb !important;
}
.rounded--2 {
  border-radius: 2px;
}
.notify--border {
  border: 1px solid #dfe3e8;
}
.notify-padding {
  padding: 3px 10px 3px 5px;
}
.bottom-4 {
  bottom: auto;
  top: 28px;
}
.msg-icon-width {
  width: 37px !important;
  height: 35px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.virtual-meet-not-conct {
  max-width: 300px;
  margin-bottom: 10px;
}
.email-msg-error {
  bottom: 26px;
}

.lowercase {
  text-transform: none !important;
}

.form-group {
  label {
    @include font-size(0.75);
    color: $midgrey;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: left;
    font-weight: 600;
    padding-top: 0;
  }

  label.align-self-start {
    padding-top: 3px;
  }

  .error_label {
    display: none;
    @include font-size(0.9);
  }
  .labelValue {
    word-break: break-word;
    word-wrap: break-word;
    -webkit-hyphens: manual;
    -ms-hyphens: manual;
    hyphens: auto;
  }
}
.NewTaskEmail .form-inline-label .select-custom-class {
  width: 100%;
  max-width: 230px;
}
.NewTaskEmail .EmailDate .iconReactCal {
  max-width: 230px;
  width: 100%;
}
.form-inline-label {
  display: flex;
  align-items: center;

  label {
    margin-bottom: 0;
    min-width: 110px;
    margin-right: 0.5rem;
  }

  .labelValue {
    flex-grow: 1;
  }

  .select-custom-class {
    width: 100%;
    max-width: 190px;
  }
}

.email-lower-case {
  text-transform: none;
}

.inputGroup {
  position: relative;

  .form-control {
    padding-right: 40px;
  }

  .inputHelpIcon {
    @include position(absolute, $top: 10px, $right: 0);
    padding: 0 0.62rem;
    color: $midgrey;
    opacity: 0.5;
    text-align: center;
    @include font-size(0.8);
  }

  &--value {
    .inputHelpIcon {
      position: static;
      padding: 0 0.2rem;
      cursor: pointer;
    }
  }
}
.form-icon {
  .inputGroup {
    .form-control {
      padding-right: 0;
    }
    .inputHelpIcon {
      top: -22px;
    }
  }
}
.form-inline-label.customformLabel {
  border-bottom: 1px solid $sky;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;

  .select-custom-class {
    max-width: 170px;
  }
  // .select-custom-class.task-type-select {
  //   max-width: 190px;
  // }
  > label {
    @include font-size(1.13);
    text-transform: none;
    font-weight: 400;
    flex: 0 0 90px;
    max-width: 90px;
  }
}

.form-control-select::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: $lightgrey;
  border-color: red;
}

.form-control-select:-moz-placeholder,
.form-control:-moz-placeholder {
  /* FF 4-18 */
  color: $lightgrey;
}

.form-control-select::-moz-placeholder,
.form-control::-moz-placeholder {
  /* FF 19+ */
  color: $lightgrey;
}

.form-control-select:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: $lightgrey;
}

.date-calender {
  > .fa {
    color: $midgrey;
    vertical-align: middle;
  }

  > div {
    display: inline-block;
    margin-left: 0.5rem;
  }
  & > .dropdown {
    margin-left: 0;
    padding-left: 0.3rem;
  }
  &-icon {
    position: absolute;
    // z-index: 10000; Just keeping it here in case we need it
    & > div {
      margin-left: 0;
    }
    .icon-calendar {
      @include position(absolute, $left: 8px, $top: 7px);
      z-index: 1;
    }
    .react-datepicker__input-container {
      input {
        @extend .form-control;
        padding-left: 30px;
        padding-right: 0.2rem;
        line-height: 1;
      }
    }
  }
}

select:required:invalid {
  color: $midgrey;
  background: #fbeae5;
  border-color: #de3618;
}

option {
  &[value=''][disabled] {
    display: none;
  }
  color: $darkgrey;
}
// Select React

.select-custom-class {
  .css-sjsido-singleValue {
    grid-area: 1 / 1 / 1 / 1;
  }
  .Select-control {
    padding: 0 0.5rem;
    @include font-size(0.94);
    position: relative;
    color: $darkgrey;
    border: 2px solid $sky;
    border-radius: 2px;
    height: auto;
    background-color: transparent;

    &:focus {
      border-color: $midgrey;
      box-shadow: none;
    }

    .Select-placeholder {
      // @include position(absolute, $top:0, $left:0);
      color: $lightgrey;
      // line-height: 32px;
    }

    .Select-input {
      padding-left: 0;
      height: 32px;
      line-height: 32px;
      // width: 100%;
      // height: auto;
      // padding: 0;
      // position: absolute;
      //
      input {
        width: 100% !important;
        border: none;
        padding: 0;
      }
    }

    .Select-multi-value-wrapper {
      // display: table-cell;
      // position: relative;
      // vertical-align: middle;

      .Select-input,
      .Select-placeholder,
      .Select-value {
        // width: 100%;
        // padding: 0;
        // line-height: 1.5;
        // top: 50%;
        // left: 0;
        // @include translate(0, -50%);
        // bottom: auto;
      }
    }

    .Select-arrow-zone {
      width: 10px;
      padding-left: 5px;
      padding-right: 0;

      .Select-arrow {
        border: none;
        @include font-size(0.62);
        width: auto;
        position: relative;
        top: -1px;

        &:before {
          content: '\e938';
          font-family: 'LiscioFonts' !important;
          color: $midgrey;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }

    .Select-clear-zone {
      width: 10px;
    }

    .Select-value {
      .sb-avatar {
        position: relative;
        vertical-align: middle;
        margin-right: 0.3rem;
        top: -2px;

        & > div {
          background-color: $lightgrey !important;
        }

        img {
          @include position(absolute, $top: 0, $left: 0, $right: 0, $bottom: 0);
          margin: auto;
        }
      }
    }

    &:hover {
      box-shadow: none;
    }
  }

  &.has-value.is-searchable.Select--single {
    .Select-multi-value-wrapper {
      .Select-value {
        // padding-right: 0;
        .Select-value-label {
          width: 100%;
          text-overflow: ellipsis;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }

  &.is-focused:not(.is-open),
  &.is-open,
  &.is-searchable.is-open {
    > .Select-control {
      border-color: $midgrey;
      box-shadow: none;
      background-color: transparent;
    }
  }

  .Select-menu-outer {
    border: 2px solid $midgrey;
    border-top: 0;
    margin-top: -2px;
    z-index: 2;

    .avatar {
      position: relative;
      vertical-align: middle;
      margin-right: 0.3rem;

      img {
        @include position(absolute, $top: 0, $left: 0, $right: 0, $bottom: 0);
        margin: auto;
      }
    }

    .Select-menu {
      .Select-option {
        color: $midgrey;
        padding: 0.32rem 0.62rem;
        transition: all ease-in-out 350ms;
        word-wrap: normal;
        word-break: break-all;
        background-color: transparent;
        display: flex;
        align-items: center;

        .avatar {
          flex: 0 0 22px;
          & > div {
            background-color: $lightgrey !important;
          }
        }

        &:hover,
        &:focus {
          color: $darkgrey;
          outline: none;
        }

        &.is-focused {
          color: $darkgrey;
          background-color: transparent;

          &:hover,
          &:focus {
            color: $darkgrey;
            outline: none;
          }
        }

        &.is-selected {
          color: $darkgrey;
        }
      }
    }
  }
  &.slctTemp {
    .Select-menu {
      .Select-option {
        flex-direction: column;
        align-items: flex-start;
        padding: 0.6rem 0.62rem;
        .emCt {
          color: darken($sky, 20%);
          @include font-size(0.82);
          br {
            display: none;
          }
        }
        & + .Select-option {
          border-top: 1px solid $sky;
        }
      }
    }
  }
}
.Select--single {
  & > .Select-control {
    .Select-value {
      // line-height: 32px;
    }
  }
}

.selectedUser {
  img {
    margin-right: 0.4rem;
    @include border-radius(50%);
  }
}
// Avatar Settings
.avatar {
  margin-right: 0.4rem;
  img {
    @include border-radius(50%);
  }

  .uName {
    background: $lightgrey;
    color: #fff;
    text-align: center;
    font-weight: 600;
    @include border-radius(50%);
    vertical-align: middle;
    text-transform: uppercase;
    &--darkbg {
      background: $darkgrey;
    }
  }

  .userName {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
    margin-top: -2px;
  }

  &--w100 {
    img {
      width: 100px;
      height: 100px;
      max-width: 100px;
    }

    .uName {
      width: 100px;
      height: 100px;
      line-height: 100px;
      @include font-size(1.3);
    }

    .userName {
      @include calc(width, '100% - 108px');
    }
  }

  &--w48 {
    img {
      width: 48px;
      height: 48px;
      max-width: 48px;
    }

    .uName {
      width: 48px;
      height: 48px;
      line-height: 48px;
    }

    .userName {
      @include calc(width, '100% - 56px');
    }
  }

  &--w40 {
    img {
      width: 40px;
      height: 40px;
      max-width: 40px;
    }

    .uName {
      width: 40px;
      height: 40px;
      line-height: 40px;
    }

    .userName {
      @include calc(width, '100% - 48px');
    }
  }

  &--w30 {
    img {
      width: 30px;
      height: 30px;
      max-width: 30px;
    }

    .uName {
      width: 30px;
      height: 30px;
      line-height: 30px;
    }

    .userName {
      @include calc(width, '100% - 38px');
    }
  }

  &--w20 {
    img {
      width: 20px;
      height: 20px;
      max-width: 20px;
    }

    .uName {
      width: 20px;
      height: 20px;
      line-height: 20px;
      font-size: 10px;
    }

    .userName {
      @include calc(width, '100% - 28px');
    }
  }

  &--overlap {
    display: flex;
    flex-direction: row-reverse;

    img {
      margin-left: -2px;
      margin-right: 0;
      position: relative;
    }

    .uName {
      margin-left: -2px;
      margin-right: 0;
      position: relative;
    }
  }

  &--border {
    .uName,
    img {
      box-shadow: 0 0 0 2px lighten($lightgrey, 15%);
      @include border-radius(50%);
      border: 1px solid #fffcfc;
      margin-right: 0.5rem;
      margin-left: 0.15rem;
    }

    &.avatar--w100 {
      .uName {
        line-height: 94px;
      }

      .uName,
      img {
        width: 96px;
        height: 96px;
      }
    }

    &.avatar--w48 {
      .uName {
        line-height: 42px;
      }

      .uName,
      img {
        width: 44px;
        height: 44px;
      }
    }

    &.avatar--w40 {
      .uName {
        line-height: 34px;
      }

      .uName,
      img {
        width: 36px;
        height: 36px;
      }
    }

    &.avatar--w30 {
      .uName {
        line-height: 24px;
      }

      .uName,
      img {
        width: 26px;
        height: 26px;
      }
    }

    &.avatar--w20 {
      .uName {
        line-height: 18px;
      }

      .uName,
      img {
        margin-left: 0;
      }
    }
  }

  &--borderPrimary {
    .uName,
    img {
      box-shadow: 0 0 0 2px $primary-color;
    }
  }

  &--borderGrey {
    .uName,
    img {
      box-shadow: 0 0 0 2px $darkgrey;
    }
  }
}
// Custom EditorState
.customEditor {
  textarea.form-control {
    width: 100%;
    min-height: 60px;
    background-color: $lightsky;
  }

  &.boxResize {
    .file-drop-target {
      position: relative;
      .Editor-controls {
        position: absolute;
        bottom: 0;
      }
      .DraftEditor-root {
        height: 100%;
      }
    }
    .DraftEditor {
      padding-bottom: 40px;
      resize: vertical;
      overflow-y: auto;
      min-height: 95px;
      &.scrlOption {
        overflow-y: auto !important;
        .DraftEditor-root {
          overflow: initial !important;
          padding-bottom: 0;
        }
      }
    }
    .tox-editor-header {
      border-top: none !important;
      .tox-toolbar-overlord,
      .tox-toolbar__primary {
        background-color: transparent !important;
      }
    }
  }

  .DraftEditor {
    // height: 190px;
    padding: 0;
    @include font-size(0.94);
    color: $darkgrey;
    border: 2px solid $sky;
    border-radius: 0.25rem;
    border-radius: 2px;
    margin-bottom: 0;

    .DraftEditor-root {
      margin-top: 0;
      padding: 0.35rem 0.5rem;
      border-bottom-color: $sky;
      @include calc(height, '100% - 40px');
      overflow: auto;
      min-height: 0px;

      .DraftEditor-editorContainer {
        padding: 0;
        height: 100%;

        .public-DraftEditor-content {
          padding: 0;
          height: 100%;
          word-break: normal;

          span {
            display: inline;
          }
        }

        a {
          padding: 0;
        }
      }

      img {
        width: 100%;
      }
    }

    .Editor-controls {
      margin-top: 0;
      align-items: center;
      height: 40px;
      padding: 0.35rem 0.5rem;

      .RichEditor-styleButton {
        border: none;
        border-radius: 4px;
        margin-bottom: 0;
        width: auto;
        padding: 0.3rem 0.2rem;
        @include font-size(1.2);
        @include transition(background-color ease-in-out 350ms);
        &:hover {
          background-color: darken($sky, 1%);
        }
      }

      .icon-font_download {
        color: $yellow;
      }
    }

    .highlight-btn-dropdown {
      .colorbox {
        background-color: $sky;
        @include border-radius(2px);
        @include box-shadow(
          $left: 0px,
          $top: 0px,
          $blur: 20px,
          $color: rgba(0, 0, 0, 0.13)
        );

        span {
          cursor: pointer;
        }
      }

      .heightbox {
        background-color: #fff;
        @include border-radius(2px);
        @include box-shadow(
          $left: 0px,
          $top: 0px,
          $blur: 20px,
          $color: rgba(0, 0, 0, 0.13)
        );
        min-width: 40px;

        span {
          padding: 0.3rem;
          color: $midgrey !important;
          height: auto !important;
          margin: 0;
          cursor: pointer;
          @include border-radius(2px);
          background-color: transparent;
          @include transition(all ease-in-out 350ms);

          &:hover {
            color: $darkgrey !important;
          }
        }
      }
    }
    &:focus,
    &:focus-within {
      border-color: $midgrey;
      box-shadow: none;
      outline: none;
      .DraftEditor-root {
        border-bottom-color: $midgrey;
      }
    }
  }

  &--bottombtn {
    position: relative;

    .DraftEditor {
      &-root {
        border-bottom: none;
      }
    }

    .btnWrap {
      position: absolute;
      right: 0.5rem;
      bottom: 0.5rem;
      z-index: 99;
    }
  }
}

body .tox .tox-statusbar {
  display: none;
}

// Drag Drop

.dragBrowse {
  .DragDrop {
    background-color: transparent;
    padding: 0.5rem 1rem;
    color: $darkgrey;
    border: 2px dashed $sky;
    text-align: center;

    &--mh350 {
      height: 350px;
    }

    &--mh100 {
      height: 100px;
    }
    &:focus-within {
      border-color: $midgrey;
    }
  }

  .dropFile {
    padding: 0.5rem 0.2rem;
    color: $midgrey;
    .center-wrapper {
      padding: 0;
    }
    & > .col {
      padding-left: 0;
      padding-right: 0;
    }
    &.icon-upload {
      font-weight: 600;
      margin-right: 0.3rem;
    }

    .fileContainer {
      margin-bottom: 0;
      font-weight: bold;
      text-transform: lowercase;
      @include font-size(0.87);
      @include transition(color ease-in-out 350ms);
      cursor: pointer;
      color: $primary-color;
      min-width: 0;
      margin-right: 0;

      &:hover {
        color: darken($primary-color, 15%);
      }
    }
  }
}

.file-drop-dragging-over-frame {
  .DragDropEditor {
    border-color: #919eab;
  }
}

.file-drop-dragging-over-frame .DragDrop {
  border: 2px solid #919eab;
  &:before {
    z-index: 9;
    opacity: 0.8;
    visibility: visible;
  }
}

.file-drop-upload {
  display: flex;
  margin-top: 0;
  padding: 0.8rem 1rem 0;

  .file-drop-type {
    margin-right: 0.6rem;
    text-align: center;
    color: $midgrey;
    padding-top: 0.1rem;
  }

  .file-drop-info {
    width: 100%;
    padding-right: 1.5rem;
    position: relative;
  }

  .file-action {
    @include position(absolute, $top: 5px, $right: 0);
    line-height: 1;
    padding: 0;
    color: $midgrey;

    i {
      @include font-size(0.6);
    }

    &:empty {
      display: none;
    }
  }

  .previewFile {
    color: $darkgrey;
    position: static;
    @include translate(0, 0);
    margin-left: 1.2rem;

    i {
      margin-right: 0.4rem;
      color: $midgrey;
      @include transition(all ease-in-out 350ms);
      @include font-size(0.7);
      &.font-2x {
        @include font-size(1);
      }
    }

    &:hover {
      i {
        color: $primary-color;
      }
    }
  }

  a.file-action {
    &:hover {
      color: $primary-color !important;
    }
  }

  .file-drop-detail {
    position: relative;
    display: flex;
    justify-content: space-between;

    .fileName {
      color: $darkgrey;
      flex: 1 1 auto;
      @include font-size(0.94);
      margin-bottom: 0;
      padding: 0 10px 0 0;
      float: left;
      word-break: break-all;
    }

    a.fileName {
      &:hover {
        color: $primary-color;
      }
    }

    .fileweight {
      float: right;
      color: $midgrey;
      white-space: nowrap;
    }

    .rc-progress-line {
      height: 5px;
      width: 100%;
      @include position(absolute, $top: 100%, $left: 0);
      margin-top: 0.2rem;

      .rc-progress-line-path,
      .rc-progress-line-trail {
        stroke-linecap: square;
      }

      .rc-progress-line-trail {
        stroke-width: 5px;
        stroke: $sky;
      }

      .rc-progress-line-path {
        stroke: $primary-color;
      }
    }
  }
}

.attached-file-list {
  .file-drop-info {
    padding-right: 0;
    display: flex;
    flex: 1 1 auto;
  }

  .file-drop-detail {
    flex: 1 1 auto;
    padding-top: 3px;
  }

  .file-action {
    white-space: nowrap;
    position: static;
    @include translate(0, 0);
    margin-left: 1rem;
    padding-top: 4px;
  }
}

.uploadContent {
  .file-drop-upload {
    background-color: #fff;
    padding: 0.66rem;
    @include transition(all ease-in-out 350ms);

    &:not(:first-child) {
      margin-top: 1rem;
    }

    &:hover {
      @include box-shadow(
        $left: 0,
        $top: 5px,
        $blur: 20px,
        $color: rgba(0, 0, 0, 0.1)
      );
    }
  }
}

.labelValue {
  p {
    margin-bottom: 0;
  }

  p + p {
    margin-top: 1rem;
  }
}

.replyInput {
  .Select-menu {
    max-height: 195px;
  }
}
// Rating SCSS
.starRating {
  & > input {
    display: none;
  }
  & > label {
    color: lighten($yellow, 40%);
    cursor: pointer;
    margin-bottom: 0;
    margin-right: 0.4rem;
    float: right;
    @include transition(color ease-in-out 350ms);
    &:before {
      font-family: 'LiscioFonts' !important;
      display: inline-block;
      @include font-size(0.87);
      content: '\e97b';
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      letter-spacing: 0;
    }
  }
  & > .icon-Star {
    margin-right: 0.4rem;
    color: lighten($yellow, 40%);
    float: right;
    @include font-size(0.87);
    &:first-child {
      margin-right: 0;
    }
  }
  .active,
  .active ~ .icon-Star {
    color: $yellow;
  }
  & > input.checked ~ label,
  & > input:checked ~ label,
  & > input:checked + label:hover,
  & > input:checked ~ label:hover,
  &:not(:checked) > label:hover,
  &:not(:checked) > label:hover ~ label {
    color: $yellow;
  }
}

// New React-Select
.slctTemp {
  .custom-react-select__control {
    width: 100%;
  }
  .custom-react-select__menu {
    margin-top: -3px;
    z-index: 99;
    .custom-react-select__option {
      flex-direction: column;
      align-items: flex-start;
      background-color: transparent;
      cursor: pointer;
      div > span {
        color: darken($sky, 20%);
      }
      .emCt {
        display: block;
        color: darken($sky, 20%);
        @include font-size(0.82);
        br {
          display: none;
        }
      }
      & + .custom-react-select__option {
        border-top: 1px solid $sky;
      }
    }
    .custom-react-select__option {
      div > span {
        color: $darkgrey;
      }
    }
  }
  .custom-react-select__control,
  .custom-react-select__control:hover {
    // border-bottom: transparent;
    & + .custom-react-select__menu {
      border-top: none;
    }
  }
  .custom-react-select__input {
    height: auto;
  }
}

// Form error
.form-error {
  .form-control {
    border-color: $red;
    background-color: rgba($red, 0.1);
    color: $midgrey;
  }

  .error_label {
    display: inline-block;
  }

  .customEditor {
    .DraftEditor {
      border-color: $red;
      background-color: rgba($red, 0.1);
      color: $midgrey;

      .DraftEditor-root {
        border-bottom-color: $red;
      }
    }
  }

  .select-custom-class {
    .Select-control {
      border-color: $red;
      color: $midgrey;
      background-color: rgba($red, 0.1);
    }
  }

  input ~ .checkmark {
    border-color: $red;
    background-color: rgba($red, 0.1);
  }

  &.check-container,
  .check-container {
    input:checked ~ .checkmark,
    input:checked:disabled ~ .checkmark,
    input:disabled ~ .checkmark {
      border-color: $red;
      background-color: rgba($red, 0.1);

      &:after {
        border-color: $red;
      }
    }
  }

  &.radio-container,
  .radio-container {
    input:checked ~ .checkmark,
    input:checked:disabled ~ .checkmark,
    input:disabled ~ .checkmark {
      border-color: $red;
      background-color: rgba($red, 0.1);

      &:after {
        background-color: $red;
      }
    }
  }

  .dragBrowse {
    .DragDrop {
      border-color: $red;
      background-color: rgba($red, 0.1);
      color: $midgrey;

      .dropFile,
      .fileContainer {
        color: $midgrey;
      }
    }
  }

  .customTags {
    .customTags__input {
      background-color: rgba($red, 0.1);

      .form-control {
        background-color: transparent;
      }
    }
  }

  .InputGroupFocus {
    .input-group {
      border-color: $red;
      background-color: rgba($red, 0.1);
    }
  }
}

// Form Error End

.date-input-wrapper {
  background-color: #fff;
  border: 2px solid #dfe3e7;
  border-radius: 2px;
  width: max-content;
  padding: 5px 25px 5px 5px;
  display: flex !important;
  min-width: 130px;
  max-width: 190px;
  margin: 0;
  .icon-calendar {
    color: #909eab;
    margin: auto;
  }
  input {
    border: 0;
    padding-left: 10px;
  }
  :focus-visible {
    outline: none !important;
  }
}

.file-upload-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  background-color: #fff;
  border: 0.5px solid #d6d6d6;
  padding: 1.25em;
}

.files-top {
  font-weight: 100;
  padding: 1em 0.5em 0.25em;
  display: flex;
  align-items: end;
}

.file-row {
  font-weight: 100;
  line-height: 2.5em;
  padding-left: 3em;
}
.file-browser-wrapper {
  padding-left: 3.5%;
  min-height: 100px;
}

.directory-path {
  > * {
    padding-left: 0.35em;
  }
  display: flex;
  flex-direction: row;
}

.files-broswer-header,
.folder-navigation-button {
  &:hover {
    cursor: pointer;
  }
}

.folder-navigation-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.file-upload-options {
  padding: 1em 2em;
  display: flex;
  justify-content: space-between;
  max-width: 230px;
}

.file-upload-name {
  font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI',
    -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;
  font-size: 14px;
}

.file-type-icon {
  min-width: 30px !important;
  color: #f3ceab !important;
  font-weight: 100;
}

.file-upload-button {
  padding: 0 1em;
}

.file-list-container {
  max-height: 45vh;
  overflow: scroll;
}

.time-block {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  max-width: 130px;
  padding-left: 0.35em;
  .react-datepicker__input-container {
    .date {
      white-space: nowrap;
      margin-right: 0.25em;
    }
  }
}
