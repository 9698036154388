.breadcrumbs {
  padding: 0;
  list-style: none;
  display: inline;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  gap: 5px;
  margin-bottom: 0;
  .breadcrumb-item {
    padding: 0px;
    a {
      color: #919eab !important;
    }
    &.active {
      color: #333333;
    }
  }
}

.ui-toolbar {
  display: flex;
  justify-content: space-between;
  input {
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #d0d0d0;
    width: 40%;
    box-shadow: 1px 1px 0.5px 0.5px rgba(0, 0, 0, 0.25);
  }
}

.files-and-folders-uibox {
  display: flex;
  justify-content: flex-start;
  min-height: 450px;
  overflow: auto;
  flex-direction: column;
  position: relative;
}

.files-and-folders-uibox .item-grid {
  cursor: pointer;
  padding: 15px 5px;
  box-sizing: border-box;
  user-select: none;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d3d3d3;
  &.selected {
    border-color: #18c2f1;
    .source-name {
    }
  }
  img.img-responsive {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }
  .source-name,
  .source-path,
  .source-date,
  .source-size {
    margin: 0;
    margin-right: 10px;
    flex: 1.25;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.header {
      color: #919eab;
      text-transform: uppercase;
      vertical-align: bottom;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1px;
      &.active {
        color: #333333;
      }
      i {
        font-size: 8px;
      }
    }
  }
  .source-path {
    flex: 2.5;
    &.header {
      margin-left: 22px;
    }
  }
  .source-date {
    flex: 0.75;
    margin-right: 0px;
    text-align: right;
  }
  .source-size {
    flex: 0.5;
    text-align: right;
    margin-right: 20px;
  }
}

.widget-ctas {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  button {
    margin-left: 15px;
    padding: 10px 25px;
    border-radius: 5px;
    border: 1px solid #d0d0d0;
    box-shadow: 1px 1px 2px 0.5px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
}

.selected,
.selection-border {
  background-color: rgba(24, 194, 241, 0.25);
  border-radius: 2px;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border: 1px solid #18c2f1;
}

.selection-border {
  position: absolute;
  display: none;
  pointer-events: none;
  z-index: 500;
}
.pagination-block {
  margin-top: 20px;
}

.modalContent--inner {
  padding: 16px 32px !important;
}

.invalid-file-message {
  font-size: 14px !important;
  border-radius: 4px;
  margin: 6px 6px 0 6px;
  padding: 6px 14px 6px 14px;
  font-weight: 200;
  background-color: #fbe6e9;
}
