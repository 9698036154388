header {
  background: #ffffff;
  border-bottom: 1px solid $sky;
  @include box-shadow($inset: true, $left: 0, $top: -2px, $blur: 10px, $color: rgba(0, 0, 0, 0.04));
  width: 100%;
  display: inline-block;
  padding: 1.6rem 2.3rem;
  @media screen and (max-width: 767px) {
    padding: 1.6rem 1rem;
  }
  .pageHeading {
    @include font-size(0.9);
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: 1px;
  }
  .nav-tabs {
    border-bottom: 0;
  }
}
.breadcrumb {
  list-style: none;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  margin-bottom: 0;
  color: $darkgrey;
  h3,
  .breadcrumb-item {
    @include font-size(0.87);
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: 1px;
    a {
      color: $midgrey;
      padding: 0;
      i {
        margin-right: 0.2rem;
        font-size: 12px;
      }
      .icon-left-arrow {
        @include font-size(0.52);
      }
      &:hover,
      &:focus {
        background: none;
        color: $darkgrey;
      }
    }
  }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0;
    &:before {
      color: $midgrey;
      padding-right: 0.2rem;
      padding-left: 0.2rem;
    }
  }
}
