.alert-box {
    border-top: 0.25em solid;
    line-height: 2.5em;
    text-align: center;
    font-size: 1.25em;
    padding: 0 0 1px 0;
    display: flex;
    justify-content: center;
    min-width: 100%;
    border-bottom: 0.1px solid rgb(225, 225, 225);
  }
  
  .alert-red {
    background-color: rgba(255, 0, 0, 0.05);
    color: red;
    border-top-color: red;
    position: relative;
  }
  
  .alert-blue {
    background-color: rgba(0, 174, 204, 0.05);
    color: #00aecc;
    border-top-color: #00aecc;
    position: relative;
  }
  
  .main-alert-text {
    padding-right: 0.35em;
    font-weight: 600;
  }
  
  .underlined-link {
    text-decoration: underline;
    cursor: pointer;
  }
  
  .billing-wrapper {
    width: 100%;
  }
  