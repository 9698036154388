@import 'scss/variables.scss';
@import 'scss/mixins.scss';

.clientserviceteam {
  border-radius: 4px;
  overflow: hidden;
  padding: 0;
  opacity: 1 !important;
  width: 175px;
  // background-color: #fff !important;
  @include box-shadow($left: 0px, $top: 0px, $blur: 20px, $color: rgba(0, 0, 0, 0.13));
  pointer-events: auto !important;

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }

  span {
    // color: $darkgrey !important;
  }

  label {
    margin-bottom: 0.3rem;
  }
}
