.customTags {
  position: relative;

  &__input {
    min-height: 34px;
    max-height: 80px;
    overflow-y: auto;
    padding-top: 0.22rem;
    padding-bottom: 0.22rem;
    position: relative;
    .inputHelper {
      @include position(absolute, $top: 0, $left: 0, $right: 0, $bottom: 0);
      cursor: text;
      margin-bottom: 0;
      border: none;
      background: none;
      width: 100%;
    }
    .form-control {
      border: none;
      display: inline-block;
      width: auto;
      max-width: 30px;
      padding: 0;
      position: relative;
      z-index: 1;
      background-color: transparent;
    }

    .tag {
      position: relative;
      margin-top: 0.18rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      top: -2px;
      z-index: 1;
      left:0;
    }
    .email--tag{
      position:relative;
      float:left;
      margin-top:3px;
      padding-top:2px;
      padding-bottom:2px;
      top:0;
      left:0;
      padding-left:2px;
      background-color: #EEF0F1;
    }
    .receipt-account-tag{
      z-index: 2;
    }
  }
  &__hauto {
    min-height: 0;
    max-height: none;
    overflow-y: auto;
    padding: 0;
    .cstRtags {
      min-height: 32px;
      max-height: 80px;
      overflow-y: auto;
      padding: 0.22rem 0.5rem;
      position: relative;
      cursor: text;
    }
  }

  &__option {
    min-width: 280px;

    .dropdown-item {
      color: $darkgrey;

      .label-medium {
        min-width: 70px;
        color: $midgrey !important;
        @include font-size(0.75);
        margin-bottom: 0;
        margin-right: 1rem;
      }

      .dropdown-item--value {
        color: $darkgrey;
        @include font-size(0.8);
      }
    }
  }
  &:focus-within {
    .customTags__input {
      border-color: $midgrey;
    }
  }
}
.email--tag{
  padding:0;
}
.tag {
  @include font-size(0.75);
  color: $midgrey;
  text-transform: uppercase;
  padding: 0.22rem 0.5rem;
  font-weight: 600;
  @include border-radius(2px);
  border: 1px solid $sky;
  margin-right: 0.4rem;
  line-height: 1;

  &:last-child {
    margin-right: 0;
  }

  &--fill {
    background-color: $sky;
  }
  .divOption {
    margin: 0 0.5rem;
  }

  .remove {
    margin-left: 0.4rem;
    cursor: pointer;
    @include font-size(0.5);
    line-height: 1;
  }
  img {
    margin-right: 5px;
  }
}
