@import './variables.scss';
@import 'mixins';

html {
  scroll-behavior: smooth;
}

body {
  line-height: 1.3;
}

body,
p {
  font-family: $fontglobal;
  @include font-size(0.94);
  color: $darkgrey;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
}

.pull-right {
  float: right;
}

.max-none {
  max-width: none !important;
}

.wordBreak {
  word-break: break-all;
  word-break: break-word;
}

.container-fluid {
  padding: 0;
}

input[type='password']::-ms-clear,
input[type='password']::-ms-reveal,
input[type='text']::-ms-clear {
  display: none;
}

.pos-rel {
  position: relative;
}

.noShadow {
  box-shadow: none !important;
}

a {
  color: $primary-color;

  &:focus,
  &:hover {
    text-decoration: none;
    outline: none;
    color: darken($primary-color, 16%);
  }
}

.btn,
a,
button {
  cursor: pointer;
}

.noAnchor {
  a {
    pointer-events: none;
    color: inherit;
  }
}

.pointer {
  cursor: pointer;
}
.noWrap {
  white-space: nowrap;
}
.overflow-x-hide {
  overflow-x: hidden !important;
}

.overflow-y-hide {
  overflow-y: hidden !important;
}

.overflow-hide {
  overflow: hidden !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.flex {
  &-3 {
    flex: 0 0 3% !important;
    max-width: 3%;
  }

  &-10 {
    flex: 0 0 10% !important;
    max-width: 10%;
  }

  &-14 {
    flex: 0 0 14% !important;
    max-width: 14%;
  }

  &-15 {
    flex: 0 0 15% !important;
    max-width: 15%;
  }

  &-18 {
    flex: 0 0 18% !important;
    max-width: 18%;
  }

  &-20 {
    flex: 0 0 20% !important;
    max-width: 20%;
  }

  &-21 {
    flex: 0 0 21% !important;
    max-width: 21%;
  }

  &-22 {
    flex: 0 0 22% !important;
    max-width: 22%;
  }

  &-30 {
    flex: 0 0 30% !important;
    max-width: 30%;
  }
}

.ie-px-1 {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  transition: none;
}

.link,
button.link {
  text-decoration: none;
  @include font-size(0.94);
  color: $primary-color;
  letter-spacing: 0;
  text-align: left;
  padding: 0.2rem;
  border: none;
  background-color: transparent;

  &.linkGrey {
    color: $lightgrey;
  }

  &.linkDark {
    color: $darkgrey;
  }
}
a.link,
button.link {
  &:focus,
  &:hover {
    text-decoration: none;
    outline: none;
  }

  &:hover {
    color: darken($primary-color, 10%);
    text-decoration: none;
  }

  &:focus {
    background: $sky;
    color: darken($primary-color, 10%);
    border-radius: 2px;
    box-shadow: none;
  }
}

.sample-letter-preview {
  width: 100%;
  height: 800px;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.sample-preview {
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.btn,
a,
button,
i,
input[type='button'],
input[type='submit'] {
  @include transition(all ease-in-out 350ms);
}

a {
  i {
    transition: unset;
  }
}

i,
span {
  display: inline-block;
}

b,
strong {
  font-weight: 600;
}

p {
  @include font-size(0.94);
}

.clrGreen {
  color: $green;
}

.clrRed {
  color: $red;
}

.clrPink {
  color: $pink;
}

.clrYellow {
  color: $yellow;
}
.text-transform-none {
  text-transform: none !important;
}
.text-icon-large {
  @include font-size(3.5);
}
.mainHeading,
.text-large {
  @include font-size(1.62);
}

.text-medium {
  @include font-size(1.25);
}

.text-small {
  @include font-size(1.12);
}

.fontSize {
  @include font-size(0.94);
}

.subHeadline {
  @include font-size(0.75);
}

label {
  font-weight: 600;
}

label,
span {
  &.label-large {
    @include font-size(0.87);
  }

  &.label-medium {
    @include font-size(0.75);
  }

  &.label-submedium {
    @include font-size(0.82);
  }

  &.label-small {
    @include font-size(0.62);
  }
}

.label-large {
  @include font-size(0.87);
}

.label-medium {
  @include font-size(0.75);
}

.label-submedium {
  @include font-size(0.82);
}

.label-small {
  @include font-size(0.62);
}

.attachBy {
  @include font-size(0.85);
}

.text-danger,
.text-info,
.text-muted,
.text-primary,
.text-secondary,
.text-success,
.text-warning,
.text-white {
}

.bg-primary {
  background-color: $primary-color !important;
}
.text-primary {
  color: $primary-color !important;
}

a.text-primary {
  &:hover {
    color: darken($primary-color, 16%);
  }
}

.text-dark {
  color: $midgrey !important;
}

.text-body {
  color: $darkgrey !important;
}

.text-light {
  color: $lightgrey !important;
}

.letterSpace-half {
  letter-spacing: 0.5px;
}

.letterSpace-1 {
  letter-spacing: 1px;
}

.letterSpace-2 {
  letter-spacing: 2px;
}

.letterSpace-3 {
  letter-spacing: 3px;
}

.letterSpace-4 {
  letter-spacing: 4px;
}

.letterSpace-5 {
  letter-spacing: 5px;
}

.lineHeight-none {
  line-height: 0;
}

.lineHeight {
  line-height: 1;
}
.lineHeight-1-half {
  line-height: 1.5;
}

.docWrap {
  height: 600px;
}
.crNotification {
  padding: 1rem;
  background-color: lighten($green, 45%);
  border: 1px solid $green;

  ul,
  li {
    margin-bottom: 0;
    padding: 0;
    i {
      vertical-align: middle;
      color: $green;
      font-size: 15px;
    }
    .icon-close2 {
      color: $red;
    }
  }
  ul {
    padding-left: 20px;
  }
}

.privacy-policy {
  h1,
  h3 {
    display: inline-block;
    vertical-align: middle;
    @include font-size(1.6);
    margin-bottom: 0;
  }
  p {
    margin-top: 0.5rem;
  }
  ol {
    list-style: none !important;
    counter-reset: my-awesome-counter;
    padding-left: 1.25rem;
    & > li {
      counter-increment: my-awesome-counter;
      margin: 0.25rem;
      padding-top: 1.25rem;
      &:before {
        content: counter(my-awesome-counter);
        background: $primary-color;
        width: 1.87rem;
        height: 1.87rem;
        @include border-radius(50%);
        display: inline-block;
        line-height: 1.87rem;
        color: white;
        text-align: center;
        margin-right: 0.31rem;
        font-family: Arial, Helvetica, sans-serif;
      }
    }
  }
  ul {
    margin: 1.25rem;
    padding-left: 0.625rem;
    li {
      margin-bottom: 0.625rem;
      list-style-type: decimal;
    }
  }
  &.liDecimal {
    & > div ol ul li {
      list-style-type: none;
    }
  }
}

.inviteTerms {
  * {
    font-family: $fontglobal !important;
    color: $darkgrey !important;
    font-size: 0.95rem !important;
  }
  [class^='icon-'] {
    font-family: 'LiscioFonts' !important;
    font-size: 0.8rem !important;
  }

  & > div {
    & > h1 {
      font-size: 1.33rem !important;
    }

    ol {
      padding-left: 0;
      list-style: none;

      ul {
        list-style: none;
        padding-left: 0;

        li {
          margin-bottom: 0.8rem;
        }
      }
    }

    li {
      & > h3 {
        font-size: 1.3rem;
      }

      a {
        color: $primary-color !important;

        &:hover {
          color: $darkgrey !important;
        }
      }
    }
  }
}

.docsWidth {
  .select-custom-class {
    max-width: 310px;
  }

  .form-control {
    max-width: 194px;
  }
}
// Link Hover

a {
  padding: 0.2rem;

  &.text-dark {
    &:hover {
      color: darken($primary-color, 10%) !important;
      text-decoration: none;
    }

    &:focus {
      background: $sky;
      color: darken($primary-color, 10%) !important;
      border-radius: 2px;
      box-shadow: none;
    }
  }
}

.font-weight-bold {
  font-weight: 700 !important;
}

textarea {
  resize: vertical;
}

.status {
  background: $pink;
  min-width: 8px;
  max-width: 8px;
  height: 8px;
  display: inline-block;
}

.vertical-top {
  vertical-align: top;
}

.vertical-middle {
  vertical-align: middle;
}

.col-container {
  display: table;
  width: 100%;

  .col {
    display: table-cell;
    padding: 0;
    vertical-align: middle;
  }
}

.section-title {
  &--bottomborder {
    border-bottom: 1px solid $sky;
  }

  h5,
  h6 {
    margin-bottom: 0;
  }
}

.center-wrapper {
  .section-title {
    &--fullwidth {
      margin-left: -2.3rem;
      margin-right: -2.3rem;
      padding-left: 2.3rem;
      padding-right: 2.3rem;
      @media screen and (max-width: 767px) {
        margin-left: -1rem;
        margin-right: -1rem;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }

  .chatMsg--newmsg {
    padding: 1.8rem 2.3rem 0;
    margin: 0 -2.3rem;
  }
}

.iconCode {
  width: 13px;
  height: 13px;
  text-align: center;
  background-color: darken($midgrey, 10%);
  color: #fff;
  font-style: normal;
  font-size: 13px;
}
// Elastic Search
.mainHeader {
  display: flex;
  align-items: center;
  .sideWrap {
    margin-left: auto;
    position: relative;
  }
  .iconSearch {
    @include position(absolute, $top: -6px, $right: 0);
    .searchToggle {
      width: 50px;
      height: 50px;
      line-height: 48px;
      display: inline-block;
      color: $secondary-color;
      text-align: center;
      background-color: #fff;
      @include border-radius(50%);
      @include box-shadow(
        $left: 0px,
        $top: 5px,
        $blur: 20px,
        $color: rgba(0, 0, 0, 0.1)
      );
    }
  }
}
.searchBar {
  @include position(fixed, $top: 0, $left: 0, $bottom: 0, $right: 0);
  @include transition(all ease-in-out 350ms);
  background-color: rgba(245, 245, 245, 0.6);
  z-index: 9;
  visibility: hidden;
  opacity: 0;
  &__inner {
    @include position(absolute, $top: 0, $bottom: 0, $right: -100%);
    @include transition(all ease-in-out 350ms);
    background-color: #fff;
    padding: 1rem;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    max-width: 600px;
  }
  .closeBar {
    @include position(absolute, $top: 0.8rem, $right: 1rem);
    color: $sky;
    &:hover {
      color: $darkgrey;
    }
  }
  .searchBlock {
    padding-left: 1rem;
    padding-right: 1rem;
    .form-control {
      @include border-radius(0);
      font-size: 1.6rem;
      color: lighten($midgrey, 5%);
      border: none;
      border-left: 2px solid lighten($midgrey, 15%);
      padding: 0 0 0 0.2rem;
      line-height: 1;
      margin: 0.8rem 0 2rem;
    }
  }
  .taskBlock {
    border-bottom: 1px solid lighten($lightgrey, 10%);
    padding-bottom: 1.55rem;
    margin-bottom: 1.55rem;
    padding-left: 1rem;
    padding-right: 1rem;
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
    &--margin {
      .taskBlock__content {
        margin: 0 -0.5rem;
      }
      .emptyBox {
        padding: 0 0.5rem;
      }
    }
    .drophover-menu {
      flex-wrap: wrap;
      .tag {
        display: block;
        flex: 0 0 100%;
        margin: 0.5rem 0 0;
        &:first-child {
          margin-top: 0;
        }
      }
    }
    h5.subHeadline {
      color: $lightgrey;
      margin-bottom: 1rem;
      font-weight: 600;
    }
    .msgTitle {
      @extend .fontSize, .mb-0;
      width: 100%;
      .snName {
        width: 100%;
        max-width: 305px;
        word-break: break-word;
        word-break: break-all;
      }
      .divCircle {
        @extend .font-weight-bold, .text-medium;
        line-height: 0;
        position: relative;
        top: 2px;
      }
    }
    .msgSub {
      @extend .fontSize;
      margin: 0;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .msgDesc {
      @extend .label-large, .text-dark;
      margin: 0;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      p {
        margin-bottom: 0;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @extend .label-large;
        @extend .text-dark;
      }
      a {
        text-decoration: none;
        width: 100%;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        pointer-events: none;
        @extend .label-large;
        @extend .text-dark;
        &:hover {
          color: $midgrey;
        }
      }
    }
    .tragetLink {
      color: $secondary-color;
      padding: 0;
      &:hover {
        color: $primary-color;
      }
    }
    &__list {
      display: -webki-flex;
      display: -ms-flex;
      display: flex;
      margin-bottom: 0.6rem;
      & > .iconCircle {
        align-self: center;
        flex: 0 0 30px;
      }
      .taskDesc {
        padding-left: 1rem;
        // flex:1 1 auto;
        @include calc(width, '100% - 30px');
        a {
          width: 100%;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    &--msgs {
      .imgList {
        &__item {
          padding-top: 0.8rem;
          padding-bottom: 0.8rem;
          background-color: #fff;
          @include transition(all ease-in-out 350ms);
          .tragetLink {
            display: flex;
            .avatar--w30 {
              flex: 0 0 30px;
            }
            .msgDetail {
              flex-basis: calc(100% - 30px);
            }
            &:hover {
              color: inherit;
            }
          }
          &:hover {
            @include box-shadow(
              $left: 0px,
              $top: 0px,
              $blur: 20px,
              $color: rgba(0, 0, 0, 0.15)
            );
          }
        }
      }
    }
  }
  .taskAction {
    display: flex;
    padding-left: 2.8rem;
    margin: 1rem 0 0;
    a {
      @include font-size(0.75);
    }
  }
  .imgList {
    margin: 0;
    padding: 0 0.5rem;
    &__item {
      list-style: none;
      margin: 0.6rem 0;
      padding-left: 2.9rem;
    }
    &--img {
      .imgList__item {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
  &--active {
    opacity: 1;
    visibility: visible;
    .searchBar__inner {
      right: 0;
    }
  }
}
strong.text-highlight {
  color: inherit;
  font-weight: bold !important;
  background-color: rgba(0, 0, 0, 0.06);
}
.posRel {
  position: relative;
}
.icnRcur {
  @include position(absolute, $top: 2px, $right: -20px);
  font-size: 14px;
}
// Elastic Search End
.tdMsg {
  span {
    color: #919eab !important;
    background-color: transparent !important;
  }
}
.divOption {
  width: 3px;
  height: 3px;
  @include border-radius(50%);
  background-color: $midgrey;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.3rem;

  &--dark {
    background-color: $darkgrey;
  }
}

.onlineStatus {
  position: relative;

  .is-status {
    width: 8px;
    height: 8px;
    @include border-radius(50%);
    display: inline-block;
    @include position(absolute, $top: 2px, $right: -15px);
    background-color: darken($sky, 3%);

    &--online {
      background-color: $green;
    }

    &--busy {
      background-color: lighten($yellow, 7%);
    }

    &--away {
      background-color: darken($red, 3%);
    }

    &--invite-expired {
      background-color: $red;
    }
  }
}

.bottomSpacing {
  margin-bottom: 4.3rem;
}

.bottomspace {
  margin-bottom: 2.85rem;
}

.upperspace {
  margin-top: 2rem;
}

.fa.fa-star {
  color: #fcf0cd;

  &.checked {
    color: #edc200;
  }
}

.clearfix {
  clear: both;
}

.s-alert-wrapper {
  .s-alert-box {
    width: 100%;
    max-width: 500px;
    color: $darkgrey;
    padding: 0.55rem 1rem;
    font-family: $fontglobal;
    border: 1px solid $lightgrey;
    border-top: none;
    opacity: 0;
    visibility: hidden;
    z-index: 99999;
    text-align: center;
    @include border-radius(0 0 4px 4px);
    @include transition(all ease-in-out 350ms);
    -webkit-animation-name: fromTop;
    animation-name: fromTop;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;

    .s-alert-box-inner {
      color: $darkgrey;
      @include font-size(1.12);

      i {
        color: $midgrey;
        margin-right: 0.5rem;
        vertical-align: middle;
      }
    }

    &.s-alert-show {
      opacity: 1;
      visibility: visible;
    }
  }

  .s-alert-close {
    display: none;

    &:after,
    &:before {
      background-color: darken($midgrey, 5%);
      width: 2px;
      height: 55%;
    }

    &:hover {
      &:after,
      &:before {
        background-color: $darkgrey;
      }
    }
  }

  .s-alert-top {
    left: 50%;
    top: -20px;
    right: auto;
    @include translate(-50%, -15px);

    &.s-alert-show {
      top: 0;
      @include translate(-50%, 0);
    }
  }

  .s-alert-success {
    border-color: darken($green, 5%);
    background-color: rgba($green, 0.7);
  }

  .s-alert-warning {
    border-color: $yellow;
    background-color: rgba($yellow, 0.7);
  }

  .s-alert-error {
    border-color: darken($red, 5%);
    background-color: rgba($red, 0.7);
  }

  .s-alert-info {
    border-color: darken($pink, 5%);
    background-color: rgba($pink, 0.7);
  }
}

.alert {
  color: $darkgrey;
  border: none;
  @include border-radius(0);
}

.alert-primary {
  background-color: rgba($primary-color, 0.2);
}

.alert-success {
  background-color: rgba($green, 0.2);
}

.alert-danger {
  background-color: rgba($red, 0.2);
}

.alert-warning {
  background-color: rgba($yellow, 0.2);
}

.alert-info {
  background-color: rgba($pink, 0.2);
}
@-webkit-keyframes fromTop {
  0% {
    margin-top: -20px;
  }

  100% {
    margin-top: 0;
  }
}
@-moz-keyframes fromTop {
  0% {
    margin-top: -20px;
  }

  100% {
    margin-top: 0;
  }
}
@keyframes fromTop {
  0% {
    margin-top: -20px;
  }

  100% {
    margin-top: 0;
  }
}
@-webkit-keyframes fromBottom {
  0% {
    margin-top: 0;
  }

  100% {
    margin-top: 20px;
  }
}
@-moz-keyframes fromBottom {
  0% {
    margin-top: 0;
  }

  100% {
    margin-top: 20px;
  }
}
@keyframes fromBottom {
  0% {
    margin-top: 0;
  }

  100% {
    margin-top: 20px;
  }
}

#loading {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1999;
  width: 100%;
  height: 100%;
  overflow: visible;
  background-image: url('../images/small-loader.gif');
  background-size: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba($sky, 0.6);
}

.hidden {
  display: none;
}

.iconCircle {
  width: 30px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  border: 2px solid $midgrey;
  color: $midgrey;
  @include border-radius(50%);

  &--w40 {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  &--green {
    color: rgba($green, 0.9);
    border-color: rgba($green, 0.3);
  }
}

.labelAligntop {
  align-self: flex-start;
  margin-top: 5px;
}

fieldset {
  border-bottom: solid 1px #dee2e6;
  margin-bottom: 30px;
}

.nopadding {
  padding: 0;
}

.UserAvatar {
  color: #ffffff;
}

.error_label {
  color: $error;
}

.option_box {
  display: block;
}

.Tag-span-Value {
  .tag {
    margin-bottom: 0.4rem;
  }
}
// Mail DropDown

.mailDrodown {
  .customTags__option {
    .dropdown-item {
      display: flex;
      align-items: center;
    }
    max-height: none;
    overflow: visible;

    .dropdown-item--value,
    .label-medium {
      vertical-align: middle;
    }

    .label-medium {
      font-weight: 600;
    }

    .dropdown-item--value {
      flex-grow: 1;
    }

    .error_label {
      display: none;
    }

    .select-custom-class {
      .Select-menu-outer {
        border-top: 2px solid $midgrey;
      }

      .Select-control {
        padding: 0;
        border: none;

        .Select-placeholder {
          padding-left: 0;
          padding-right: 0;
        }

        .Select-value {
          padding-left: 0;
        }
      }

      .Select-arrow-zone {
        display: none;
      }
    }

    .btn-wrap {
      padding: 0.75rem;
      text-align: right;
    }
  }
}

.cstmLiteupload {
  min-width: 320px !important;
  z-index: 9;
  .inItem {
    width: 100%;
    display: inline-block;
    .select-custom-class {
      padding-right: 28px;
    }
    .label-medium {
      min-width: 80px;
    }
    & > .dropdown-item {
      .Select-value {
        & > .Select-value-label {
          background-color: #dfe3e8;
          font-size: 0.75rem;
          color: $midgrey !important;
          width: auto !important;
          margin-top: 5px;
          padding-right: 20px;
          position: relative;
          @extend .tag;
          .Select-clear {
            font-style: normal;
            position: absolute;
            right: 5px;
            top: 1px;
            line-height: 0.8;
            font-weight: normal;
            cursor: pointer;
          }
          .sb-avatar {
            display: none !important;
          }
        }
      }
    }
    .dropdown-menu.show {
      position: static !important;
      transform: translate(0, 0) !important;
      overflow: visible;
      margin-top: 0;
      min-height: 0;
    }
    .rcpForm {
      padding-top: 0.6rem;
      padding-bottom: 0.2rem;
      border-top: 1px solid $sky;
      border-bottom: 1px solid $sky;
      position: relative;
      &:before,
      &:after {
        content: '';
        width: 0;
        height: 0;
        display: inline-block;
      }
      &:before {
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #dfe3e8;
        @include position(absolute, $top: -7px, $right: 5px);
      }
      &:after {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #ffffff;
        @include position(absolute, $top: -6px, $right: 6px);
      }
      .selectBorder {
        .select-custom-class {
          padding-right: 0;
          max-width: 100%;
          .Select-control {
            border: 2px solid $sky;
            padding: 0 0.5rem;
          }
          .Select-placeholder {
            padding-left: 10px;
            padding-right: 10px;
          }
          .Select-value {
            padding-left: 10px;
          }
          .Select-arrow-zone {
            display: table-cell;
          }
        }
      }
    }
  }
  .rcpClick {
    @include position(absolute, $top: 13px, $right: 10px);
    width: 25px;
    height: 25px;
    font-size: 0.75rem;
    text-align: center;
    background-color: $primary-color;
    color: #fff;
    line-height: 18px;

    &:after {
      display: none;
    }
    &:hover {
      opacity: 0.7;
    }
    &.disabled {
      background-color: $sky;
      color: $midgrey;
      cursor: not-allowed;
      pointer-events: none;
      &:hover {
        opacity: 1;
      }
    }
  }
}

.unSupported {
  img {
    max-width: 80px;
    margin-bottom: 0.5rem;
  }
}

.senderInfo {
  label {
    text-transform: uppercase;
    color: $lightgrey;
    margin-right: 1rem;
  }
}

.collapseHead {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  h5 {
    margin-bottom: 0;
  }
}

.collapse {
  padding-bottom: 1.5rem;
}

.taskStatus {
  background-color: $secondary-color;
  color: #fff;
  margin-bottom: 30px !important;

  .taskMarked {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 750px;
    margin: auto;
    padding: 0.72rem 0;
    align-items: center;

    .taskHeading {
      margin-bottom: 0;
      @include font-size(0.98);

      i {
        vertical-align: middle;
        margin-right: 0.3rem;
      }
    }
  }
}

.fileTtype {
  margin-right: 0.6rem;
  width: 36px;
  flex: 0 0 36px;
  height: 26px;
  text-align: center;
  line-height: 26px;
}

.stepBacklink {
  a,
  span {
    color: $darkgrey;

    i {
      font-weight: 600;
      font-size: 9px;
    }

    &:hover {
      color: $midgrey;
    }
  }
}
// Activity Table

.activityTable {
  .activityHeading {
    border-bottom: 1px solid $sky;
    padding-bottom: 1.5rem;
    margin-bottom: 0;
  }
}

.innerList {
  border-bottom: 1px solid $sky;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  cursor: pointer;

  &__list {
    display: flex;

    &__detail {
      flex: 1 1 auto;
    }
  }
}

.listRow {
  &__list {
    display: flex;

    .avatar {
      margin-top: 1.22rem;
      position: relative;
      .status {
        @include position(absolute, $top: -16px, $left: 5px);
      }
    }

    &__detail {
      flex: 1 1 auto;

      p.mb-0 {
        margin-bottom: 0;
      }
    }
  }
}

.activityUser {
  display: flex;
  margin-top: 0.5rem;

  .avatar-w20 {
    flex: 0 0 20px;
  }

  &--detail {
    h5 {
      margin-bottom: 0;
    }

    b {
      font-weight: 700;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.emptyBox {
  .emptyBox-icon {
    color: $lightgrey;
    padding: 4rem;

    h3 {
      margin-bottom: 0;
    }

    i {
      @include font-size(5);
      opacity: 0.5;
      display: block;
      margin: 0 1.25rem 1.25rem;
      color: inherit;
      font-weight: normal;
    }

    p {
      margin-bottom: 0;
      @include font-size(1.25);
      color: $lightgrey;
    }
  }

  &--small {
    .emptyBox-icon {
      padding: 2rem;

      i {
        @include font-size(2);
      }
    }
  }

  .onlyText {
    color: $lightgrey;

    p {
      margin-bottom: 0;
      text-align: center;
      padding: 1rem;
      color: $lightgrey;
    }
  }
}

td {
  .emptyBox {
    .onlyText {
      p {
        padding: 0 0.5rem;
      }
    }
  }
}

.contentHasicon {
  display: flex;

  &--inner {
    margin-right: 1.4rem;

    i {
      @include font-size(0.9);
      width: 30px;
      height: 30px;
      line-height: 27px;
      border-width: 2px;
      border-style: solid;
      @include border-radius(50%);
      text-align: center;
    }

    .clrGreen {
      i {
        border-color: $green;
      }
    }
  }
}

.date-calender {
  .react-datepicker__input-container {
    label {
      min-width: 0;
      color: $darkgrey;
      font-weight: normal;
      @extend .fontSize;
    }
  }
}
.react-datepicker__time-container {
  width: 100px !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 100% !important;
}

.date-picker-arrow-remove {
  .react-datepicker__triangle {
    display: none !important;
  }
}
// Input Range SCSS

.customRange {
  .rangeSlider,
  .rangeslider {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    background: $sky;
    outline: none;
    opacity: 1;
    @include transition(all ease-in-out 350ms);
    @include border-radius(0);
    box-shadow: none;

    .rangeslider__fill {
      background-color: $primary-color;
      @include border-radius(0);
      box-shadow: none;
    }

    .rangeslider__handle {
      width: 15px;
      height: 15px;
      @include border-radius(50%);
      background-color: $primary-color;
      cursor: pointer;
      box-shadow: none;
      border: none;
      outline: none;

      &:after {
        display: none;
      }
    }

    &:focus,
    &:hover {
      opacity: 0.7;
    }
  }
}
// added by Dinakar Tumu

.cursor-default {
  cursor: default !important;
}

.taskWrap {
  .formsetValue {
    .form-inline-label label {
      min-width: 140px;
    }
  }

  .taskDescription {
    a {
      padding: 0;
    }

    p {
      max-width: 100%;
      display: inline-block;
    }

    figure {
      display: flex;
    }

    span {
      display: initial;
    }
  }
}

.reassignhead {
  label {
    text-transform: none;
    font-weight: normal;
    @include font-size(1.12);
    letter-spacing: 0;
  }

  .select-custom-class {
    max-width: 250px;
  }
}
.notifyList {
  label {
    text-transform: none;
    min-width: 64px;
    padding-right: 0;
    margin-right: 0;
    @include font-size(0.94);
  }
  .filterBy {
    label {
      min-width: 70px;
    }
    .select-custom-class {
      max-width: 100px;
    }
  }
}

// Security settings
.settingInner {
  .btn {
    @include border-radius(30px);
    i {
      @include font-size(1.25);
      vertical-align: middle;
    }
  }
  .iconCircle {
    width: 22px;
    height: 22px;
    line-height: 20px;
    @include font-size(0.63);
  }
}
.dList {
  &__inner {
    &:nth-child(2n + 0) {
      margin-left: auto;
    }
    & > .media {
      border-top: 1px solid $sky;
      cursor: pointer;
    }
    &:nth-child(1),
    &:nth-child(2) {
      & > .media {
        border-top: none;
      }
    }
  }
}
.deviceList {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    padding-left: 1.5rem;
    position: relative;
    &:before {
      width: 3px;
      height: 3px;
      content: '';
      display: inline-block;
      background-color: $primary-color;
      @include border-radius(50%);
      @include position(absolute, $left: 0, $top: 8px);
    }
  }
}
.media--dropdown {
  .media-body {
    position: relative;
  }
  .dropdown {
    @include position(absolute, $top: 0, $right: 0);
    .btn-link {
      @include font-size(1.32);
      padding: 0;
      & > i {
        @include rotate(90);
        color: #000;
      }
    }
  }
}
// PDF Viewer
.left-inner-content,
.pdfOuter {
  .pdfWrap {
    width: 100%;
    height: 100%;
    background-color: #525659;
    align-self: stretch;
    padding: 2rem;
    position: relative;
    &__inner {
      width: 100%;
      height: 100%;
      overflow: auto;
      text-align: center;
      background-color: #fff;
      & > canvas {
        max-width: 100%;
        @include transform-origin(left top);
      }
    }
    .zoom {
      position: absolute;
      right: 70px;
      top: 70px;
      background-color: #222;
      border: 2px solid #888;
      border-radius: 7px;
      padding: 5px;
      z-index: 10;
      display: flex;
      flex-direction: column;
      text-align: center;
      & > .ZoomIn,
      & > .ZoomOut {
        border: 1px solid #fff;
        border-radius: 5px;
        background-color: #fff;
        text-align: center;
        width: 25px;
        height: 25px;
        line-height: 23px;
        font-size: 10px;
        font-weight: bold;
        color: black;
        margin: 3px;
        padding: 0;
      }
      & > .ZoomPercent {
        text-align: center;
        font-size: 16px;
        color: white;
        margin: 2px;
      }
    }
    nav {
      width: 100%;
      position: relative;
      top: -60px;
      z-index: 9;
      .pager {
        display: flex;
        width: 100%;
        text-align: center;
        margin: 0;
        padding: 0 30px 0 0;
        justify-content: center;

        li {
          list-style: none;
          margin: 0 0.4rem;

          a {
            color: $secondary-color;
            padding: 0.4rem;
            display: inline-block;
            background-color: #fff;
            @include box-shadow(
              $left: 0px,
              $top: 5px,
              $blur: 20px,
              $color: rgba(0, 0, 0, 0.15)
            );
            @include border-radius(10px);
            i {
              @include font-size(0.65);
              position: relative;
            }

            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }
  }
}
#templateData {
  span {
    display: inline;
  }
  ul,
  ol {
    margin-bottom: 1em;
    margin-top: 1em;
  }
}
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .SensitiveField {
    min-height: 32px;
  }
  .left-inner-content {
    height: 800px;
  }
  .msgLoader {
    width: 100%;
    height: 380px;
    @media screen and (max-width: 1600px) {
      width: 100%;
      height: 250px;
    }
  }
  .taskLoader {
    width: 100%;
    height: 500px;
    @media screen and (max-width: 1600px) {
      width: 100%;
      height: 350px;
    }
  }

  .leftnavLoader {
    width: 100%;
    height: 500px;
    @media screen and (max-width: 1600px) {
      width: 100%;
      height: 350px;
    }
  }
  .col-containerIE {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;

    .col {
      display: block;
    }
  }

  .modal-open {
    #sidebar {
      z-index: -1;
    }

    #footer {
      z-index: -2;
    }

    .chatMsg {
      & > div {
        z-index: -1;
      }
    }
  }
}

.remove-min-width {
  min-width: 0 !important;
}
.shBlock > div > .col-12 {
  padding-left: 0;
  padding-right: 0;
}
.linkRmv {
  @include position(absolute, $top: 5px, $right: 0);
  @include font-size(0.75);
  @extend .text-dark;
}
.rcpList {
  max-height: 150px;
  overflow: auto;
}
// for react-switch
.react-switch {
  vertical-align: middle;
  margin-left: 8px;
}
.link-disabled {
  cursor: not-allowed;
  color: $midgrey;
  pointer-events: none;
  &:hover,
  &:focus {
    color: $midgrey;
  }
}
//for edoc
iframe#post-send-text {
  display: none;
}
iframe .save-as-template-label,
.checkbox-a12,
.authoring-right-pane {
  display: none !important;
}

// for warning box
.alert {
  padding-right: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.alert-dismissible .close {
  position: relative;
  color: inherit;
  display: flex !important;
  align-items: center;
  padding: 8px 1.25rem;
  line-height: 0;
  font-weight: normal;
}
.btWithWrap {
  position: relative;
  .hIcon {
    width: 20px;
    @include position(absolute, $top: 12px, $left: 12px);
  }
  .outlookLogin {
    svg {
      width: 100%;
    }
  }
}
.login-with-google {
  border-color: $blue;
  border-width: 1px;
  color: $blue;
  text-transform: uppercase;
  font-weight: 600;
}
.outlookLogin {
  border-color: $red;
  border-width: 1px;
  color: $red;
  text-transform: uppercase;
  font-weight: 600;
}

.invForm {
  .rcpForm {
    border-bottom: 0 !important;
  }
  .inItem + .btn-wrap.btn--leftspace {
    border-top: 1px solid $sky;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    padding-left: 0;
    padding-right: 0;
  }
}
.cstForm {
  padding-right: 82px;
  flex: 0 0 450px;
  max-width: 450px;
}
.cstBtn {
  position: absolute;
  right: 3px;
  top: 0;
}

// One Drive
.ms-Fabric {
  [class^='icon-'],
  [class*=' icon-'] {
    font-family: FabricMDL2Icons !important;
  }
  .icon-100 {
    font-family: FabricMDL2Icons-1 !important;
  }
}
.ms-OverflowSet {
  .ms-OverflowSet-item {
    &:nth-child(3) {
      display: none;
    }
  }
}
.ms-ContextualMenu {
  .ms-ContextualMenu-list {
    .ms-ContextualMenu-item {
      &:nth-child(3) {
        display: none;
      }
    }
  }
}
.ms-ContextualMenu-Callout {
  .ms-Callout-main {
    .ms-ContextualMenu-container {
      .ms-ContextualMenu {
        .ms-ContextualMenu-list {
          .ms-ContextualMenu-item {
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(9) {
              display: none;
            }
          }
        }
      }
    }
  }
}

.odHeader {
  background-color: $lightsky;
  .nav-tabs {
    .nav-item {
      .nav-link {
        padding: 1rem 2rem;
        @include font-size(0.9);
        background-color: transparent;
      }
    }
  }
}
.picker-dialog {
  z-index: 9982 !important;
}

.cstLinks {
  border: 0;
  margin-bottom: 1px;

  &.btn-link {
    color: $primary-color;
    i {
      color: $primary-color;
      @include transition(all ease-in-out 350ms);
    }
    &:hover {
      color: darken($primary-color, 16%) !important;
      i {
        color: darken($primary-color, 16%);
      }
    }
  }
}

.flWdth .Select-control .Select-multi-value-wrapper {
  width: 100%;
}

.slctClrBtn .Select-clear-zone {
  .Select-clear {
    position: relative;
    &:before {
      content: 'Clear';
      display: inline-block;
      background-color: $primary-color;
      display: inline-block;
      padding: 4px 8px 5px;
      font-size: 14px;
      white-space: nowrap;
      color: #fff;
      position: absolute;
      top: 0px;
      right: 0;
    }
  }
}
.cstmClr {
  .form-control {
    padding-right: 45px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .btn-primary {
    @include font-size(0.68);
    padding: 0.2rem 0.3rem;
    @include position(absolute, $top: 35px, $right: 5px);
  }
  .email-sub-button {
    @include position(absolute, $top: 31px, $right: 0px);
  }
  &.msgCustmClr {
    .btn-primary {
      @include position(absolute, $top: 36px, $right: 20px);
    }
  }
  .Select-multi-value-wrapper {
    display: block;
  }
}

.frmMxWidth {
  .d-block,
  .btn-wrap,
  .labelValue {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .d-block {
    max-width: 280px;
  }
  .btn-wrap,
  .labelValue {
    max-width: 200px;
  }
}

.kSwitch {
  box-shadow: none !important;
  div {
    box-shadow: none !important;
  }
  .react-switch-bg {
    & > div {
      width: 100px !important;
    }
  }
}
.inpopup .react-datepicker-popper {
  z-index: 2;
}
.cstPopupShow .dropdown.show {
  z-index: 4 !important;
}
.cstPopupShow {
  .dropdown {
    height: 100% !important;
    button {
      height: 100%;
    }
  }
}
.cstPopupShow #unassigned_accounts {
  transform: translate(0, 0) !important;
  top: 100% !important;
}
// =================
// ToolTip
.tipWrap {
  position: relative;
  cursor: pointer;
  .tipContent {
    @include transition(all ease-in-out 350ms);
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 50%;
    @include calc(bottom, '100% + 5px');
    @include translate(-50%, 0);
    display: inline-block;
    min-width: 80px;
    @include font-size(0.6875);
    line-height: 1.2;
    @include border-radius(3px);
    font-family: inherit;
    width: auto;
    padding: 5px 7px;
    background-color: #000000;
    border: 1px solid #000000;
    color: #fff;
    margin-top: -3px;
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      @include calc(top, '100% + 1px');
      @include translate(-50%, 0);
      display: inline-block;
      border-top: 6px solid #000000;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
    }
    &:after {
      content: '';
      @include position(absolute, $top: 100%, $left: 50%);
      @include translate(-50%, 0);
      display: inline-block;
      border-top: 6px solid #000000;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
    }
  }
  &:hover {
    .tipContent {
      opacity: 1;
      visibility: visible;
    }
  }
  &--right {
    .tipContent {
      @include calc(left, '100% + 10px');
      @include translate(0, -50%);
      top: 50%;
      bottom: auto;
      z-index: 9;
      &:before {
        @include translate(0, -50%);
        top: 50%;
        left: -7px;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: none;
        border-right: 6px solid #000000;
      }
      &:after {
        top: 50%;
        left: -0.343rem;
        @include translate(0, -50%);
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: none;
        border-right: 6px solid #000000;
      }
    }
  }
  &--bottom {
    .tipContent {
      @include calc(top, '100% + 10px');
      bottom: auto;
      &:before {
        top: auto;
        @include calc(bottom, '100% + 1px');
        border-top: 0;
        border-bottom: 6px solid #000000;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
      }
      &:after {
        top: auto;
        bottom: 100%;
        border-top: 0;
        border-bottom: 6px solid #000000;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
      }
    }
  }
  &--left {
    .tipContent {
      left: auto;
      @include calc(right, '100% + 10px');
      @include translate(0, -50%);
      top: 50%;
      bottom: auto;
      z-index: 9;
      &:before {
        @include translate(0, -50%);
        top: 50%;
        left: auto;
        right: -7px;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: none;
        border-left: 6px solid #000000;
      }
      &:after {
        top: 50%;
        left: auto;
        right: -0.343rem;
        @include translate(0, -50%);
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: none;
        border-left: 6px solid #000000;
      }
    }
  }
}

// for email
.em_email {
  height: calc(100vh - 220px);
}
.em_wrap {
  height: calc(100% - 78px);
}
.em_short {
  cursor: pointer;
  .check-container .checkmark {
    background-color: #fff;
  }
  &-active {
    background-color: darken($lightsky, 1%);
  }
  .emDate {
    @include transition(all ease-in-out 350ms);
    margin-right: -18px;
  }
  .tglDrop {
    opacity: 0;
    visibility: hidden;
    // display: none;
    @include position(relative, $top: 0, $right: 0);
  }
  &:hover {
    @include box-shadow(
      $left: 0px,
      $top: 5px,
      $blur: 20px,
      $color: rgba(0, 0, 0, 0.1)
    );
    .tglDrop {
      opacity: 1;
      visibility: visible;
    }
    .emDate {
      margin-right: 0px;
    }
  }
}
.em_sub {
  .em_attach {
    @include position(absolute, $top: 0, $right: 0);
  }
  .em_subText {
    width: 100%;
    text-overflow: ellipsis;
    padding-right: 30px;
  }
}
.em_right_block {
  background-color: #fff;
  @include box-shadow(
    $left: 0px,
    $top: 5px,
    $blur: 20px,
    $color: rgba(0, 0, 0, 0.1)
  );
}
.em_right_wrap {
  height: calc(100% - 45px);
}
.em_right_blo {
  &:first-child {
    padding-top: 15px;
  }
  padding: 0 20px 15px;
}
// ====================

.tox .tox-toolbar-overlord,
.tox-toolbar__primary {
  background-color: $lightsky !important;
}
body .tox-tinymce--disabled,
body .tox.tox-tinymce--disabled .tox-edit-area__iframe {
  background-color: transparent !important;
}
body .tox .tox-editor-header {
  z-index: 0;
}

.account-name-wrapper {
  display: flex;
}

.account-indicator {
  margin-top: 0.45em;
}

.account-name {
  margin-left: 1em;
}

.EmailDate .iconReactCal {
  max-width: 190px;
  border-bottom: 1px solid #cccccc;
  border: 0px 0px 1px 0px;
  border-right: 0;
  border-radius: 2px;
  border-top: 0;
  border-left: 0;
}
.NewTaskEmail .msgCustmClr input {
  border-bottom: 1px solid #cccccc;
  border-top: 0;
  border-right: 0;
  border-left: 0;
}
.NewTaskEmail .EmailTasks {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}
.NewTaskEmail .dragBrowse {
  background-color: #f9fafb;
}
.NewTaskEmail .cstmClr.msgCustmClr .btn-primary {
  top: 32px;
  right: 16px;
}
.NewTaskEmail .css-1t0pmd1-indicatorSeparator {
  width: 0px;
}
.NewTaskEmail .form-inline-label {
  display: grid;
}
.iconReactCal {
  max-width: 140px;
  position: relative;
  a.cal {
    @include position(absolute, $top: 7px, $left: 3px);
    z-index: 6;
  }
  &__inner > label {
    display: none;
  }
  #due_date {
    padding-left: 1.5rem !important;
  }
}

// INVOICE TASK DETAIL

.InvoiceTaskDetail__Container .taskWrap .formsetValue .form-inline-label label {
  min-width: 98px;
}

.InvoiceTaskDetail__Container .formsetValue {
  margin-top: 20px;
}

.InvoiceTaskDetail__Container {
  .file-drop-upload {
    padding-left: 0;
  }
}
.alert-drop-down {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  align-items: flex-start;
  border: 1.5px solid #909eab;
  border-radius: 5px;
  margin-top: -10px;
  box-shadow: 0px 2px 5px 0px #d3d3d3;
  top: 6.5em;
  left: 55%;
  width: 245px;
  .alert-option {
    font-size: 0.75em;
    color: #555151;
    line-height: 1em;
    padding: 0.75em;
    background-color: #fff;
    z-index: 9999;
    border-bottom: 0.5px solid #ebebeb;
    white-space: nowrap;
    width: -webkit-fill-available;
    box-sizing: content-box;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.alert-drop-down-bg {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: rgba(10, 10, 10, 0.02);
}

.custom-firm-alert {
  position: relative;
}

.outOfOffc {
  width: 100%;
  text-align: center;
  @include position(sticky, $top: 0, $right: 0);
  padding: 10px 0;
  background-color: #fdf5dc;
  z-index: 1;
  span {
    font-weight: 600;
  }
  a {
    color: $secondary-color;
    text-decoration: underline;
  }
}

/* PAGINATION */
.Pagination {
  margin-bottom: 30px;
}

/* CUSTOM FIELDS DATE PICKER */
.CustomFieldsDatePicker {
  display: flex;
}

/* BULK SIGNATURE */
.BulkSignatureButtonGroup,
.TaskButtonGroup,
.BulkInviteButtonGroup,
.BulkReassignButtonGroup,
.BulkMessageButtonGroup,
.BulkDownloadButtonGroup,
.BulkEsignButtonGroup {
  margin-bottom: 40px;
}

/* ACCOUNT DETAILS PAGE */
.AccountPageContainer {
  height: 100%;
}

/* EMAIL LIST */
.EmailMain {
  padding: 1.5rem 2.3rem;
}
.EmailList__LeftColumn {
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .Pagination {
    margin-bottom: 0;
  }
}

/* ESIGN TEMPLATES */
.EsignTemplates__BackButton {
  display: flex;
  align-items: center;
  border: none;
  color: #212b36;
  background-color: white;
  font-size: 15px;
  font-weight: 600;

  &:hover {
    color: #919eab;
  }
}

.Sidebar {
  display: flex;
  width: 200px;
  height: 100%;
  flex-shrink: 0;
  ::-webkit-scrollbar {
    width: 3px !important;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px hsl(206 98.8% 5.9% / 0.2);
    -webkit-box-shadow: inset 0 0 1px hsl(206 98.8% 5.9% / 0.2);
  }

  ::-webkit-scrollbar-thumb {
    background-color: hsl(206 98.8% 5.9% / 0.2);
    outline: 1px solid hsl(206 98.8% 5.9% / 0.2);
  }
}

// for integration page
.card-img-top img {
  max-width: 100%;
  max-height: 100%;
}
.emailModal .em_right_wrap {
  height: calc(100% - 65px);
}

.customModal.emailModal .modal-content {
  height: 100%;
}

.TaskDetail__Description {
  flex: 0.67 !important;
}

.card-section-header {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
  margin-top: 6px;
}

.card-section-sub-header {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #b4b4b4;
  margin-bottom: 12px;
}

.light-outline {
  border-color: #dce1e4 !important;
}

.card-button {
  width: 160px !important;
  padding: 4px 0px !important;
  border-radius: 4px !important;
}

.mfa-card {
  width: 60%;
  border: 1px solid #DBDBDB;
  border-radius: 4px;
  padding: 24px;

  .mfa-title {
    font-size: 1.25em;
    font-weight: 600;
  }
}
