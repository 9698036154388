.noteDescription {
  a {
    padding: 0;
  }

  p {
    width: 100%;
    display: inline-block;
  }
}
