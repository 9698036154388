@import 'scss/variables.scss';
@import 'scss/mixins.scss';

.wrapper {
  display: flex;
  align-items: stretch;
  width: 100%;
  min-height: 100vh;
  padding-left: 200px;
  padding-bottom: 50px;
  @include transition(all ease-in-out 350ms);
  position: relative;
  &.active {
    padding-left: 90px;
    .sidebar-wrapper {
      width: 90px;
    }
  }
}
#content {
  padding: 0;
  width: 100%;
  transition: all 0.3s;

  @media screen and (max-width: 767px) {
    padding-left: 0px;
  }
}
.center-wrapper {
  // height: 100%;
  padding: 1.5rem 2.3rem;
}
.sidebar-wrapper.active + #content {
  // padding-left: 90px;
}
// Footer
#footer {
  display: flex;
  border-top: 1px solid $sky;
  background: white;
  width: 100%;
  justify-content: flex-end;
  padding: 8px;
  position: fixed;
  bottom: 0;
  right: 0;
  nav {
    display: flex;
    margin-right: 25px;
    .nav-link {
      text-transform: uppercase;
      font-weight: 600;
      color: $lightgrey;
      @include font-size(0.625);
      position: relative;
      padding: 0 3px;
      &:after {
        content: '|';
        padding-left: 5px;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      &:hover {
        color: $primary-color;
        &:after {
          color: $lightgrey;
        }
      }
    }
  }
  span {
    color: $lightgrey;
    @include font-size(0.625);
    font-weight: 600;
  }
}

