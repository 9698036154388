@import 'scss/variables.scss';
@import 'scss/mixins.scss';

// Todo: find a better way to do this
.Md-full-width {
  max-width: 100% !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  .modal-content {
    border: none;
    min-height: 100vh;
  }
}

.eng-letter {
  width: 100%;
  min-height: 100vh;
  background-color: rgba(223, 227, 232, 0.4);
  header {
    padding: 0.5rem 0 0.5rem 2rem;
    box-shadow: none;
    .headerWrapper {
      align-items: center;
      .left-side {
        .head-inner {
          overflow: hidden;
        }
        .btn-link {
          float: left;
        }
        .head-inner h2 {
          font-size: 1.5rem;
          margin-bottom: 5px;
          word-break: break-all;
        }
        .head-inner h5 {
          font-size: 1rem;
          color: #dadfe5;
          margin-bottom: 0;
        }
      }
      .right-side {
        display: inline-block;
        width: 50%;
        vertical-align: middle;
        .line-right {
          position: relative;
        }
        .line-right:after {
          position: absolute;
          left: -7px;
          top: 6px;
          content: '';
          height: 27px;
          border: 1px solid #ccc;
        }
      }
      .right-side .btn-wrap.btn--leftspace {
        text-align: right;
      }
    }
    .head-inner {
      display: inline-block;
      vertical-align: middle;
    }
  }
  .main-sec {
    display: flex;
    @include calc(min-height, '100vh - 77px');
    align-items: stretch;
    .left-side-inner-sec {
      @include calc(width, '100% - 400px');
      padding: 4rem;
      border-right: 1px solid $sky;
      &.no-padding {
        padding: 0;
      }
      .left-inner-content {
        @include box-shadow(
          $left: 0px,
          $top: 0px,
          $blur: 20px,
          $color: rgba(0, 0, 0, 0.13)
        );
        padding: 2rem 2.5rem;
        background-color: #fff;
        text-align: center;
        min-height: 800px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.no-shadow {
          box-shadow: none;
        }
        &.no-padding {
          padding: 0;
        }
        iframe {
          height: auto;
          width: 100%;
          min-height: 800px;
          position: relative;
          z-index: 0;
        }
        .customPdf {
          align-self: stretch;
        }
        .emptyBox {
          .emptyBox-icon {
            cursor: pointer;
            margin-bottom: 0;
            i {
              margin-top: 0;
            }
          }
        }
      }
    }
    .right-sidebar-inner {
      width: 100%;
      max-width: 400px;
      padding: 2rem;
      background-color: #fff;
      .labelValue {
        .avatar {
          max-width: 218px;
        }
      }
      .Save-btn {
        margin-left: 2rem;
      }
    }
  }
}

.btn-Upload-Image {
  input#avatar {
    width: 100%;
    min-height: 48px;
    cursor: pointer;
  }
}
