.chatMsg {
  &--block {
    padding: 1.8rem 0;

    &--repeat {
      display: flex;
      margin-bottom: 0;
      padding: 0;

      .avatar {
        flex: 0 0 30px;
        max-width: 30px;
        margin-right: 0;
        position: relative;
        z-index: 0;

        &:before {
          background-color: $sky;
          content: '';
          width: 1px;
          height: 100%;
          @include position(absolute, $left: 50%, $top: 0.6rem);
          margin-left: -0.5px;
        }

        .uName,
        img {
          margin-right: 0;
          position: relative;
          z-index: 1;
        }
      }

      .chatMsg--msg {
        width: 100%;
        padding: 0 0 1rem 1rem;

        .chatMsg--Header {
          display: flex;
          padding-top: 0.2rem;
          padding-bottom: 0.2rem;

          h6 {
            margin-bottom: 0;
            @include font-size(0.94);
            b {
              margin-right: 0.2rem;
            }
            span {
              word-break: break-all;
              display: inline;
            }
          }

          .chatMsg--time {
            color: $lightgrey;
          }

          a {
            color: $primary-color;

            &:hover {
              color: darken($primary-color, 16%);
            }
          }
        }

        p {
          color: $midgrey;
          margin-bottom: 0.5rem;
          min-height: 22px;
          a {
            word-break: break-all;
          }
        }

        a {
          padding: 0;
        }
      }

      &:last-child {
        .avatar {
          &:before {
            display: none;
          }
        }

        .chatMsg--msg {
          padding-bottom: 0;
        }
      }
    }
  }

  &--newmsg {
    border-top: 1px solid $sky;
    padding: 1.8rem 5rem 0;
    margin: 0 -5rem;
    display: flex;

    .avatar {
      max-width: 30px;
      margin-right: 1.2rem;
    }
  }
}
