.trusted-domain-field {
  display: flex;
  align-items: center;

  .trusted-domain-field-at-sign {
    display: inline-block;
    margin-right: 0.25rem;
  }

  .form-control.trusted-domain-field-form-control {
    display: inline-block;
  }
}
